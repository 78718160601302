import { TFilterOption } from '@renderer-ui-library/molecules'
import { FormControlLabel, Radio, RadioGroup } from '@renderer-ui-library/mui'
import { KnownBaleChamberType } from '@website-shared-library/machine/KnownBalerChamberType'
import { KnownMachineHeaderType } from '@website-shared-library/machine/KnownMachineHeaderType'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { TFilterableBaleChamberOption } from '@website-shared-library/search/TFilterableBalerChamberOption'
import { TFilterableHeaderTypeOption } from '@website-shared-library/search/TFilterableHeaderTypeOption'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useBaleChamberTypeOptions } from './useRadioSelectOptions'

type Props = {
  onChange: (option: KnownBaleChamberType | KnownMachineHeaderType) => void
  selectedOption:
    | TFilterableBaleChamberOption
    | TFilterableHeaderTypeOption
    | undefined
}

export const RadioSelectFilter: React.FC<Props> = React.memo(
  ({ onChange, selectedOption }) => {
    const { baleChamberTypeOptions, machineHeaderTypeOptions } =
      useBaleChamberTypeOptions()
    const { machineType } = useSearchParams()

    const [options, setOptions] = useState<TFilterOption[] | null>(null)

    useEffect(() => {
      if (machineType === MachineType.BALER) {
        setOptions(baleChamberTypeOptions)
      }
      if (machineType === MachineType.HEADER) {
        setOptions(machineHeaderTypeOptions)
      }
    }, [baleChamberTypeOptions, machineHeaderTypeOptions, machineType])

    const handleSubTypeChange = useCallback(
      (_: ChangeEvent<HTMLInputElement>, option: string) => {
        if (option) {
          onChange(option as KnownBaleChamberType | KnownMachineHeaderType)
        }
      },
      [onChange]
    )

    return (
      <>
        {options !== null && (
          <RadioGroup
            onChange={handleSubTypeChange}
            defaultValue={selectedOption}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.id}
                control={<Radio value={option.id} />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        )}
      </>
    )
  }
)

RadioSelectFilter.displayName = 'RadioSelectFilter'
