import { ActionableButton, UIButtonGroup } from '@renderer-ui-library/molecules'
import React from 'react'
import { TButtonData } from './TButtonData'
import styles from './buttonGroup.module.scss'

type Props = {
  primaryButton?: TButtonData | null
  secondaryButton?: TButtonData | null
  alignment?: 'left' | 'center'
  variant?: 'outlined' | 'contained'
  highlight?: boolean
}

export const ButtonGroup: React.FC<Props> = React.memo(
  ({ primaryButton, secondaryButton, alignment, highlight, variant }) => {
    if (!primaryButton && !secondaryButton) {
      return null
    }
    const buttonVariant = variant ?? 'outlined'

    return (
      <UIButtonGroup alignment={alignment}>
        <ActionableButton
          button={primaryButton}
          className={styles.button}
          color='secondary'
          variant={buttonVariant}
        />
        <ActionableButton
          button={secondaryButton}
          className={styles.button}
          color={highlight ? 'secondary' : 'primary'}
          variant={buttonVariant}
          sx={
            !highlight && buttonVariant === 'contained'
              ? { color: 'white' }
              : {}
          }
        />
      </UIButtonGroup>
    )
  }
)

ButtonGroup.displayName = 'ButtonGroup'
