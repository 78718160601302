export enum KnownMachineAttribute {
  ABS = 'ABS',
  AD_SCORE = 'AD_SCORE',
  ADD_BLUE = 'ADD_BLUE',
  AIR_BRAKE_SYSTEM = 'AIR_BRAKE_SYSTEM',
  AIR_CONDITION = 'AIR_CONDITION',
  APPLICATION_SYSTEM_PNEUMATIC = 'APPLICATION_SYSTEM_PNEUMATIC',
  ATTACHMENT_LOCKING_HYDRAULIC = 'ATTACHMENT_LOCKING_HYDRAULIC',
  AUTO_CONTOUR = 'AUTO_CONTOUR',
  AVAILABLE = 'AVAILABLE',
  AXLE_STEERED = 'AXLE_STEERED',
  BALE_CHAMBER = 'BALE_CHAMBER',
  BALE_DIMENSIONS = 'BALE_DIMENSIONS',
  BALE_EJECTOR = 'BALE_EJECTOR',
  BALE_THROWER = 'BALE_THROWER',
  BALES_TOTAL_NUMBER = 'BALES_TOTAL_NUMBER',
  BINDING_METHOD = 'BINDING_METHOD',
  BOARD_COMPUTER = 'BOARD_COMPUTER',
  BORDER_SPREADING_FACILITY = 'BORDER_SPREADING_FACILITY',
  BRAND = 'BRAND',
  BUNKER = 'BUNKER',
  CABIN = 'CABIN',
  CABIN_SUSPENSION = 'CABIN_SUSPENSION',
  CALIBRATION_KIT = 'CALIBRATION_KIT',
  CAMERA_SYSTEM = 'CAMERA_SYSTEM',
  CHAFF_SPREADER = 'CHAFF_SPREADER',
  COARSE_HAUL_WEB = 'COARSE_HAUL_WEB',
  CORN_EQUIPMENT = 'CORN_EQUIPMENT',
  COULTER = 'COULTER',
  COUPLING_EQUIPMENT_AUTOMATIC = 'COUPLING_EQUIPMENT_AUTOMATIC',
  COVER = 'COVER',
  COVERING_DISC = 'COVERING_DISC',
  CREEPER = 'CREEPER',
  CUTTERS = 'CUTTERS',
  CUTTING_WIDTH_ADJUSTMENT = 'CUTTING_WIDTH_ADJUSTMENT',
  CUTTING_WIDTH_ADJUSTMENT_HYDRAULIC = 'CUTTING_WIDTH_ADJUSTMENT_HYDRAULIC',
  DEALER_INFO = 'DEALER_INFO',
  DEALER_INFO_EXTERNAL_SOURCE = 'DEALER_INFO_EXTERNAL_SOURCE',
  DEALER_INFO_GROUP_ID = 'DEALER_INFO_GROUP_ID',
  DEALER_REFERENCES = 'DEALER_REFERENCES',
  DRAWBAR_STEERING = 'DRAWBAR_STEERING',
  DRIVE_LANE_MARKING = 'DRIVE_LANE_MARKING',
  DRUM_HOURS = 'DRUM_HOURS',
  ELECTROHYDRAULIC_OPERATION = 'ELECTROHYDRAULIC_OPERATION',
  ELECTRONIC_CONTROL = 'ELECTRONIC_CONTROL',
  ELECTRONIC_GRINDING_DEVICE = 'ELECTRONIC_GRINDING_DEVICE',
  ENGINE_HOURS = 'ENGINE_HOURS',
  ENGINE_HP = 'ENGINE_HP',
  ENGINE_KW = 'ENGINE_KW',
  ENGINE_POWER = 'ENGINE_POWER',
  FOLDING_DEVICE = 'FOLDING_DEVICE',
  FOUR_WHEEL_DRIVE = 'FOUR_WHEEL_DRIVE',
  FRONT_AXLE_SUSPENSION = 'FRONT_AXLE_SUSPENSION',
  FRONT_LOADER = 'FRONT_LOADER',
  FURROWS_NUMBER = 'FURROWS_NUMBER',
  GPS = 'GPS',
  GRAIN_OUTLET_EXTENSION = 'GRAIN_OUTLET_EXTENSION',
  HAND_WASH_CANISTER = 'HAND_WASH_CANISTER',
  HEADER = 'HEADER',
  HEADER_CORN = 'HEADER_CORN',
  HEADER_PICK_UP = 'HEADER_PICK_UP',
  HEADER_RAPE_SEED_TABLE = 'HEADER_RAPE_SEED_TABLE',
  HEADER_TROLLEY = 'HEADER_TROLLEY',
  HEADER_TYPE = 'HEADER_TYPE',
  HEADER_YEAR_OF_PRODUCTION = 'HEADER_YEAR_OF_PRODUCTION',
  HEIGHT_REGULATION = 'HEIGHT_REGULATION',
  HOPPER_CAPACITY = 'HOPPER_CAPACITY',
  HYDRAULIC_OPERATION = 'HYDRAULIC_OPERATION',
  HYDROSTATIC_DRIVE = 'HYDROSTATIC_DRIVE',
  INDUCTION_HOPPER = 'INDUCTION_HOPPER',
  ISOBUS = 'ISOBUS',
  KNIFE_COULTER = 'KNIFE_COULTER',
  KNOTTER = 'KNOTTER',
  KNOTTER_BLOWER = 'KNOTTER_BLOWER',
  FREE_TEXT_FIELD = 'FREE_TEXT_FIELD',
  LIFTING_CAPACITY = 'LIFTING_CAPACITY',
  LIFTING_HEIGHT = 'LIFTING_HEIGHT',
  LIFTING_HOOK = 'LIFTING_HOOK',
  LIGHTS = 'LIGHTS',
  LINKAGE_FRONT = 'LINKAGE_FRONT',
  LOAD_SENSING = 'LOAD_SENSING',
  LOAD_SENSING_DIABOLO = 'LOAD_SENSING_DIABOLO',
  LOCATION_COUNTRY = 'LOCATION_COUNTRY',
  LOCKING_DIFFERENTIAL_FRONT = 'LOCKING_DIFFERENTIAL_FRONT',
  LOCKING_DIFFERENTIAL_REAR = 'LOCKING_DIFFERENTIAL_REAR',
  LUBRICATION_CENTRAL = 'LUBRICATION_CENTRAL',
  MACHINE_GROUP_ID = 'MACHINE_GROUP_ID',
  MACHINE_TYPE = 'MACHINE_TYPE',
  MAPPED_MODEL = 'MAPPED_MODEL',
  MAX_SPEED = 'MAX_SPEED',
  METAL_DETECTOR = 'METAL_DETECTOR',
  MODEL = 'MODEL',
  ON_BOARD_HYDRAULIC = 'ON_BOARD_HYDRAULIC',
  PACKING_ARM = 'PACKING_ARM',
  PART_WIDTH_SECTION_SWITCH = 'PART_WIDTH_SECTION_SWITCH',
  PHOTOS = 'PHOTOS',
  PICKING_TABLE = 'PICKING_TABLE',
  PICKUP_GAUGE_WHEEL = 'PICKUP_GAUGE_WHEEL',
  PICKUP_WIDTH = 'PICKUP_WIDTH',
  PRICE_NET = 'PRICE_NET',
  PRICE_NET_EUR = 'PRICE_NET_EUR',
  PTO_FRONT = 'PTO_FRONT',
  PTO_SHAFT_WIDE_ANGLE = 'PTO_SHAFT_WIDE_ANGLE',
  QUICK_ATTACH_CARRIAGE = 'QUICK_ATTACH_CARRIAGE',
  REEL_HORIZONTAL_ADJUSTMENT_SYSTEM = 'REEL_HORIZONTAL_ADJUSTMENT_SYSTEM',
  REVERSE_CAMERA = 'REVERSE_CAMERA',
  REVERSE_DRIVE_SYSTEM = 'REVERSE_DRIVE_SYSTEM',
  RINSE_TANK = 'RINSE_TANK',
  ROLLER_CROP_PRESS = 'ROLLER_CROP_PRESS',
  ROTOR = 'ROTOR',
  ROW_NUMBER = 'ROW_NUMBER',
  ROW_PLANTERS = 'ROW_PLANTERS',
  ROW_SPREADING_FACILITY = 'ROW_SPREADING_FACILITY',
  SHOE_COULTER = 'SHOE_COULTER',
  SIEVE_BOX_LEVELLING_SYSTEM = 'SIEVE_BOX_LEVELLING_SYSTEM',
  SKIM_COULTER = 'SKIM_COULTER',
  SLATTED_BODY_FURROWS = 'SLATTED_BODY_FURROWS',
  SLOPE_CONTROL = 'SLOPE_CONTROL',
  SOURCE = 'SOURCE',
  STEERING_AUTOMATIC = 'STEERING_AUTOMATIC',
  STRAW_CHOPPER = 'STRAW_CHOPPER',
  STRAW_WALKER_NUMBER = 'STRAW_WALKER_NUMBER',
  SUB_TYPE = 'SUB_TYPE',
  TANDEM_AXLE = 'TANDEM_AXLE',
  TANK_CAPACITY = 'TANK_CAPACITY',
  TRACK_MARKER = 'TRACK_MARKER',
  TRANSMISSION_TYPE = 'TRANSMISSION_TYPE',
  TRANSPORT_FOLD_UP_SYSTEM_HYDRAULIC = 'TRANSPORT_FOLD_UP_SYSTEM_HYDRAULIC',
  TRANSPORT_SUPPORT_WHEEL = 'TRANSPORT_SUPPORT_WHEEL',
  TWIN_DISC_COULTER = 'TWIN_DISC_COULTER',
  WARNING_SIGNS = 'WARNING_SIGNS',
  WEIGHTS_ADDITIONAL = 'WEIGHTS_ADDITIONAL',
  WHEEL_EQUIPMENT = 'WHEEL_EQUIPMENT',
  WHEEL_TIRE_PRESSURE_CONTROL_SYSTEM = 'WHEEL_TIRE_PRESSURE_CONTROL_SYSTEM',
  WORKING_WIDTH = 'WORKING_WIDTH',
  YEAR_OF_PRODUCTION = 'YEAR_OF_PRODUCTION',
  YIELD_MAPPING_VIA_GPS = 'YIELD_MAPPING_VIA_GPS',
  WHEEL_FRONT_LEFT_DEPTH = 'WHEEL_FRONT_LEFT_DEPTH',
  WHEEL_FRONT_RIGHT_DEPTH = 'WHEEL_FRONT_RIGHT_DEPTH',
  WHEEL_REAR_LEFT_DEPTH = 'WHEEL_REAR_LEFT_DEPTH',
  WHEEL_REAR_RIGHT_DEPTH = 'WHEEL_REAR_RIGHT_DEPTH',
}
