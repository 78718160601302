export enum KnownMachineHeaderType {
  FRONT_END_EXTENSION = 'FRONT_END_EXTENSION',
  GPS_CUTTING_UNIT = 'GPS_CUTTING_UNIT',
  GRAIN_HEADER = 'GRAIN_HEADER',
  GRASS_SEED_HEADER = 'GRASS_SEED_HEADER',
  HEADER = 'HEADER',
  MAIZE_HEADER = 'MAIZE_HEADER',
  MAIZE_PICKER = 'MAIZE_PICKER',
  OTHER = 'OTHER',
  PICKER = 'PICKER',
  PICK_UP = 'PICK_UP',
  RAPESEED_HEADER = 'RAPESEED_HEADER',
  SUNFLOWER_HEADER = 'SUNFLOWER_HEADER',
}
