import { TextField } from '@renderer-ui-library/atoms'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
} from '@renderer-ui-library/mui'
import React, { HtmlHTMLAttributes, SyntheticEvent, useCallback } from 'react'
import {
  CountryCodeType,
  countriesCodes,
} from 'utils/contact/phoneCountryCodes'
import styles from './countryCodeAutoComplete.module.scss'

interface CountryCodeAutoCompletePorps {
  onChange: (e: SyntheticEvent, newValue: CountryCodeType | null) => void
  areaCode: CountryCodeType
}

export const CountryCodeAutoComplete = React.memo(
  ({ onChange, areaCode }: CountryCodeAutoCompletePorps) => {
    const getOptionLabel = useCallback(
      (option: CountryCodeType) => `+${option.phone}`,
      []
    )
    const areOptionsOfEqualCode = useCallback(
      (option: CountryCodeType, value: CountryCodeType) =>
        option.code === value.code,
      []
    )

    const renderOption = useCallback(
      (props: HtmlHTMLAttributes<HTMLLIElement>, option: CountryCodeType) => (
        <Box component='li' {...props} key={props.id} className={styles.option}>
          <img
            loading='lazy'
            width='20'
            src={`https://ik.imagekit.io/efarm/flags/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://ik.imagekit.io/efarm/flags/w40/${option.code.toLowerCase()}.png 2x`}
            className={styles.flag}
            alt=''
          />
          +{option.phone}
        </Box>
      ),
      []
    )

    const renderInput = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <div className={styles.countryCodeContainer}>
          <img
            loading='lazy'
            width='20'
            src={`https://ik.imagekit.io/efarm/flags/w20/${areaCode.code.toLowerCase()}.png`}
            srcSet={`https://ik.imagekit.io/efarm/flags/w40/${areaCode.code.toLowerCase()}.png 2x`}
            className={styles.inputFlag}
            alt=''
          />
          <TextField {...params} size='small' />
        </div>
      ),
      [areaCode.code]
    )

    return (
      <Autocomplete
        id='countryCodes'
        options={countriesCodes}
        autoHighlight
        disableClearable
        value={areaCode}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={areOptionsOfEqualCode}
        renderOption={renderOption}
        renderInput={renderInput}
        className={styles.autocomplete}
      />
    )
  }
)

CountryCodeAutoComplete.displayName = 'CountryCodeAutoComplete'
