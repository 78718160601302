import {
  BlockTopText,
  Media,
  MediaColumn,
  TopLink,
} from '@renderer-ui-library/molecules'
import { Grid, GridSize } from '@renderer-ui-library/mui'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { CarouselMediaColumns } from './CarouselColumns'
import { IMediaColumnsBlockData } from './IMediaColumnsBlockData'
import styles from './mediaColumnsBlock.module.scss'

export const MediaColumnsBlock: React.FC<BlockProps<IMediaColumnsBlockData>> =
  React.memo((props) => {
    const containsOnlyImages = useMemo(
      () => !props.data.columns.find((col) => !!col.media?.videoUrl),
      [props]
    )
    const hasCta = React.useMemo(
      () => !props.data.columns.some((column) => column.cta),
      [props.data.columns]
    )

    const topLink = useMemo(
      () =>
        props.data.topButton ? (
          <TopLink
            button={props.data.topButton}
            highlight={props.data.highlight}
          />
        ) : null,
      [props.data.highlight, props.data.topButton]
    )

    const renderColumns = useMemo(
      () =>
        props.data.columns.map((column) => (
          <Grid
            item
            xs={12}
            md={
              props.data.stretchColumns
                ? ((12 / props.data.columns.length) as GridSize)
                : 3
            }
            key={column.id}
          >
            <div className={styles.columnContent}>
              <MediaColumn
                id={column.id}
                title={column.title}
                content={column.content}
                cta={column.cta}
                showInfo
                noMarginBottom={hasCta}
                paddingSides
                highlight={props.data.highlight}
              >
                {column.media && (
                  <div className={styles.image}>
                    <Media
                      media={column.media}
                      ratio={containsOnlyImages ? 3 / 2 : 16 / 9}
                    />
                  </div>
                )}
              </MediaColumn>
            </div>
          </Grid>
        )),
      [
        containsOnlyImages,
        hasCta,
        props.data.columns,
        props.data.highlight,
        props.data.stretchColumns,
      ]
    )

    return (
      <BlockWrapper blockData={props.data}>
        {(props.data.topText || props.data.topTitle) && (
          <BlockTopText
            title={props.data.topTitle}
            text={props.data.topText}
            contentRight={topLink}
            highlight={props.data.highlight}
          />
        )}
        <div
          className={classNames(styles.gridWrapper, {
            [styles.stretched]: props.data.stretchColumns,
          })}
        >
          <Grid container columnSpacing={2} rowSpacing={3}>
            {props.data.carouselColumns ? (
              <CarouselMediaColumns
                columns={props.data.columns}
                containsOnlyImages={containsOnlyImages}
                hasCta={hasCta}
                highlight={props.data.highlight}
              />
            ) : (
              renderColumns
            )}
          </Grid>
        </div>
      </BlockWrapper>
    )
  })

MediaColumnsBlock.displayName = 'MediaColumnsBlock'
