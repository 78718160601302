import { Translation } from '../../machine/i18n/Translation';
import { SubTypes } from '../../machine/MachineType';

export const machineSubMapping = (translations: Translation) => {
  const mapping: Record<SubTypes[keyof SubTypes], string> = {
    [SubTypes.BALER_WRAPPER_COMBINATION]: translations.balerWrapperCombination,
    [SubTypes.HIGH_DENSITY_BALER]: translations.highDensityBaler,
    [SubTypes.OTHER]: translations.balerOther,
    [SubTypes.ROUND_BALER]: translations.roundBaler,
    [SubTypes.SQUARE_BALER]: translations.squareBaler,
  };
  return mapping;
};
