import { Machine } from '@internal/utils/machine/Machine'
import { tracker } from './tracker'

export const trackConversionIntention = (machine: Machine) => {
  tracker.trackEvent({
    name: 'AddToCart',
    action: 'add-to-cart-tag',
    event: 'addToCart',
    ecommerce: {
      add: {
        products: [
          {
            id: machine.machineGroupId,
            price: machine.price ?? '',
            quantity: 1,
          },
        ],
      },
    },
  })
  tracker.trackEvent({
    name: 'Checkout',
    action: 'basket-tag',
    event: 'checkout',
    ecommerce: {
      checkout: {
        products: [
          {
            id: machine.machineGroupId,
            price: machine.price ?? '',
            quantity: 1,
          },
        ],
      },
    },
  })
}
