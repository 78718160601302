import {
  ContentPasteSearchOutlined,
  CreditScoreOutlined,
  EuroOutlined,
  LocalShippingOutlined,
  VerifiedOutlined,
} from '@mui/icons-material'
import { Grid } from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { TNavigation } from 'utils/navigation/TNavigation'
import { TNavigationEntriesKeyMap } from 'utils/navigation/TNavigationEntriesKeyMap'
import { navigationToNavigationEntriesKeyMap } from 'utils/navigation/navigationToNavigationEntriesKeyMap'
import { ServiceItem } from '../ServiceItem/ServiceItem'
import styles from './services.module.scss'

type Props = {
  navigation?: TNavigation
  onEntryClick?: () => void
  xs?: number
  md?: number
  inline?: boolean
}

type TServiceKey =
  | 'inspection'
  | 'payment'
  | 'transportation'
  | 'warranty'
  | 'financing'

export const Services: React.FC<Props> = ({
  navigation,
  onEntryClick,
  inline,
  md = 12,
  xs = 12,
}) => {
  const { t } = useTranslation(localeNamespace.common)

  const entries = useMemo(() => {
    const navigationEntriesMap: TNavigationEntriesKeyMap<TServiceKey> =
      navigation
        ? navigationToNavigationEntriesKeyMap<TServiceKey>(navigation)
        : {}

    return [
      {
        title: t(translations.headerMainNavServiceItemInspectionTitle),
        content: t(translations.headerMainNavServiceItemInspectionDescription),
        url: navigationEntriesMap.inspection?.route,
        icon: ContentPasteSearchOutlined,
      },
      {
        title: t(translations.headerMainNavServiceItemPaymentTitle),
        content: t(translations.headerMainNavServiceItemPaymentDescription),
        url: navigationEntriesMap.payment?.route,
        icon: CreditScoreOutlined,
      },
      {
        title: t(translations.headerMainNavServiceItemTransportTitle),
        content: t(translations.headerMainNavServiceItemTransportDescription),
        url: navigationEntriesMap.transportation?.route,
        icon: LocalShippingOutlined,
      },
      {
        title: t(translations.headerMainNavServiceItemWarrantyTitle),
        content: t(translations.headerMainNavServiceItemWarrantyDescription),
        url: navigationEntriesMap.warranty?.route,
        icon: VerifiedOutlined,
      },
      {
        title: t(translations.headerMainNavServiceItemFinancingTitle),
        content: t(translations.headerMainNavServiceItemFinancingDescription),
        url: navigationEntriesMap.financing?.route,
        icon: EuroOutlined,
      },
    ]
  }, [navigation, t])

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.noPadding]: !inline,
      })}
    >
      <Grid container columns={20}>
        {entries.map((entry, index) => (
          <Grid item key={index} xs={xs} md={md} lg={'auto'}>
            {(!inline || entry.url) && (
              <ServiceItem
                onClick={onEntryClick}
                title={entry.title}
                content={entry.content}
                url={entry.url}
                icon={entry.icon}
                inline={inline}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
