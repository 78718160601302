import { useEffect, useRef, useState } from 'react'

const TYPE_SPEED = 50
const PAUSE_TIME = 2000
const CHARS_PER_WRITE = 1
const CHARS_PER_DELETE = 3

export const useTypewriterEffect = (
  placeHolderText: string,
  words: string[]
) => {
  const [placeholderText, setPlaceholderText] = useState(
    placeHolderText.replace('{{value}}', '')
  )
  const wordIndexRef = useRef(0)
  const charIndexRef = useRef(0)
  const isDeletingRef = useRef(false)
  const isPausingRef = useRef(false)

  useEffect(() => {
    const handleTyping = () => {
      const currentWord = words[wordIndexRef.current]
      const currentText = currentWord.slice(0, charIndexRef.current)

      setPlaceholderText(placeHolderText.replace('{{value}}', currentText))

      if (!isDeletingRef.current) {
        // if writing, add characters to the word
        if (charIndexRef.current < currentWord.length) {
          charIndexRef.current = Math.min(
            charIndexRef.current + CHARS_PER_WRITE,
            currentWord.length
          )
        } else if (!isPausingRef.current) {
          // if the word is fully typed, pause before deleting
          isPausingRef.current = true
          setTimeout(() => {
            isDeletingRef.current = true
            isPausingRef.current = false
          }, PAUSE_TIME)
        }
      } else {
        // if deleting the word, reduce characters
        if (charIndexRef.current > 0) {
          charIndexRef.current = Math.max(
            0,
            charIndexRef.current - CHARS_PER_DELETE
          )
        } else {
          // if the word is fully deleted, move to the next word
          isDeletingRef.current = false
          wordIndexRef.current = (wordIndexRef.current + 1) % words.length
        }
      }
    }

    const interval = setInterval(handleTyping, TYPE_SPEED)
    return () => clearInterval(interval)
  }, [placeHolderText, words])

  return placeholderText
}
