import { Popover } from '@renderer-ui-library/mui'
import React from 'react'
import styles from './multipleFloatingButton.module.scss'

interface Props {
  open: boolean
  id: string | undefined
  children: React.ReactNode
  anchorEl: HTMLButtonElement | null
  handleClose: () => void
}

export const PopoverFloatingButtonOverlay: React.FC<Props> = React.memo(
  ({ id, anchorEl, handleClose, children, open }) => (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      className={styles.popover}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </Popover>
  )
)

PopoverFloatingButtonOverlay.displayName = 'PopoverFloatingButtonOverlay'
