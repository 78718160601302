import React from 'react'
import { FeatureFlagsContext } from './FeatureFlagsContext'
import { TFeatureFlagsMap } from './TFeatureFlagsMap'

interface FeatureFlagsProviderProps {
  featureFlags: TFeatureFlagsMap
  children?: React.ReactNode
}

export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> =
  React.memo(({ children, featureFlags }) => (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  ))

FeatureFlagsProvider.displayName = 'FeatureFlagsProvider'
