import { TLocale } from '@website-shared-library/machine/i18n/TLocale'

const formatters: Record<TLocale, Intl.NumberFormat> = {
  de: new Intl.NumberFormat('de', { style: 'decimal', useGrouping: true }),
  en: new Intl.NumberFormat('en', { style: 'decimal', useGrouping: true }),
  el: new Intl.NumberFormat('el', { style: 'decimal', useGrouping: true }),
  es: new Intl.NumberFormat('es', { style: 'decimal', useGrouping: true }),
  fr: new Intl.NumberFormat('fr', { style: 'decimal', useGrouping: true }),
  hu: new Intl.NumberFormat('hu', { style: 'decimal', useGrouping: true }),
  it: new Intl.NumberFormat('it', { style: 'decimal', useGrouping: true }),
  pl: new Intl.NumberFormat('pl', { style: 'decimal', useGrouping: true }),
  ro: new Intl.NumberFormat('ro', { style: 'decimal', useGrouping: true }),
  ru: new Intl.NumberFormat('ru', { style: 'decimal', useGrouping: true }),
  bg: new Intl.NumberFormat('bg', { style: 'decimal', useGrouping: true }),
  sl: new Intl.NumberFormat('sl', { style: 'decimal', useGrouping: true }),
  sr: new Intl.NumberFormat('sr', { style: 'decimal', useGrouping: true }),
  da: new Intl.NumberFormat('da', { style: 'decimal', useGrouping: true }),
  cs: new Intl.NumberFormat('cs', { style: 'decimal', useGrouping: true }),
  lt: new Intl.NumberFormat('lt', { style: 'decimal', useGrouping: true }),
}

export const formatNumber = (locale: TLocale, value: number) =>
  formatters[locale].format(value)
