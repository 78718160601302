import classnames from 'classnames'
import styles from './elementsWrapper.module.scss'

type Props = {
  className?: string
  children?: React.ReactNode
}
export const ElementsWrapper: React.FC<Props> = (props) => {
  return (
    <div className={classnames(props.className, styles.wrapper)}>
      {props.children}
    </div>
  )
}
