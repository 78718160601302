import { RangeInput } from '@renderer-ui-library/molecules'
import { trackRangeFilterChange } from '@renderer-ui-library/organisms'
import { TDisplayDefinitionEntryNumber } from '@website-shared-library/displayDefinition/entry/DisplayDefinitionEntryNumber'
import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import { RangeSelectValue } from 'blocks/SearchCoverBlock/RangeSelectFilter'
import { TRangeFilterValues } from 'blocks/SearchResultsBlock/TRangeFilterValues'
import { useRangeFilterOptions } from 'blocks/SearchResultsBlock/useRangeFilterOptions'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import React, { useCallback } from 'react'
import { SearchFilterCategory } from './SearchFilterCategory/SearchFilterCategory'

type Props = {
  config: TDisplayDefinitionEntryNumber
}

export const RangeFilter: React.FC<Props> = React.memo((props) => {
  const { rangeFilters, ...searchParams } = useSearchParams()
  const { gotoSearchResults } = useSearchResultsRouting()
  const filter: TRangeFilterValues | undefined =
    rangeFilters[props.config.machineAttribute]

  const rangeFilterOptions = useRangeFilterOptions(
    props.config.machineAttribute as TFilterableMachineAttribute,
    props.config.label
  )

  const handleRangeChange = useCallback(
    (value: { min?: RangeSelectValue; max?: RangeSelectValue }) => {
      gotoSearchResults({
        ...searchParams,
        rangeFilters: {
          ...rangeFilters,
          [props.config.machineAttribute]: {
            ...filter,
            ...value,
          },
        },
      })

      trackRangeFilterChange(
        props.config.machineAttribute as TFilterableMachineAttribute,
        value
      )
    },
    [gotoSearchResults, searchParams, rangeFilters, props.config, filter]
  )

  return (
    <SearchFilterCategory title={rangeFilterOptions.label}>
      <RangeInput
        {...rangeFilterOptions}
        minValue={filter?.min}
        maxValue={filter?.max}
        step={1}
        onChange={handleRangeChange}
      />
    </SearchFilterCategory>
  )
})

RangeFilter.displayName = 'RangeFilter'
