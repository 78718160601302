import { KeyboardArrowUp } from '@mui/icons-material'
import { Box } from '@mui/system'
import { Collapse, Typography } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import classNames from 'classnames'
import { FC, ReactNode, memo, useCallback, useState } from 'react'
import styles from './collapseArea.module.scss'

export interface CollapseAreaProps {
  children: ReactNode
  titleComponent?: ReactNode
  title?: string
  reverseDirection?: boolean
  noCollapse?: boolean
  closed?: boolean
}

export const CollapseArea: FC<CollapseAreaProps> = memo((props) => {
  const { isMobile } = useMediaQuery()
  const [open, setOpen] = useState(props.closed ? false : true)
  const toggle = useCallback(() => {
    if (props.noCollapse) return
    setOpen((o) => !o)
  }, [props.noCollapse])

  return (
    <>
      <div
        className={classNames(styles.collapseHeader, {
          [styles.isClosed]: !open,
          [styles.reverseDirection]: !!props.reverseDirection,
          [styles.defaultCursor]: props.noCollapse,
        })}
        onClick={toggle}
      >
        {props.titleComponent
          ? props.titleComponent
          : props.title && (
              <div className={styles.title}>
                <Typography variant={'h4'} component={'h3'}>
                  {props.title}
                </Typography>
              </div>
            )}
        {!props.noCollapse && <KeyboardArrowUp fontSize={'medium'} />}
      </div>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box>{props.children}</Box>
      </Collapse>
    </>
  )
})

CollapseArea.displayName = 'CollapseArea'
