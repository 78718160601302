import { Machine, MachineMedia } from '@internal/utils/machine/Machine'
import {
  Checkbox,
  FormControlLabel,
  Slide,
  Typography,
} from '@renderer-ui-library/mui'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { MachineComparisonContext } from 'blocks/SearchResultsBlock/CompareMachines/MachineComparisonContext'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useContext } from 'react'
import { MachinePlaceholderImageMapper } from 'utils/machine/placeholder/MachinePlaceholderImageMapper'
import { formatNumber } from 'utils/numbers/formatNumber'
import { formatPrice } from 'utils/numbers/formatPrice'
import { SerpCard } from '../SerpCard'
import styles from './productCard.module.scss'

type TProductHighlightData = {
  power?: number | null
  year?: number | null
  hours?: number | null
}

type Props = {
  title: string
  price: number | null
  media?: MachineMedia | null
  highlightData: TProductHighlightData
  locale: TLocale
  url: string
  machineType: MachineType
  machine?: Machine
}

export const getMachineImageAlt = (
  title: string,
  machineType: MachineType,
  price: number | null,
  locale: TLocale
) => `${title} ${machineType} ${price ? formatPrice(locale, price) : ''}`

export const ProductCard: React.FC<Props> = React.memo(
  ({
    title,
    price,
    media,
    highlightData,
    locale,
    url,
    machineType,
    machine,
  }) => {
    const { t } = useTranslation(localeNamespace.common)
    const {
      isCompareModeEnabled,
      isSelectedForComparison,
      addMachine,
      removeMachine,
    } = useContext(MachineComparisonContext)

    const handleCompareCheckboxChange = useCallback(
      (_: any, checked: boolean) => {
        if (!machine) {
          return
        }
        if (checked) {
          addMachine(machine)
        } else {
          removeMachine(machine.machineGroupId)
        }
      },
      [addMachine, machine, removeMachine]
    )

    return (
      <div className={styles.wrapper}>
        {machine && (
          <Slide
            direction='down'
            in={isCompareModeEnabled}
            mountOnEnter
            unmountOnExit
          >
            <div className={styles.checkBoxWrapper}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSelectedForComparison(machine)}
                    onChange={handleCompareCheckboxChange}
                  />
                }
                label={t(translations.compareMachinesSearchResultLabel)}
              />
            </div>
          </Slide>
        )}

        {machine && (
          <SerpCard
            price={price}
            title={title}
            locale={locale}
            media={
              media
                ? {
                    data: {
                      id: 0,
                      attributes: {
                        ...media,
                        alternativeText: getMachineImageAlt(
                          title,
                          machineType,
                          price,
                          locale
                        ),
                      },
                    },
                  }
                : { data: MachinePlaceholderImageMapper(machineType, 'icon') }
            }
            isLink
            grow
            machine={machine}
          >
            <div>
              <Typography variant='body2' color='textSecondary' component='div'>
                <div className={styles.highlightWrapper}>
                  {highlightData.year && (
                    <div className={styles.highlight}>
                      <Typography variant='body1' fontWeight={600}>
                        {t(translations.searchResultsCardYear)}
                      </Typography>
                      <Typography variant='body2'>
                        {highlightData.year}
                      </Typography>
                    </div>
                  )}
                  {!!highlightData.power && (
                    <div className={styles.highlight}>
                      <Typography variant='body1' fontWeight={600}>
                        {t(translations.searchResultsCardHorsePower)}
                      </Typography>
                      <Typography variant='body2'>
                        {`${formatNumber(locale, highlightData.power)} ${t(
                          translations.enginePowerUnitHP
                        )}`}
                      </Typography>
                    </div>
                  )}
                  {!!highlightData.hours && (
                    <div className={styles.highlight}>
                      <Typography variant='body1' fontWeight={600}>
                        {t(translations.searchResultsCardHours)}
                      </Typography>
                      <Typography variant='body2'>
                        {`${formatNumber(locale, highlightData.hours)}`}
                      </Typography>
                    </div>
                  )}
                </div>
              </Typography>
            </div>
          </SerpCard>
        )}
      </div>
    )
  }
)

ProductCard.displayName = 'ProductCard'
