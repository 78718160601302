import React, { FC } from 'react'

interface CombineProviderProps {
  children: React.ReactNode
}

export const combineProviders = (
  ...providers: any[]
): FC<CombineProviderProps> =>
  providers.reverse().reduce(
    (AccumulatedComponents, { Provider, props }) =>
      // eslint-disable-next-line react/display-name
      ({ children }: { children: React.ReactNode }): JSX.Element => (
        <AccumulatedComponents>
          <Provider {...props}>{children}</Provider>
        </AccumulatedComponents>
      ),
    ({ children }: { children: React.ReactNode }) => <>{children}</>
  )
