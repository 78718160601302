import dynamic from 'next/dynamic'
import { BlockProps } from '../BlockProps'
import { IBenefitsBlockData } from './IBenefitsBlockData'

export const DynamicBenefitsBlock = dynamic<BlockProps<IBenefitsBlockData>>(
  () => import('./BenefitsBlock').then((md) => md.BenefitsBlock),
  {
    ssr: false,
  }
)
