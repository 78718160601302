import { MachineType } from '@website-shared-library/machine/MachineType'

const {
  // machine types to exclude
  FARMSHOP,

  // machine types to make available
  ...AvailableMachineTypes
} = MachineType

export { AvailableMachineTypes }
