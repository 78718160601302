import { ButtonGroup } from '@renderer-ui-library/mui'
import { memo, useMemo } from 'react'
import { useMediaQuery } from '../../mui/utils/mediaQuery/useMediaQuery'
import { Option, RadioOption } from './Option'

export interface Props {
  options: RadioOption[]
  value: string
  onChange: (value: string) => void
}

export const RadioButtonGroup: React.FC<Props> = memo((props) => {
  const { isMobile } = useMediaQuery()

  const renderOptions = useMemo(
    () =>
      props.options.map((option) => (
        <Option
          key={option.value}
          option={option}
          isSelected={props.value === option.value}
          onClick={props.onChange}
          fullWidth
          mobile
        />
      )),
    [props.onChange, props.options, props.value]
  )

  return (
    <>
      {isMobile ? (
        <div>{renderOptions}</div>
      ) : (
        <ButtonGroup variant='outlined'>{renderOptions}</ButtonGroup>
      )}
    </>
  )
})

RadioButtonGroup.displayName = 'RadioButtonGroup'
