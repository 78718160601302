import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import React, { useEffect, useRef } from 'react'
import { trustPilotLocaleMapper } from './trustPilotLocaleMapper'

type Variant = 'small' | 'big'
type Props = {
  locale: TLocale
  variant: Variant
  theme: 'light' | 'dark'
}

const config: Record<Variant, { height: string; template: string }> = {
  small: {
    height: '20px',
    template: '5419b637fa0340045cd0c936',
  },
  big: {
    height: '52px',
    template: '56278e9abfbbba0bdcd568bc',
  },
}

export const TrustPilot: React.FC<Props> = React.memo(
  ({ locale, variant, theme }) => {
    const trustBoxRef = useRef(null)

    useEffect(() => {
      const trustpilot = (window as any).Trustpilot
      if (trustpilot) {
        trustpilot.loadFromElement(trustBoxRef.current, true)
      }
    }, [])

    return (
      <div
        ref={trustBoxRef}
        className='trustpilot-widget'
        data-locale={trustPilotLocaleMapper[locale]}
        data-template-id={config[variant].template}
        data-businessunit-id='608727a74453420001e788db'
        data-style-height={config[variant].height}
        data-style-width='100%'
        data-theme={theme}
      >
        <NextLink
          href='https://uk.trustpilot.com/review/e-farm.com'
          passHref
          prefetch={false}
          target='_blank'
          rel='noopener'
        >
          Trustpilot
        </NextLink>
      </div>
    )
  }
)

TrustPilot.displayName = 'TrustPilot'

export const DynamicTrustPilot = dynamic<Props>(
  () => import('./TrustPilot').then((md) => md.TrustPilot),
  {
    ssr: false,
  }
)
