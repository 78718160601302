import { Link, Typography } from '@renderer-ui-library/mui'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'

export const getMarkdownComponents = (params?: {
  onLinkClick?: (ev: React.MouseEvent<HTMLAnchorElement>) => void
  color?: 'bright' | 'dark'
}): ReactMarkdownOptions['components'] => {
  const color = params?.color || 'dark'

  const textColor = color === 'bright' ? 'white' : undefined

  return {
    h1: (props) => (
      <Typography variant='h1' color={textColor}>
        {props.children}
      </Typography>
    ),
    h2: (props) => (
      <Typography variant='h2' color={textColor}>
        {props.children}
      </Typography>
    ),
    h3: (props) => (
      <Typography variant='h3' color={textColor}>
        {props.children}
      </Typography>
    ),
    h4: (props) => (
      <Typography variant='h4' color={textColor}>
        {props.children}
      </Typography>
    ),
    h5: (props) => (
      <Typography variant='h5' color={textColor}>
        {props.children}
      </Typography>
    ),
    h6: (props) => (
      <Typography variant='h6' color={textColor}>
        {props.children}
      </Typography>
    ),
    p: (props) => (
      <Typography
        variant='body1'
        color={textColor ?? 'textPrimary'}
        gutterBottom
      >
        {props.children}
      </Typography>
    ),
    a: (props) => (
      <Link
        underline='none'
        tabIndex={0}
        href={props.href}
        onClick={params?.onLinkClick}
      >
        {props.children}
      </Link>
    ),
  }
}
