import { translations } from '@internal/i18n/translations'
import {
  TLocale,
  localeNamespace,
} from '@website-shared-library/machine/i18n/Locale'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { displayDefinitionEntriesToStringList } from 'utils/displayDefinition/displayDefinitionEntriesToStringList'
import { useMachineTypeName } from 'utils/machine/useMachineTypeName'
import { formatPrice } from 'utils/numbers/formatPrice'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { SystemPageKey } from './SystemPageKey'
import { TPageData } from './TPageData'
import { TPagePropsMetaData } from './TPagePropsMetaData'

const addBranding = (title: string) => `E-FARM: ${title}`

export const usePageTitle = (pageData: TPageData, locale?: TLocale) => {
  const { getMachineTypeName } = useMachineTypeName()
  const { t } = useTranslation(localeNamespace.common)

  const getPageTitle = useCallback(
    (meta: TPagePropsMetaData) => {
      if (!pageData) {
        return ''
      }

      let pageTitle = pageData.title

      if (
        pageData.type === PageTypeKey.SystemPage &&
        pageData.key === SystemPageKey.PDP &&
        meta.pdp?.machine
      ) {
        const machine = meta.pdp?.machine
        const price = machine.price
          ? `- ${formatPrice(pageData.locale, machine.price)}`
          : ''

        const id = `- id ${machine.machineGroupId}`

        const entries =
          meta.pdp.displayDefinitions.WEBSITE_MACHINE_OVERVIEW?.categories[0]
            ?.entries

        const definitions = entries
          ? `- ${displayDefinitionEntriesToStringList(
              locale ?? 'en',
              machine,
              entries,
              ', '
            )}`
          : ''

        pageTitle = [
          machine.brand,
          machine.model,
          '-',
          getMachineTypeName(machine.machineType, 'singular'),
          id,
          price,
          definitions.replace(',', ' - '),
        ]
          .filter((element) => !!element)
          .join(' ')
      }

      return pageTitle
        ? addBranding(pageTitle)
        : t(translations.efarmBrandedPageTitle)
    },
    [pageData, t, locale, getMachineTypeName]
  )

  return { getPageTitle }
}
