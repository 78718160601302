import { getMediaSrc } from '@internal/utils/media/getMediaSrc'
import { ignoreColumnCtaClickClass } from '@renderer-ui-library/mui/base/constants'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { TMediaFile } from '../Media/TMediaFile'
import styles from './imageSlider.module.scss'

export type ImageSliderProps = {
  images: TMediaFile[]
  ratio: number | 'original' // width / height
  autoPlay?: boolean
  pagination: boolean
  arrows: boolean
}

export const ImageSlider: React.FC<ImageSliderProps> = ({
  images,
  ratio,
  autoPlay,
  pagination,
  arrows,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const handleSlideChange = useCallback(
    (_: any, newIndex: number) => setActiveIndex(newIndex),
    []
  )
  return (
    <Splide
      options={{
        width: '100%',
        heightRatio: typeof ratio === 'number' ? 1 / ratio : undefined,
        rewind: true,
        autoplay: autoPlay,
        pagination: pagination,
        arrows: arrows,
        interval: 3500,
        speed: autoPlay ? 1000 : 400,
        easing: 'ease',
        lazyLoad: 'nearby',
        classes: {
          arrows: `splide__arrows ${ignoreColumnCtaClickClass}`,
          pagination: `splide__pagination ${ignoreColumnCtaClickClass}`,
        },
      }}
      className={classNames(styles.slider, {
        [styles.cover]: !ratio,
      })}
      onMoved={handleSlideChange}
    >
      {images.map((image, index) => {
        return (
          <SplideSlide key={`${index}-${image.attributes.url}`}>
            <img
              loading='lazy'
              className={styles.image}
              alt={image.attributes.alternativeText ?? ''}
              data-splide-lazy={getMediaSrc({ data: image }, { ratio }).src}
            />
          </SplideSlide>
        )
      })}
    </Splide>
  )
}
