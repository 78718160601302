import dynamic from 'next/dynamic'
import { MultipleFloatingButtonProps } from './MultipleFloatingButton'

export const DynamicMultipleFloatingButton =
  dynamic<MultipleFloatingButtonProps>(
    () =>
      import('./MultipleFloatingButton').then(
        (md) => md.MultipleFloatingButton
      ),
    {
      ssr: false,
    }
  )
