import { BenefitCardProps } from '@internal/blocks/BenefitsBlock/IBenefitsBlockData'
import { Text } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import Image from 'next/image'
import styles from './benefitCard.module.scss'
import { ImageLoader } from '@internal/utils/media/ImageLoader'

export const BenefitCard: React.FC<BenefitCardProps> = (props) => {
  const { headline, description, image, cta } = props

  return (
    <div className={styles.cardWrapper}>
      <Image
        src={image.data.attributes.url}
        alt={image?.data.attributes.alternativeText || ''}
        width={275}
        height={200}
        className={styles.image}
        loader={ImageLoader}
      />
      <Typography variant='h4' margin={'24px 0'}>
        {headline}
      </Typography>
      <div className={styles.description}>
        <Text text={description} variant='body1' />
      </div>

      <div className={styles.ctaWrapper}>
        <Text text={cta} variant='body1' />
      </div>
    </div>
  )
}

BenefitCard.displayName = 'BenefitCard'
