import { Typography } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { HeaderContentWrapper } from '../HeaderContentWrapper/HeaderContentWrapper'
import styles from './holidayBanner.module.scss'

export const HolidayBanner: React.FC = React.memo(() => {
  const { t } = useTranslation(localeNamespace.common)
  const text = t(translations.globalBannerText)

  if (!text) {
    return null
  }

  return (
    <div className={styles.outerWrapper}>
      <HeaderContentWrapper className={styles.wrapper}>
        <div className={styles.subtext}>
          <Typography color={Colors.textInvertedPrimary} variant='h6'>
            {t(translations.holidayBreakMessage)}
          </Typography>
        </div>
      </HeaderContentWrapper>
    </div>
  )
})

HolidayBanner.displayName = 'HolidayBanner'
