import { FinancingProvider } from '@internal/utils/machine/leasing'
import { ImageLoader } from '@internal/utils/media/ImageLoader'
import DllLogo from '@renderer-ui-library/assets/companyLogos/dllLogo.png'
import EFarmFinancingLogo from '@renderer-ui-library/assets/companyLogos/efarmFinancing.svg'
import { Icon } from '@renderer-ui-library/atoms'
import { TButtonData, TMediaFile } from '@renderer-ui-library/molecules'
import { Typography } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import classnames from 'classnames'
import Image, { StaticImageData } from 'next/image'
import React, { useCallback } from 'react'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import { tracker } from 'utils/tracking/tracker'
import { TIconData } from '../../atoms/Icon/TIconData'
import { ActionableButton } from '../ActionableButton/ActionableButton'
import styles from './productServiceCard.module.scss'

enum EServiceTypes {
  leasing = 'leasing',
  service = 'service',
}

enum EButtonVariant {
  outlined = 'outlined',
  contained = 'contained',
  text = 'text',
}

export type ServiceProps = {
  title: string
  image: { data: TMediaFile } | null
  repeat: boolean
  repeatPosition: number | null
  initialPosition: number
  icon: TIconData | null
  button: TButtonData | null
  serviceType: EServiceTypes
  leasingAvailable: boolean
  buttonVariant: EButtonVariant
  logo?: { data: TMediaFile }
  leasingProvider: FinancingProvider | null
}

const Logos: Record<FinancingProvider, StaticImageData> = {
  [FinancingProvider.Dll]: DllLogo,
  [FinancingProvider.EFarm]: EFarmFinancingLogo,
}

export const ProductServiceCard: React.FC<ServiceProps> = React.memo(
  (props) => {
    const { isMobile } = useMediaQuery()
    const { leasingAvailable, serviceType, buttonVariant } = props

    const renderImage = (image: { data: TMediaFile }, isLogo: boolean) => (
      <div
        className={classnames({
          [styles.image]: !isLogo,
          [styles.logo]: isLogo,
        })}
      >
        <img
          loading='lazy'
          key={image.data.id}
          alt={image.data.attributes.alternativeText}
          {...getMediaSrc(
            {
              data: { id: image.data.id, attributes: image.data.attributes },
            },
            {
              ratio: 1 / 1,
            }
          )}
        />
      </div>
    )

    const handleButtonClick = useCallback(() => {
      tracker.trackEvent({
        name: 'click',
        target_name: 'service_card.serp',
        is_interaction: true,
      })
    }, [])

    return (
      <>
        {(serviceType !== 'leasing' || leasingAvailable) && (
          <div className={styles.wrapper}>
            {props.image?.data && renderImage(props.image, false)}

            <div className={styles.content}>
              {props.icon && (
                <Icon icon={props.icon.icon} className={styles.icon} />
              )}
              <div
                className={classnames(
                  (styles.title,
                  { [styles.titleSmMargin]: serviceType === 'leasing' })
                )}
              >
                <Typography variant={isMobile ? 'h6' : 'h4'} color='secondary'>
                  {props.title}
                </Typography>
              </div>
              {props.logo?.data &&
                renderImage(props.logo as { data: TMediaFile }, true)}
              {props.leasingProvider && (
                <div className={styles.leasingLogo}>
                  <Image
                    src={props.leasingProvider}
                    alt='financing logo'
                    loader={ImageLoader}
                  />
                </div>
              )}
              {props.button && (
                <div className={styles.button}>
                  <ActionableButton
                    button={props.button}
                    enabled
                    noMinWidth
                    variant={buttonVariant}
                    color='inherit'
                    onClick={handleButtonClick}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    )
  }
)

ProductServiceCard.displayName = 'ProductServiceCard'
