import { KnownMachineAttribute } from '../machine/KnownMachineAttribute';

// using an object instead of an enum here because it allows for computed values
// the enum caused trouble with some typings
export const FilterableMachineAttributes = {
  ABS: KnownMachineAttribute.ABS.toString(),
  ADD_BLUE: KnownMachineAttribute.ADD_BLUE.toString(),
  AIR_BRAKE_SYSTEM: KnownMachineAttribute.AIR_BRAKE_SYSTEM.toString(),
  AIR_CONDITION: KnownMachineAttribute.AIR_CONDITION.toString(),
  APPLICATION_SYSTEM_PNEUMATIC: KnownMachineAttribute.APPLICATION_SYSTEM_PNEUMATIC.toString(),
  ATTACHMENT_LOCKING_HYDRAULIC: KnownMachineAttribute.ATTACHMENT_LOCKING_HYDRAULIC.toString(),
  AUTO_CONTOUR: KnownMachineAttribute.AUTO_CONTOUR.toString(),
  AXLE_STEERED: KnownMachineAttribute.AXLE_STEERED.toString(),
  BALE_CHAMBER: KnownMachineAttribute.BALE_CHAMBER.toString(),
  BALE_DIMENSIONS: KnownMachineAttribute.BALE_DIMENSIONS.toString(),
  BALE_EJECTOR: KnownMachineAttribute.BALE_EJECTOR.toString(),
  BALE_THROWER: KnownMachineAttribute.BALE_THROWER.toString(),
  BALES_TOTAL_NUMBER: KnownMachineAttribute.BALES_TOTAL_NUMBER.toString(),
  BINDING_METHOD: KnownMachineAttribute.BINDING_METHOD.toString(),
  BOARD_COMPUTER: KnownMachineAttribute.BOARD_COMPUTER.toString(),
  BORDER_SPREADING_FACILITY: KnownMachineAttribute.BORDER_SPREADING_FACILITY.toString(),
  BUNKER: KnownMachineAttribute.BUNKER.toString(),
  CABIN: KnownMachineAttribute.CABIN.toString(),
  CABIN_SUSPENSION: KnownMachineAttribute.CABIN_SUSPENSION.toString(),
  CALIBRATION_KIT: KnownMachineAttribute.CALIBRATION_KIT.toString(),
  CAMERA_SYSTEM: KnownMachineAttribute.CAMERA_SYSTEM.toString(),
  CHAFF_SPREADER: KnownMachineAttribute.CHAFF_SPREADER.toString(),
  COARSE_HAUL_WEB: KnownMachineAttribute.COARSE_HAUL_WEB.toString(),
  CORN_EQUIPMENT: KnownMachineAttribute.CORN_EQUIPMENT.toString(),
  COULTER: KnownMachineAttribute.COULTER.toString(),
  COUPLING_EQUIPMENT_AUTOMATIC: KnownMachineAttribute.COUPLING_EQUIPMENT_AUTOMATIC.toString(),
  COVER: KnownMachineAttribute.COVER.toString(),
  COVERING_DISC: KnownMachineAttribute.COVERING_DISC.toString(),
  CREEPER: KnownMachineAttribute.CREEPER.toString(),
  CUTTERS: KnownMachineAttribute.CUTTERS.toString(),
  CUTTING_WIDTH_ADJUSTMENT: KnownMachineAttribute.CUTTING_WIDTH_ADJUSTMENT.toString(),
  CUTTING_WIDTH_ADJUSTMENT_HYDRAULIC: KnownMachineAttribute.CUTTING_WIDTH_ADJUSTMENT_HYDRAULIC.toString(),
  DRAWBAR_STEERING: KnownMachineAttribute.DRAWBAR_STEERING.toString(),
  DRIVE_LANE_MARKING: KnownMachineAttribute.DRIVE_LANE_MARKING.toString(),
  DRUM_HOURS: KnownMachineAttribute.DRUM_HOURS.toString(),
  ELECTROHYDRAULIC_OPERATION: KnownMachineAttribute.ELECTROHYDRAULIC_OPERATION.toString(),
  ELECTRONIC_CONTROL: KnownMachineAttribute.ELECTRONIC_CONTROL.toString(),
  ELECTRONIC_GRINDING_DEVICE: KnownMachineAttribute.ELECTRONIC_GRINDING_DEVICE.toString(),
  ENGINE_HOURS: KnownMachineAttribute.ENGINE_HOURS.toString(),
  ENGINE_HP: KnownMachineAttribute.ENGINE_HP.toString(),
  FOLDING_DEVICE: KnownMachineAttribute.FOLDING_DEVICE.toString(),
  FOUR_WHEEL_DRIVE: KnownMachineAttribute.FOUR_WHEEL_DRIVE.toString(),
  FRONT_AXLE_SUSPENSION: KnownMachineAttribute.FRONT_AXLE_SUSPENSION.toString(),
  FRONT_LOADER: KnownMachineAttribute.FRONT_LOADER.toString(),
  FURROWS_NUMBER: KnownMachineAttribute.FURROWS_NUMBER.toString(),
  GPS: KnownMachineAttribute.GPS.toString(),
  GRAIN_OUTLET_EXTENSION: KnownMachineAttribute.GRAIN_OUTLET_EXTENSION.toString(),
  HAND_WASH_CANISTER: KnownMachineAttribute.HAND_WASH_CANISTER.toString(),
  HEADER: KnownMachineAttribute.HEADER.toString(),
  HEADER_CORN: KnownMachineAttribute.HEADER_CORN.toString(),
  HEADER_PICK_UP: KnownMachineAttribute.HEADER_PICK_UP.toString(),
  HEADER_RAPE_SEED_TABLE: KnownMachineAttribute.HEADER_RAPE_SEED_TABLE.toString(),
  HEADER_TROLLEY: KnownMachineAttribute.HEADER_TROLLEY.toString(),
  HEADER_TYPE: KnownMachineAttribute.HEADER_TYPE.toString(),
  HEADER_YEAR_OF_PRODUCTION: KnownMachineAttribute.HEADER_YEAR_OF_PRODUCTION.toString(),
  HEIGHT_REGULATION: KnownMachineAttribute.HEIGHT_REGULATION.toString(),
  HOPPER_CAPACITY: KnownMachineAttribute.HOPPER_CAPACITY.toString(),
  HYDRAULIC_OPERATION: KnownMachineAttribute.HYDRAULIC_OPERATION.toString(),
  HYDROSTATIC_DRIVE: KnownMachineAttribute.HYDROSTATIC_DRIVE.toString(),
  INDUCTION_HOPPER: KnownMachineAttribute.INDUCTION_HOPPER.toString(),
  ISOBUS: KnownMachineAttribute.ISOBUS.toString(),
  KNIFE_COULTER: KnownMachineAttribute.KNIFE_COULTER.toString(),
  KNOTTER: KnownMachineAttribute.KNOTTER.toString(),
  KNOTTER_BLOWER: KnownMachineAttribute.KNOTTER_BLOWER.toString(),
  LIFTING_CAPACITY: KnownMachineAttribute.LIFTING_CAPACITY.toString(),
  LIFTING_HEIGHT: KnownMachineAttribute.LIFTING_HEIGHT.toString(),
  LIFTING_HOOK: KnownMachineAttribute.LIFTING_HOOK.toString(),
  LIGHTS: KnownMachineAttribute.LIGHTS.toString(),
  LINKAGE_FRONT: KnownMachineAttribute.LINKAGE_FRONT.toString(),
  LOAD_SENSING: KnownMachineAttribute.LOAD_SENSING.toString(),
  LOAD_SENSING_DIABOLO: KnownMachineAttribute.LOAD_SENSING_DIABOLO.toString(),
  LOCATION_COUNTRY: KnownMachineAttribute.LOCATION_COUNTRY.toString(),
  LOCKING_DIFFERENTIAL_FRONT: KnownMachineAttribute.LOCKING_DIFFERENTIAL_FRONT.toString(),
  LOCKING_DIFFERENTIAL_REAR: KnownMachineAttribute.LOCKING_DIFFERENTIAL_REAR.toString(),
  LUBRICATION_CENTRAL: KnownMachineAttribute.LUBRICATION_CENTRAL.toString(),
  MAX_SPEED: KnownMachineAttribute.MAX_SPEED.toString(),
  METAL_DETECTOR: KnownMachineAttribute.METAL_DETECTOR.toString(),
  ON_BOARD_HYDRAULIC: KnownMachineAttribute.ON_BOARD_HYDRAULIC.toString(),
  PACKING_ARM: KnownMachineAttribute.PACKING_ARM.toString(),
  PART_WIDTH_SECTION_SWITCH: KnownMachineAttribute.PART_WIDTH_SECTION_SWITCH.toString(),
  PICKING_TABLE: KnownMachineAttribute.PICKING_TABLE.toString(),
  PICKUP_GAUGE_WHEEL: KnownMachineAttribute.PICKUP_GAUGE_WHEEL.toString(),
  PICKUP_WIDTH: KnownMachineAttribute.PICKUP_WIDTH.toString(),
  PRICE_NET_EUR: KnownMachineAttribute.PRICE_NET_EUR.toString(),
  PTO_FRONT: KnownMachineAttribute.PTO_FRONT.toString(),
  PTO_SHAFT_WIDE_ANGLE: KnownMachineAttribute.PTO_SHAFT_WIDE_ANGLE.toString(),
  QUICK_ATTACH_CARRIAGE: KnownMachineAttribute.QUICK_ATTACH_CARRIAGE.toString(),
  REEL_HORIZONTAL_ADJUSTMENT_SYSTEM: KnownMachineAttribute.REEL_HORIZONTAL_ADJUSTMENT_SYSTEM.toString(),
  REVERSE_CAMERA: KnownMachineAttribute.REVERSE_CAMERA.toString(),
  REVERSE_DRIVE_SYSTEM: KnownMachineAttribute.REVERSE_DRIVE_SYSTEM.toString(),
  RINSE_TANK: KnownMachineAttribute.RINSE_TANK.toString(),
  ROLLER_CROP_PRESS: KnownMachineAttribute.ROLLER_CROP_PRESS.toString(),
  ROTOR: KnownMachineAttribute.ROTOR.toString(),
  ROW_NUMBER: KnownMachineAttribute.ROW_NUMBER.toString(),
  ROW_PLANTERS: KnownMachineAttribute.ROW_PLANTERS.toString(),
  ROW_SPREADING_FACILITY: KnownMachineAttribute.ROW_SPREADING_FACILITY.toString(),
  SHOE_COULTER: KnownMachineAttribute.SHOE_COULTER.toString(),
  SIEVE_BOX_LEVELLING_SYSTEM: KnownMachineAttribute.SIEVE_BOX_LEVELLING_SYSTEM.toString(),
  SKIM_COULTER: KnownMachineAttribute.SKIM_COULTER.toString(),
  SLATTED_BODY_FURROWS: KnownMachineAttribute.SLATTED_BODY_FURROWS.toString(),
  SLOPE_CONTROL: KnownMachineAttribute.SLOPE_CONTROL.toString(),
  STEERING_AUTOMATIC: KnownMachineAttribute.STEERING_AUTOMATIC.toString(),
  STRAW_CHOPPER: KnownMachineAttribute.STRAW_CHOPPER.toString(),
  STRAW_WALKER_NUMBER: KnownMachineAttribute.STRAW_WALKER_NUMBER.toString(),
  SUB_TYPE: KnownMachineAttribute.SUB_TYPE.toString(),
  TANDEM_AXLE: KnownMachineAttribute.TANDEM_AXLE.toString(),
  TANK_CAPACITY: KnownMachineAttribute.TANK_CAPACITY.toString(),
  TRACK_MARKER: KnownMachineAttribute.TRACK_MARKER.toString(),
  TRANSMISSION_TYPE: KnownMachineAttribute.TRANSMISSION_TYPE.toString(),
  TRANSPORT_FOLD_UP_SYSTEM_HYDRAULIC: KnownMachineAttribute.TRANSPORT_FOLD_UP_SYSTEM_HYDRAULIC.toString(),
  TRANSPORT_SUPPORT_WHEEL: KnownMachineAttribute.TRANSPORT_SUPPORT_WHEEL.toString(),
  TWIN_DISC_COULTER: KnownMachineAttribute.TWIN_DISC_COULTER.toString(),
  WARNING_SIGNS: KnownMachineAttribute.WARNING_SIGNS.toString(),
  WEIGHTS_ADDITIONAL: KnownMachineAttribute.WEIGHTS_ADDITIONAL.toString(),
  WHEEL_EQUIPMENT: KnownMachineAttribute.WHEEL_EQUIPMENT.toString(),
  WHEEL_TIRE_PRESSURE_CONTROL_SYSTEM: KnownMachineAttribute.WHEEL_TIRE_PRESSURE_CONTROL_SYSTEM.toString(),
  WORKING_WIDTH: KnownMachineAttribute.WORKING_WIDTH.toString(),
  YEAR_OF_PRODUCTION: KnownMachineAttribute.YEAR_OF_PRODUCTION.toString(),
  YIELD_MAPPING_VIA_GPS: KnownMachineAttribute.YIELD_MAPPING_VIA_GPS.toString(),
};
