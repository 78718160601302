import { Typography } from '@mui/material'
import type { FC, HTMLAttributes, LiHTMLAttributes, ReactNode } from 'react'
import { useCallback } from 'react'

import classNames from 'classnames'
import Image from 'next/image'
import { SelectFilterOption } from './SelectFilterOption'
import styles from './selectItems.module.scss'

type Props = LiHTMLAttributes<HTMLLIElement> & {
  label: string
  wrapText?: boolean
  imageUrl?: string | null
  description?: ReactNode
}
export const SelectItem: FC<Props> = ({
  label,
  wrapText,
  className,
  imageUrl,
  description,
  ...props
}) => {
  return (
    <li
      {...props}
      className={classNames(className, styles.item, {
        [styles.unwrapped]: !wrapText,
      })}
      title={label}
    >
      <div className={styles.contentContainer}>
        {imageUrl && (
          <Image
            className={styles.image}
            alt=''
            src={imageUrl}
            width={36}
            height={36}
          />
        )}
        <div>
          <div>{label}</div>
          {description && (
            <Typography variant='caption' color='textSecondary' component='div'>
              {description}
            </Typography>
          )}
        </div>
      </div>
    </li>
  )
}

export const useSelectItem = (options?: { wrapText?: boolean }) => {
  const renderSelectItem = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement> & { key?: string },
      option: SelectFilterOption,
      state: { selected: boolean }
    ) => (
      <SelectItem
        {...props}
        key={option.key ?? props.key}
        label={option.label}
        wrapText={options?.wrapText}
        imageUrl={option.imageUrl}
        description={option.description}
      />
    ),
    [options?.wrapText]
  )

  return { renderSelectItem }
}
