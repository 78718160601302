import { ImageRatio } from '@renderer-ui-library/mui/utils/media/ImageRatio'
import { createContext } from 'react'
import { TMedia } from './TMedia'

export type GetMediaSrcFn = (
  media: TMedia,
  options: {
    ratio: 'original' | ImageRatio
    desktopViewportPrecentage?: number
    disableMachineWatermark?: boolean | undefined
  }
) => {
  src: string
  srcSet?: string | undefined
  sizes?: string | undefined
  width?: string | undefined
  height?: string | undefined
}

type TMediaContext = {
  getMediaSrc: GetMediaSrcFn
}
export const MediaContext = createContext<TMediaContext>({
  getMediaSrc: (media) => ({
    src: media.attributes.url,
    srcSet: undefined,
    sizes: undefined,
    width: media.attributes.width?.toString(),
    height: media.attributes.height?.toString(),
    alt: '',
  }),
})
