import { TNavigation } from './TNavigation'
import { TNavigationEntriesKeyMap } from './TNavigationEntriesKeyMap'

export const navigationToNavigationEntriesKeyMap = <T extends string>(
  navigation: TNavigation
): TNavigationEntriesKeyMap<T> => {
  const map: TNavigationEntriesKeyMap<T> = {}

  navigation.entries.forEach((entry) => {
    if (entry.key) {
      ;(map as any)[entry.key] = entry
    }
  })
  return map
}
