import { translations } from '@internal/i18n/translations'
import { SettingsContext } from '@internal/page/SettingsContext'
import { MachineContext } from '@internal/utils/machine/MachineContext'
import {
  CallOutlined as CallOutlinedIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { ClickToCopyWrapper } from '@renderer-ui-library/molecules'
import { Paper, Slide, Typography } from '@renderer-ui-library/mui'
import {
  localeNamespace,
  TLocale,
} from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { FC, memo, useCallback, useContext, useState } from 'react'
import { tracker } from 'utils/tracking/tracker'
import styles from './headerPhoneNumber.module.scss'

export const HeaderPhoneNumber: FC<{ locale: TLocale }> = memo(({ locale }) => {
  const [isPhoneVisible, setIsPhoneVisible] = useState(false)
  const { t } = useTranslation(localeNamespace.common)
  const { machine } = useContext(MachineContext)

  const close = useCallback(() => setIsPhoneVisible(false), [])

  const { customerServiceNumbers } = useContext(SettingsContext)
  const serviceNumber = customerServiceNumbers[locale]

  const handleButtonClick = useCallback(() => {
    setIsPhoneVisible(true)

    tracker.trackEvent({
      name: 'call-click',
      target_name: 'header',
      machine_group_id: machine?.machineGroupId,
      is_interaction: true,
    })
  }, [machine])

  return (
    <div className={styles.cluster}>
      <button
        onClick={handleButtonClick}
        className={classNames(styles.serviceNumberReveal, styles.cluster)}
      >
        <CallOutlinedIcon fontSize='small' />
        <span>{t(translations.mainNavigationContact)}</span>
      </button>
      <div className={styles.slideContainer}>
        <Slide direction='right' in={isPhoneVisible} mountOnEnter unmountOnExit>
          <Paper elevation={4} className={styles.overlay}>
            <div className={styles.cluster}>
              <ClickToCopyWrapper text={serviceNumber?.linkNumber ?? ''}>
                <div className={styles.cluster}>
                  <CallOutlinedIcon />
                  {serviceNumber && (
                    <Typography variant='body1' component='div'>
                      <Link
                        href={`tel:${serviceNumber.linkNumber}`}
                        className={styles.serviceNumber}
                      >
                        {serviceNumber.displayNumber}
                      </Link>
                    </Typography>
                  )}
                </div>
              </ClickToCopyWrapper>
              <CloseIcon onClick={close} className={styles.close} />
            </div>
          </Paper>
        </Slide>
      </div>
    </div>
  )
})

HeaderPhoneNumber.displayName = 'HeaderPhoneNumber'
