import { searchModels } from '@internal/services/machineSearchClient'
import { InfoOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { TFilterOption } from '@renderer-ui-library/molecules'
import { RemoteAutoComplete } from '@renderer-ui-library/molecules/RemoteAutocomplete/RemoteAutoComplete'
import { SelectFilterOption } from '@renderer-ui-library/molecules/RemoteAutocomplete/SelectFilterOption'
import { OverlayColor } from '@renderer-ui-library/mui/base/useCreateTheme'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useMemo } from 'react'
import { SearchModel } from '../SearchResultsBlock/TSearchParams'
import styles from './modelSelectFilter.module.scss'

type ModelSelectFilterOption = SelectFilterOption & {
  type: SearchModel['type']
}

type Props = {
  disabled?: boolean
  onChange: (model: SearchModel | null) => void
  model: SearchModel | null
  brands: TFilterOption[]
  colorOverlay: OverlayColor
}

export const ModelSelectFilter: React.FC<Props> = React.memo((props) => {
  const { t } = useTranslation(localeNamespace.common)
  const { brands, model, disabled, colorOverlay, onChange } = props

  const handleChange = useCallback(
    (value: ModelSelectFilterOption | string | null) => {
      if (typeof value === 'string') {
        onChange({ name: value, type: 'raw' })
      } else {
        onChange(
          value === null ? null : { name: value.value, type: value.type }
        )
      }
    },
    [onChange]
  )

  const value: ModelSelectFilterOption | null = useMemo(
    () =>
      model && {
        value: model.name,
        label: model.name,
        type: model.type,
      },
    [model]
  )

  const brand = brands[0]?.id

  const loadValues = useCallback(
    (input: string, abortSignal: AbortSignal) =>
      searchModels(input, brand, abortSignal).then((models) => {
        const options = models.map<ModelSelectFilterOption>((model) => ({
          value: model.name,
          label: model.name,
          type: model.type,
        }))

        if (
          !options.some((o) => o.value.toLowerCase() === input.toLowerCase())
        ) {
          options.push({ value: input, label: input, type: 'raw' })
        }

        return options
      }),
    [brand]
  )

  return (
    <>
      <RemoteAutoComplete<ModelSelectFilterOption>
        valuesReference={brand}
        type='single'
        value={value}
        onChange={handleChange}
        loadValues={loadValues}
        disabled={disabled}
        inputPlaceholder={t(translations.searchResultsFiltersModelLabel)}
        freeSolo
      />

      {brands.length !== 1 && (
        <div
          className={classNames(styles.wrapper, {
            [styles.dark]: colorOverlay === 'dark',
          })}
        >
          <InfoOutlined
            className={styles.icon}
            fontSize='inherit'
            color='inherit'
          />
          <Typography component='p' variant='caption' color='inherit'>
            {t(translations.searchResultsFiltersModelOneBrandInfo)}
          </Typography>
        </div>
      )}
    </>
  )
})

ModelSelectFilter.displayName = 'ModelSelectFilter'
