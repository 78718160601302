import { Chip as MuiChip, ChipProps } from '@mui/material'

export const Chip: React.FC<ChipProps> = (props) => {
  const propsWithDefault: ChipProps = {
    ...props,
    color: props.color ? props.color : 'primary',
    variant: props.variant ? props.variant : 'outlined',
  }
  return <MuiChip {...propsWithDefault} />
}
