import { MobileOverlay } from '@renderer-ui-library/atoms'
import classNames from 'classnames'
import React from 'react'
import { createPortal } from 'react-dom'
import styles from './multipleFloatingButton.module.scss'

export interface MobileFloatingButtonOverlayProps {
  open: boolean
  handleClose: () => void
  children: React.ReactNode
}

export const MobileFloatingButtonOverlay: React.FC<MobileFloatingButtonOverlayProps> =
  React.memo(({ open, handleClose, children }) => (
    <>
      {createPortal(
        <div
          className={classNames(styles.mobileWrapper, {
            [styles.mobileWrapperOpen]: open,
          })}
        >
          <MobileOverlay hideTitle open={open} onCloseClick={handleClose}>
            {children}
          </MobileOverlay>
        </div>,
        document.body
      )}
    </>
  ))

MobileFloatingButtonOverlay.displayName = 'MobileFloatingButtonOverlay'
