import { environment } from 'environment/environment'
import { TTracker } from '../TTracker'
import { PageViewEvent, TTrackingEvent } from '../TTrackingEvent'
import { isCookieConsentBoxClosed } from '../oneTrust'

const storage: TTrackingEvent[] = []

const pushToDataLayer = (event: any) => {
  const global = window as any
  global.dataLayer && global.dataLayer.push(event)
}

const flushStorage = () => {
  storage.forEach((event) => {
    pushToDataLayer({
      event: event.name,
      ...event,
    })
  })
}

const whenElementIsPresent = async (id: string): Promise<HTMLElement> =>
  new Promise((resolve) => {
    const element: HTMLElement | null = document.getElementById(id)
    if (element) {
      resolve(element)
    } else {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLElement && node.id === id) {
              observer.disconnect()
              resolve(node)
            }
          })
        })
      })
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      })
    }
  })

export const gtmTracker: TTracker = {
  init: () => {
    void whenElementIsPresent('onetrust-pc-sdk').then((sdk) => {
      sdk
        .querySelector('.save-preference-btn-handler')
        ?.addEventListener('click', () => {
          //some delay so that the consent receipt can be sent and the cookie can be updated
          setTimeout(() => location.reload(), 1000)
        })
    })

    if (!isCookieConsentBoxClosed()) {
      // we give OneTrust some time to set all the things and then flush / replay the stored events
      void whenElementIsPresent('onetrust-consent-sdk').then((sdk) => {
        const flush = () => setTimeout(flushStorage, 500)
        sdk
          .querySelector('#onetrust-accept-btn-handler')
          ?.addEventListener('click', flush)

        sdk
          .querySelector('.ot-bnr-save-handler')
          ?.addEventListener('click', flush)
      })
    }
  },

  isEnabled: () => !!environment.GTM_ID,

  trackPageView(path, title, trackingPageType, meta) {
    if (trackingPageType === null) {
      return
    }

    let event: PageViewEvent
    if (trackingPageType === 'machine_category_page') {
      event = {
        name: 'pageview',
        page_location: path,
        page_title: title,
        page_type: 'machine_category_page',
        machine_category_page_filter: meta?.machineCategoryPageFilter,
      }
    } else {
      event = {
        name: 'pageview',
        page_location: path,
        page_title: title,
        page_type: trackingPageType,
        machine_group_id: meta?.machineGroupId,
      }
    }

    if (!isCookieConsentBoxClosed()) {
      storage.push(event)
      return
    }
    pushToDataLayer({
      event: event.name,
      ...event,
    })
  },

  trackEvent(event) {
    if (!isCookieConsentBoxClosed()) {
      storage.push(event)
      return
    }
    pushToDataLayer({
      event: event.name,
      ...event,
    })
  },
}
