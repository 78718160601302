import dynamic from 'next/dynamic'
import { BlockProps } from '../BlockProps'
import { ICardBannerBlockData } from './ICardBannerBlockData'

export const DynamicCardBannerBlock = dynamic<BlockProps<ICardBannerBlockData>>(
  () => import('./CardBannerBlock').then((md) => md.CardBannerBlock),
  {
    ssr: false,
  }
)
