import { InputAdornment, SvgIcon, TextField } from '@renderer-ui-library/mui'
import classnames from 'classnames'
import { HTMLAttributes, KeyboardEventHandler } from 'react'
import styles from './TextInput.module.scss'

type SvgIconComponent = typeof SvgIcon

export enum IconPosition {
  start = 'start',
  end = 'end',
}

export type TextInputProps = {
  id?: string
  value?: string
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>
  icon?: SvgIconComponent
  placeholder?: string
  fullWidth?: boolean
  iconPosition?: IconPosition
  className?: string
  inputProps?: HTMLAttributes<HTMLInputElement>
  disabled?: boolean
  children?: React.ReactNode
}

export const TextInput: React.FC<TextInputProps> = (props) => {
  const IconComponent = props.icon

  const getIcon = () => {
    if (!IconComponent) {
      return {}
    }

    const AdornmentComponent = (
      <InputAdornment
        position={props.iconPosition ? props.iconPosition : 'start'}
        className={styles.inputAdornment}
      >
        <IconComponent
          className={styles.icon}
          color='action'
          fontSize='small'
        />
      </InputAdornment>
    )

    return props.iconPosition === IconPosition.end
      ? {
          endAdornment: AdornmentComponent,
        }
      : {
          startAdornment: AdornmentComponent,
        }
  }

  return (
    <TextField
      inputProps={props.inputProps}
      placeholder={props.placeholder}
      fullWidth={props.fullWidth}
      color='primary'
      className={props.className}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      value={props.value}
      disabled={props.disabled}
      InputProps={{
        ...getIcon(),
        className: classnames(styles.input, {
          [styles.inputWithIconStart]:
            props.icon && props.iconPosition !== IconPosition.end,
          [styles.inputWithIconEnd]:
            props.icon && props.iconPosition === IconPosition.end,
        }),
      }}
    >
      {props.children}
    </TextField>
  )
}
