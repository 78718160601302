import { ICardBannerBlockData } from '@internal/blocks/CardBannerBlock/ICardBannerBlockData'
import { ABTest } from '@internal/utils/abTesting/ABTest'
import { ABTestingProvider } from '@internal/utils/abTesting/ABTestingProvider'
import { useMachineSharingPreviewImage } from '@internal/utils/machine/useMachineSharingPreviewImage'
import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { NavigationKey } from '@internal/utils/navigation/NavigationKey'
import { RolloutsProvider } from '@internal/utils/rollouts/RolloutsProvider'
import { tracker } from '@internal/utils/tracking/tracker'
import {
  defaultUserContext,
  UserProvider,
} from '@internal/utils/user/UserContext'
import { CssBaseline, ThemeProvider } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import {
  Font,
  useCreateTheme,
} from '@renderer-ui-library/mui/base/useCreateTheme'
import { ContactWizardProvider } from '@renderer-ui-library/organisms'
import { Blocks } from '@renderer-ui-library/scaffolding/Blocks/Blocks'
import { Footer } from '@renderer-ui-library/scaffolding/Footer/Footer'
import { Header } from '@renderer-ui-library/scaffolding/Header/Header'
import { TSearchParams } from '@renderer-ui-library/scaffolding/Header/SearchOverlay/SearchOverlay'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { LocaleEnum } from '@website-shared-library/machine/i18n/Locale'
import { BlockType } from 'blocks/BlockType'
import classnames from 'classnames'
import Head from 'next/head'
import React, { useEffect, useMemo, useRef } from 'react'
import { SalesManagerProvider } from 'utils/SalesManager/SalesManagerProvider'
import { FeatureFlagsProvider } from 'utils/featureFlags/FeatureFlagsProvider'
import { MachineProvider } from 'utils/machine/MachineProvider'
import { PreviewImage } from 'utils/machine/PreviewImage'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { combineProviders } from 'utils/provider/CombineProvider'
import { BrowserHistoryStackProvider } from 'utils/routing/BrowserHistoryStackProvider'
import { useBrowserHistoryStack } from 'utils/routing/hooks/useBrowserHistoryStack'
import { usedFarmMachineryRoutePrefix } from 'utils/routing/routingTable'
import { TextProvider } from 'utils/text/TextProvider'
import { usePageTracking } from 'utils/tracking/usePageTracking'
import { OpenGraphTags } from './OpenGraphTags'
import { PageProvider } from './PageProvider'
import { SettingsProvider } from './SettingsContext'
import { SystemPageKey } from './SystemPageKey'
import { TPageProps } from './TPageProps'
import styles from './basePageStyles.module.scss'
import { usePageDescription } from './usePageDescription'
import { usePageTitle } from './usePageTitle'
import { useScrollPositionRestoration } from './useScrollPositionRestoration'

export const BasePage: React.FC<TPageProps> = React.memo((props) => {
  usePageTracking(props)
  const { browserHistoryStack } = useBrowserHistoryStack()
  useScrollPositionRestoration(browserHistoryStack)
  const { getPageTitle } = usePageTitle(props.data, props.locale)
  const { getPageDescription } = usePageDescription(props.data, props.meta)
  const { theme } = useCreateTheme(
    props.locale === 'el' ? Font.Greek : Font.Default
  )

  const machine = props.meta.pdp?.machine ?? null
  const pageData = props.data

  const { getMachineTypeName } = useMachineTypeName()
  const pageTitle = getPageTitle(props.meta)

  const searchRoute = usedFarmMachineryRoutePrefix[props.locale]

  const searchParams = useMemo<TSearchParams>(() => {
    let params: TSearchParams = {
      machineType: MachineType.TRACTOR,
      brand: '',
      model: '',
      resultsCount: null,
    }

    props.data.blocks.forEach((block) => {
      if (block.type === BlockType.SearchCoverBlock) {
        params.machineType = block.machineType
        params.brand = block.brand ?? ''
        params.model = block.model ?? ''
      }
    })

    return params
  }, [props.data])

  const trackerInitialized = useRef(false)

  useEffect(() => {
    ;(window as any).refreshForAllLanguages = () => {
      let urls: string[] = []
      if (props.data.type === PageTypeKey.Page) {
        urls = Object.values(props.data.localizations).map(
          (l) => `${window.location.origin}${l}?refresh=1`
        )
      } else {
        urls = Object.values(LocaleEnum).map(
          (locale) => `${window.location.origin}/${locale}/?refresh=1`
        )
      }

      const fn = async () => {
        for (const url of urls) {
          console.log('refreshing: ', url)
          await fetch(url).catch(() => {
            // ignore
          })
        }
        window.location.reload()
      }

      void fn()
    }
  }, [props.data])

  useEffect(() => {
    // useEffect gets called twice in dev env. We need to make sure that we only init the tracker once
    if (!trackerInitialized.current) {
      trackerInitialized.current = true
      tracker.init?.()

      Object.entries(props.abTests).forEach(([testName, isInTestGroup]) => {
        tracker.trackEvent({
          name: 'ab-test',
          test_name: testName as ABTest,
          group: isInTestGroup ? 'test' : 'control',
        })
      })
    }
  }, [props.abTests])

  const AppProvider = useMemo(() => {
    const providers = [
      {
        Provider: ContactWizardProvider,
        props: {
          locale: props.locale,
          pageType: pageData,
          formsData: props.formsData ?? null,
        },
      },
      {
        Provider: SalesManagerProvider,
        props: { salesManager: props.localSalesManager },
      },
      {
        Provider: MachineProvider,
        props: {
          machine: props.meta.pdp?.machine ?? null,
        },
      },
      {
        Provider: UserProvider,
        props: props.meta.user
          ? {
              user: props.meta.user,
            }
          : defaultUserContext,
      },
      {
        Provider: FeatureFlagsProvider,
        props: { featureFlags: props.featureFlags },
      },
      {
        Provider: ABTestingProvider,
        props: { abTests: props.abTests },
      },
      {
        Provider: PageProvider,
        props: {
          pageData: props.data,
          pathParams: props.pathParams,
          machineType: props.machineType,
          localizedUrlMap: props.meta.localizedUrlMap ?? null,
        },
      },
      {
        Provider: TextProvider,
      },
      {
        Provider: SettingsProvider,
        props: { settings: props.settings },
      },
      {
        Provider: RolloutsProvider,
        props: {
          rollouts: props.rollouts,
        },
      },
    ]

    return combineProviders(...providers)
  }, [
    props.locale,
    props.formsData,
    props.localSalesManager,
    props.meta.pdp?.machine,
    props.meta.user,
    props.meta.localizedUrlMap,
    props.featureFlags,
    props.abTests,
    props.data,
    props.pathParams,
    props.machineType,
    props.settings,
    props.rollouts,
    pageData,
  ])

  const isSystemOrMachineCategoryPage =
    pageData.type === PageTypeKey.MachineCategoryPage ||
    (pageData.type === PageTypeKey.SystemPage &&
      pageData.key === SystemPageKey.SearchResults)

  const { blocks, cardBannerBlock } = useMemo(() => {
    /*
     * This was previously done and I just refactored it to simplify things
     * anyways someone has build a way go get the cardBanner out of the blocks on the search results page and then render them inside the block
     * this will lead to a block in a block and is an anti-pattern in this architecture
     */
    if (isSystemOrMachineCategoryPage) {
      const cardBannerBlock = props.data.blocks.find(
        (block) => block.type === BlockType.CardBannerBlock
      )
      return {
        blocks: props.data.blocks.filter(
          (block) => block.type !== BlockType.CardBannerBlock
        ),
        cardBannerBlock: cardBannerBlock
          ? (cardBannerBlock as ICardBannerBlockData)
          : undefined,
      }
    }
    return {
      blocks: props.data.blocks,
      cardBannerBlock: undefined,
    }
  }, [isSystemOrMachineCategoryPage, props.data.blocks])

  const lightBackgroundPage = !!pageData?.lightBackground
  const lastBlockIsHighlighted =
    !!props.data.blocks[props.data.blocks.length - 1]?.highlight

  const machinePreviewImage = useMachineSharingPreviewImage(machine)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Head>
        <OpenGraphTags
          machine={props.meta.pdp?.machine ?? null}
          title={pageTitle}
          description={getPageDescription(props.data.description)}
        />
        <title>{pageTitle}</title>
        <meta name='theme-color' content={`${Colors.primary}`} />
        {machine ? (
          <meta
            name='robots'
            content={`${machine.allowIndexing ? 'index' : 'noindex'} ${
              props.data.robotsFollow ? 'follow' : 'nofollow'
            }`}
          />
        ) : (
          <meta
            name='robots'
            content={`${props.data.robotsIndex ? 'index' : 'noindex'},${
              props.data.robotsFollow ? 'follow' : 'nofollow'
            }`}
          />
        )}
        {machine && machine.price && machinePreviewImage && (
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org/',
                '@type': 'Product',
                name: machine.name,
                category: getMachineTypeName(machine.machineType, 'plural'),
                image: machinePreviewImage.url,
                manufacturer: machine.brand,
                additionalProperty: [
                  {
                    '@type': 'Property Value',
                    name: 'Hours',
                    value: `${machine.engineHours ?? ''}`,
                  },
                  {
                    '@type': 'Property Value',
                    name: 'Year',
                    value: `${machine.yearOfProduction ?? ''}`,
                  },
                  {
                    '@type': 'Property Value',
                    name: 'HorsePower',
                    value: `${machine.enginePower ?? ''}`,
                  },
                ],
                offers: {
                  '@type': 'Offer',
                  priceCurrency: 'EUR',
                  availability: 'https://schema.org/InStock',
                  price: `${machine.price ?? ''}`,
                },
              }),
            }}
          />
        )}

        <meta name='keywords' content={props.data.keywords} />
        <meta
          name='description'
          content={getPageDescription(props.data.description)}
        />
        {props.meta.canonicalUrl && props.host && (
          <link
            rel='canonical'
            href={`https://${props.host}${props.meta.canonicalUrl}`}
          />
        )}

        {/* only render sitelink data structure on the home page for compliance */}
        {pageData.type === PageTypeKey.SystemPage &&
          pageData.key === SystemPageKey.StartPage && (
            <script
              type='application/ld+json'
              dangerouslySetInnerHTML={{
                __html: `
                {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "url": "https://www.e-farm.com/${props.locale}",
                  "potentialAction": {
                    "@type": "SearchAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://e-farm.com/${props.locale}/${searchRoute}/?machineType={search_term_string}"                      
                    },
                    "query-input": "required name=search_term_string"
                  }
                }            
              `,
              }}
            />
          )}

        {/* Please add data that should be loaded in every page to pages/_document.tsx */}
      </Head>

      <PreviewImage machine={machine} alt={pageTitle} />

      <AppProvider>
        <BrowserHistoryStackProvider browserHistoryStack={browserHistoryStack}>
          <main
            className={classnames(styles.pageFrame, {
              [styles.light]: lightBackgroundPage,
            })}
          >
            <Header
              primaryNavigation={
                props.navigationsMap[NavigationKey.MainNavigationPrimary]
              }
              secondaryNavigation={
                props.navigationsMap[NavigationKey.MainNavigationSecondary]
              }
              brandsNavigation={
                props.navigationsMap[NavigationKey.HeaderBrands]
              }
              footerNavigation={props.navigationsMap[NavigationKey.Footer0]}
              locale={props.locale}
              searchParams={searchParams}
            />
            <Blocks
              blocks={blocks}
              locale={props.locale}
              preFetchedBlockProps={props.preFetchedBlockProps}
              cardBannerBlock={cardBannerBlock}
              location={props.location}
              seoDescription={props.data.seoDescription}
              pagePropsMetaData={props.meta}
            />
            <Footer
              navigationsMap={props.navigationsMap}
              locale={props.locale}
              background={
                lightBackgroundPage
                  ? lastBlockIsHighlighted
                    ? 'light'
                    : 'dark'
                  : lastBlockIsHighlighted
                    ? 'dark'
                    : 'light'
              }
            />
          </main>
        </BrowserHistoryStackProvider>
      </AppProvider>
    </ThemeProvider>
  )
})

BasePage.displayName = 'BasePage'
