import { Alert, AlertColor } from '@renderer-ui-library/mui'
import { translations } from 'i18n/translations'
import { TFunction } from 'next-i18next'
import React, { useMemo } from 'react'

export enum AlertState {
  None = 'None',
  Success = 'Success',
  Error = 'Error',
}
type TAlertState = keyof typeof AlertState

type NotificationPreferencesAlertProps = {
  alertState: TAlertState
  t: TFunction
}

export const NotificationPreferencesAlert = React.memo(
  ({ alertState, t }: NotificationPreferencesAlertProps) => {
    const alertContent = useMemo<{
      [key in TAlertState]: { severity: AlertColor; message: string } | null
    }>(
      () => ({
        Error: {
          severity: 'error',
          message: t(translations.searchRequestPreferenceUpdateErrorMessage),
        },
        Success: {
          severity: 'success',
          message: t(translations.searchRequestPreferenceUpdateSuccessMessage),
        },
        None: null,
      }),
      [t]
    )

    const alert = alertContent[alertState]

    if (!alert) {
      return null
    }

    return <Alert severity={alert.severity}>{alert.message}</Alert>
  }
)
NotificationPreferencesAlert.displayName = 'NotificationPreferencesAlert'
