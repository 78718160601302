import dynamic from 'next/dynamic'
import { BlockProps } from '../BlockProps'
import { ICookieListBlockData } from './ICookieListBlockData'

export const DynamicCookieListBlock = dynamic<BlockProps<ICookieListBlockData>>(
  () => import('./CookieListBlock').then((md) => md.CookieListBlock),
  {
    ssr: false,
  }
)
