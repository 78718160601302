import { Neutralizer } from '@renderer-ui-library/atoms'
import classnames from 'classnames'
import React, { HTMLProps } from 'react'
import styles from './ratioContainerStyles.module.scss'

type Props = HTMLProps<HTMLDivElement> & {
  // width / height
  ratio: number | { width: number; height: number }
  innerRef?: React.Ref<HTMLDivElement>
  flex?: boolean
  overflowHidden?: boolean
}

export const RatioContainerWithInnerRef: React.FC<Props> = (props) => {
  const { ratio, innerRef, flex, overflowHidden, ...divProps } = props
  const calculatedRatio =
    typeof ratio === 'number' ? ratio : ratio.width / ratio.height
  return (
    <div
      {...divProps}
      ref={innerRef}
      className={classnames(props.className, styles.container, {
        [styles.overflowHidden]: overflowHidden,
      })}
      style={{
        ...props.style,
        paddingBottom: `${(1 / calculatedRatio) * 100}%`,
      }}
    >
      <Neutralizer flex={flex}>{props.children}</Neutralizer>
    </div>
  )
}

export const RatioContainer = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => <RatioContainerWithInnerRef innerRef={ref} {...props} />
)
