const getCookieFromBrowser = (name: string) => {
  try {
    // this will throw when executet on the server
    const match = document.cookie.match('(^|; )' + name + '=([^;]*)') || 0
    if (match) {
      return match[0] ?? null
    }
  } catch (error) {
    // ignore
  }
  return null
}

// cookie consent box closed means that a user has interacted with the cookie consent box
export const isCookieConsentBoxClosed = () => {
  return !!getCookieFromBrowser('OptanonAlertBoxClosed')
}
