export enum MachineDataSource {
  agmachinery = 'agmachinery',
  agriaffaires = 'agriaffaires',
  agriaffaires_fork = 'agriaffaires_fork',
  farmerapp = 'farmerapp',
  internal = 'internal',
  landwirt = 'landwirt',
  machinefinder = 'machinefinder',
  mascus = 'mascus',
  mia = 'mia',
  model_metadata = 'model_metadata',
  picasso = 'picasso',
  reap = 'reap',
  smarttrade = 'smarttrade',
  technikboerse = 'technikboerse',
  tractomarket = 'tractomarket',
  traktorpool = 'traktorpool',
  vin = 'vin',
  zoho = 'zoho',
}
