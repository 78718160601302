import { Text } from '@renderer-ui-library/atoms'
import { ButtonGroup, ElementsWrapper } from '@renderer-ui-library/molecules'
import { Grid } from '@renderer-ui-library/mui'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import React from 'react'
import { ISimpleTextBlockData } from './ISimpleTextBlockData'

export const SimpleTextBlock: React.FC<BlockProps<ISimpleTextBlockData>> =
  React.memo(({ data }) => (
    <BlockWrapper blockData={data}>
      <Grid container justifyContent={data.alignment}>
        <Grid item md={12}>
          <ElementsWrapper>
            <Text
              text={data.text}
              highlight={data.highlight}
              color={data.highlight ? 'bright' : 'dark'}
              alignment={data.alignment}
            />
            <ButtonGroup
              highlight={data.highlight}
              alignment={data.alignment}
              primaryButton={data.buttonPrimary}
              secondaryButton={data.buttonSecondary}
            />
          </ElementsWrapper>
        </Grid>
      </Grid>
    </BlockWrapper>
  ))

SimpleTextBlock.displayName = 'SimpleTextBlock'
