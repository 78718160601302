import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { Media, MediaColumn } from '@renderer-ui-library/molecules'
import { Grid } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import classNames from 'classnames'
import React, { useMemo, useRef, useState } from 'react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavigationOptions } from 'swiper/types'
import { TMediaColumnData } from './IMediaColumnsBlockData'
import styles from './carouselMediaColumns.module.scss'
interface CarouselMediaColumnsProps {
  columns: TMediaColumnData[]
  containsOnlyImages: boolean
  hasCta: boolean
  highlight: boolean
}

SwiperCore.use([Navigation])

export const CarouselMediaColumns: React.FC<CarouselMediaColumnsProps> =
  React.memo(({ columns, containsOnlyImages, hasCta, highlight }) => {
    const { isMobile } = useMediaQuery()
    const [isBeginning, setIsBeginning] = useState<boolean>(true)
    const [isEnd, setIsEnd] = useState<boolean>(false)
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)

    const renderDesktopCarousel = useMemo(
      () => (
        <div className={styles.swiper}>
          <Grid item xs={12} md={12}>
            <Swiper
              freeMode
              spaceBetween={16}
              slidesPerView={'auto'}
              navigation={{
                prevEl: navigationPrevRef.current ?? undefined,
                nextEl: navigationNextRef.current ?? undefined,
              }}
              onSlideChange={() => {
                setIsBeginning(false)
                setIsEnd(false)
              }}
              onReachBeginning={() => {
                setIsBeginning(true)
                setIsEnd(false)
              }}
              onReachEnd={() => {
                setIsBeginning(false)
                setIsEnd(true)
              }}
              onSwiper={(swiper) => {
                // Delay execution for the refs to be define
                setTimeout(() => {
                  if (swiper.params?.navigation !== undefined) {
                    ;(swiper.params.navigation as NavigationOptions).prevEl =
                      navigationPrevRef.current
                    ;(swiper.params.navigation as NavigationOptions).nextEl =
                      navigationNextRef.current
                  }
                  swiper.navigation?.destroy()
                  swiper.navigation?.init()
                  swiper.navigation?.update()
                })
              }}
            >
              {columns.map((column) => (
                <SwiperSlide key={column.id} className={styles.swiperSlide}>
                  <MediaColumn
                    id={column.id}
                    title={column.title}
                    content={column.content}
                    cta={column.cta}
                    showInfo
                    noMarginBottom={hasCta}
                    highlight={highlight}
                  >
                    {column.media && (
                      <div className={styles.image}>
                        <Media
                          media={column.media}
                          ratio={containsOnlyImages ? 3 / 2 : 16 / 9}
                        />
                      </div>
                    )}
                  </MediaColumn>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </div>
      ),
      [columns, hasCta, highlight, containsOnlyImages]
    )

    const renderMobileCarousel = useMemo(
      () => (
        <div className={styles.mobileWrapper}>
          {columns.map((column) => (
            <div key={column.id} className={styles.column}>
              <MediaColumn
                id={column.id}
                title={column.title}
                content={column.content}
                cta={column.cta}
                showInfo
                noMarginBottom={hasCta}
              >
                {column.media && (
                  <div className={styles.image}>
                    <Media
                      media={column.media}
                      ratio={containsOnlyImages ? 3 / 2 : 16 / 9}
                    />
                  </div>
                )}
              </MediaColumn>
            </div>
          ))}
        </div>
      ),
      [columns, containsOnlyImages, hasCta]
    )
    return isMobile ? (
      renderMobileCarousel
    ) : (
      <div className={styles.wrapper}>
        {renderDesktopCarousel}
        <div
          ref={navigationPrevRef}
          className={classNames(styles.swiperPrev, {
            [styles.hideNavigate]: isBeginning,
          })}
        >
          <NavigateBefore color='inherit' />
        </div>
        <div
          ref={navigationNextRef}
          className={classNames(styles.swiperNext, {
            [styles.hideNavigate]: isEnd,
          })}
        >
          <NavigateNext color='inherit' />
        </div>
      </div>
    )
  })

CarouselMediaColumns.displayName = 'CarouselMediaColumns'
