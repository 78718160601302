import { Machine } from '@internal/utils/machine/Machine'
import { FittedImage } from '@renderer-ui-library/atoms'
import { ThumbnailSlider, TMedia } from '@renderer-ui-library/molecules'
import { ImageRatio } from '@renderer-ui-library/mui/utils/media/ImageRatio'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import React, { useMemo } from 'react'
import { MachinePlaceholderImageMapper } from 'utils/machine/placeholder/MachinePlaceholderImageMapper'
import { useMachineTypeName } from 'utils/machine/useMachineTypeName'

interface Props {
  machine: Machine
  locale: TLocale
}

export const MachinePreviewImage: React.FC<Props> = React.memo(
  ({ machine, locale }) => {
    const { getMachineTypeName } = useMachineTypeName()
    const media = useMemo(
      (): TMedia[] | null =>
        machine.previewImage
          ? [{ id: 0, attributes: { ...machine.previewImage } }]
          : null,
      [machine.previewImage]
    )

    if (!machine) {
      return null
    }

    if (media) {
      return (
        <ThumbnailSlider
          imageRatio={ImageRatio.Square}
          media={media}
          pagination={false}
          machine={machine}
          locale={locale}
          hideShareButton={true}
        />
      )
    }

    return (
      <FittedImage
        ratio={ImageRatio.Square}
        src={
          MachinePlaceholderImageMapper(machine.machineType, 'icon').attributes
            .url
        }
        alt={getMachineTypeName(machine.machineType, 'singular')}
      />
    )
  }
)

MachinePreviewImage.displayName = 'MachinePreviewImage'
