import { LinkComponent } from '@renderer-ui-library/atoms'
import { Link as LinkUI, Typography } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import React, { useCallback } from 'react'
import { TNavigationEntry } from 'utils/navigation/TNavigationEntry'
import { convertCmsTrackingClickEvent } from 'utils/tracking/convertCmsTrackingEvents'
import { tracker } from 'utils/tracking/tracker'
import styles from './footer.module.scss'

type Props = {
  name: string
  entries: TNavigationEntry[]
  isMobile: boolean
}

enum EntryKey {
  CookieSettings = 'cookie-settings',
}

export const MenuEntry: React.FC<{ entry: TNavigationEntry }> = ({ entry }) => {
  const handleClick = useCallback(() => {
    if (entry.key === EntryKey.CookieSettings) {
      const optanon = (window as any).Optanon
      if (optanon) {
        optanon.ToggleInfoDisplay()
      }
    }

    if (entry.trackingClickEvent) {
      tracker.trackEvent({
        ...convertCmsTrackingClickEvent(entry.trackingClickEvent),
      })
    }
  }, [entry])

  const attachClickEvent =
    entry.key === EntryKey.CookieSettings || !!entry.trackingClickEvent
  return (
    <div className={styles.entry} key={entry.id}>
      {entry.key === EntryKey.CookieSettings ? (
        <LinkUI
          component='button'
          variant='body1'
          color={Colors.textInvertedPrimary}
          onClick={attachClickEvent ? handleClick : undefined}
          underline='none'
        >
          {entry.name}
        </LinkUI>
      ) : (
        <LinkComponent
          href={entry.route}
          external={entry.external}
          onClick={attachClickEvent ? handleClick : undefined}
          prefetch={false}
        >
          {entry.name}
        </LinkComponent>
      )}
    </div>
  )
}

export const MenuColumn: React.FC<Props> = React.memo(
  ({ name, entries, isMobile }) => (
    <>
      {!isMobile && (
        <Typography variant='h6' color={Colors.textInvertedPrimary}>
          {name}
        </Typography>
      )}
      {entries.map((entry) => (
        <MenuEntry key={entry.name} entry={entry} />
      ))}
    </>
  )
)

MenuColumn.displayName = 'MenuColumn'
