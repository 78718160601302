import { machineSubMapping } from '@website-shared-library/machine/i18n/machineSubMapping'
import { TranslationJsonType } from '@website-shared-library/machine/i18n/Translation'
import { SubTypes } from '@website-shared-library/machine/MachineType'
import { translations } from 'i18n/translations'

const mapping = machineSubMapping(translations)

export const machineSubTypeTranslationKeyMapping = mapping as any as Record<
  SubTypes[keyof SubTypes],
  keyof TranslationJsonType
>
