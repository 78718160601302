import type { KeyboardEvent } from 'react'
import { useCallback, useMemo } from 'react'

export const useEnterPressHandler = (
  callback: (ev: React.KeyboardEvent) => void,
  dependencies: unknown[],
  options?: {
    requireMetaKey?: boolean
  }
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wrappedCallback = useCallback(callback, dependencies)
  const handleKeyPress = useCallback(
    (ev: KeyboardEvent) => {
      if (
        ev.key === 'Enter' &&
        (!options || !options.requireMetaKey || ev.metaKey || ev.ctrlKey)
      ) {
        wrappedCallback(ev)
      }
    },
    [options, wrappedCallback]
  )

  return useMemo(
    () => ({
      handleEnterPress: handleKeyPress,
    }),
    [handleKeyPress]
  )
}
