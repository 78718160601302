import { TLocale } from '@website-shared-library/machine/i18n/TLocale'

export const contactPageRoutes: Record<TLocale, string> = {
  en: '/en/contact-us/',
  de: '/de/kontakt/',
  fr: '/fr/contact/',
  el: '/el/epikinonia/',
  it: '/it/contatto/',
  pl: '/pl/kontakt/',
  ro: '/ro/contact/',
  ru: '/ru/kontakt/',
  hu: '/hu/kapcsolat/',
  es: '/es/contacto/',
  bg: '/bg/za-kontakt/',
  sl: '/sl/pishite-na/',
  sr: '/sr/kontaktirajte-nas/',
  da: '/da/kontakt-os/',
  cs: '/cs/contact-us/',
  lt: '/lt/contact-us/',
}
