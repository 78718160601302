import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
  Search,
} from '@mui/icons-material'
import { TextInput } from '@renderer-ui-library/atoms'
import {
  Checkbox,
  Divider,
  Typography,
  useAutocomplete,
} from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useMemo } from 'react'
import { Option } from './Option'
import { SelectedOption } from './SelectedOption'
import { TFilterOption } from './TFilterOption'
import styles from './filteredList.module.scss'

export type FilteredListProps = {
  id?: string
  options: TFilterOption[]
  placeholder?: string
  allSelected?: boolean
  onItemClick: (id: string) => void
  onSelectAll?: (selectAll: boolean) => void
  moreButtonText?: string
  disabled?: boolean
  title?: string
  dividerIndex?: number
  contentTop?: React.ReactNode
  hideInputField?: boolean
}

export const FilteredList: React.FC<FilteredListProps> = React.memo(
  ({ onSelectAll, onItemClick, allSelected, hideInputField, ...props }) => {
    const { t } = useTranslation(localeNamespace.common)
    const {
      getRootProps,
      getInputLabelProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
    } = useAutocomplete({
      id: props.id,
      options: props.options,
      getOptionLabel: (option) => option.label,
      multiple: true,
      open: true,
    })

    const inputProps = getInputProps()

    const toggleSelectAll = useCallback(
      () => onSelectAll?.(!allSelected),
      [onSelectAll, allSelected]
    )

    const handleItemClick = useCallback(
      (option: TFilterOption) => onItemClick(option.id),
      [onItemClick]
    )

    const checkedOptions = useMemo(
      () => props.options.filter((option) => !!option.selected),
      [props.options]
    )

    return (
      <div className={styles.container}>
        <div
          {...getRootProps()}
          className={classNames(styles.input, {
            [styles.hideInputField]: hideInputField,
          })}
        >
          <TextInput
            inputProps={inputProps}
            fullWidth
            icon={Search}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
        </div>
        {checkedOptions.length > 0 && (
          <div
            className={classNames(styles.chipsWrapper, {
              [styles.hideInputField]: hideInputField,
            })}
          >
            {checkedOptions.map((option) => (
              <SelectedOption
                key={option.id}
                option={option}
                onClick={handleItemClick}
              />
            ))}
          </div>
        )}

        {props.contentTop}

        {groupedOptions.length > 0 ? (
          <ul {...getListboxProps()} className={styles.list}>
            {onSelectAll && (
              <li className={styles.item} onClick={toggleSelectAll}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  style={{ marginRight: 8 }}
                  checked={allSelected}
                />
                {t(translations.filteredListSelectAll)}
              </li>
            )}
            {(groupedOptions as TFilterOption[]).map((option, index) => (
              <div key={option.id}>
                {index === 0 && props.title && (
                  <Typography variant='body2' color='textSecondary'>
                    {props.title}
                  </Typography>
                )}
                <Option
                  key={option.id}
                  option={option}
                  optionProps={getOptionProps({ option, index })}
                  onClick={handleItemClick}
                />
                {index === props.dividerIndex && <Divider />}
              </div>
            ))}
          </ul>
        ) : null}
      </div>
    )
  }
)

FilteredList.displayName = 'FilteredList'
