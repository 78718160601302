import {
  CheckCircleOutline,
  SentimentVeryDissatisfied,
  SvgIconComponent,
} from '@mui/icons-material'
import React from 'react'

type Props = {
  variant: 'success' | 'error'
  icon?: SvgIconComponent
}

export const FinishedStepIcon: React.FC<Props> = React.memo(
  ({ variant, icon }) => {
    const IconComponent = icon ? icon : undefined
    if (IconComponent) {
      return (
        <IconComponent
          color={variant === 'success' ? 'primary' : 'inherit'}
          fontSize='inherit'
        />
      )
    }

    return variant === 'error' ? (
      <SentimentVeryDissatisfied color='inherit' fontSize='inherit' />
    ) : (
      <CheckCircleOutline color='primary' fontSize='inherit' />
    )
  }
)

FinishedStepIcon.displayName = 'FinishedStepIcon'
