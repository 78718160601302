import { TMedia } from '@renderer-ui-library/molecules'

export const getLocalImage = (req: { default: any }, mime: string): TMedia => {
  const { width, height } = req.default
  return {
    id: 0,
    attributes: {
      url: req.default.src ?? '',
      ratio: width && height ? width / height : undefined,
      width,
      height,
      mime,
    },
  }
}
