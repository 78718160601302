import { TLocalizedUrlMap } from '@internal/i18n/TLocalizedUrlMap'
import { getLocalizedUrlMapForStartPage } from '@internal/utils/routing/urls/getLocalizedUrlMapForStartPage'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { createContext } from 'react'
import { TPageData } from './TPageData'

export type PageContextProps = {
  pageData: TPageData | null
  pathParams: any
  machineType: MachineType | null
  localizedUrlMap: TLocalizedUrlMap | null
}

export const PageContext = createContext<PageContextProps>({
  pageData: null,
  pathParams: {},
  machineType: null,
  localizedUrlMap: getLocalizedUrlMapForStartPage(),
})
