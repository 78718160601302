import { SearchModel } from '@internal/blocks/SearchResultsBlock/TSearchParams'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { MachineType } from '@website-shared-library/machine/MachineType'
import axios from 'axios'
import { environment } from 'environment/environment'
import { uniqBy } from 'lodash'
import {
  GetSuggestRequestParams,
  GetSuggestResponse,
} from './types/suggestTypes'

const machineSearchClient = axios.create({
  baseURL: `${environment.MACHINE_SEARCH_URL}/api`,
  timeout: 30000,
})

type ModelSearchResult = {
  BRAND: string
  BASE_MODEL: string
  MODEL: string
  MODEL_EXTENSION?: string
  MODEL_SERIES?: string
  MACHINE_TYPE?: MachineType
}

export const searchBrands = (query: string, abortSignal?: AbortSignal) =>
  machineSearchClient
    .get<string[]>(`/v1/brands/search`, {
      signal: abortSignal,
      params: {
        query,
        limit: 20,
      },
    })
    .then((res) => res.data)
    .then((brands) => brands as string[])
    .catch((e) => [])

export const searchModels = (
  query: string,
  brand?: string,
  abortSignal?: AbortSignal
) =>
  machineSearchClient
    .get<ModelSearchResult[]>(`/v1/models/autocomplete`, {
      signal: abortSignal,
      params: {
        query,
        BRAND: brand,
        limit: 50,
      },
    })
    .then(({ data: models }) => {
      const combinedList = models.flatMap((m): [SearchModel, SearchModel] => [
        { name: m.BASE_MODEL, type: 'base' },
        { name: m.MODEL, type: 'mapped' },
      ])
      return uniqBy(combinedList, (e) => e.name)
    })
    .catch((e) => [])

export const suggest = (
  query: string,
  locale: TLocale
): Promise<GetSuggestResponse> =>
  machineSearchClient
    .get<GetSuggestResponse>(`/v1/search/suggest`, {
      params: {
        query,
        locale,
      } satisfies GetSuggestRequestParams,
    })
    .then((res) => res.data)
