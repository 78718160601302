import { Button } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { TNavigation } from 'utils/navigation/TNavigation'
import { PopularTractorBrands } from '../../../molecules/PopularTractorBrands/PopularTractorBrands'
import styles from './allMachinesFooter.module.scss'

type Props = {
  onClick: () => void
  brandsNavigation: TNavigation
  allOffersUrl: string
}

export const AllMachinesFooter: React.FC<Props> = (props) => {
  const { t } = useTranslation(localeNamespace.common)

  return (
    <div className={styles.footer}>
      <div className={styles.left}>
        <div className={styles.description}>
          <Typography variant='h6'>
            {t(translations.headerMainNavPopularBrandsTitle)}
          </Typography>
        </div>
        <PopularTractorBrands
          onEntryClick={props.onClick}
          navigation={props.brandsNavigation}
        />
      </div>
      <div>
        <Button href={props.allOffersUrl} color='secondary'>
          {t(translations.headerMainNavAllOffersButtonText)}
        </Button>
      </div>
    </div>
  )
}
