import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'
import { TFilterOption } from '@renderer-ui-library/molecules'
import { TDisplayDefinitionEntryBoolean } from '@website-shared-library/displayDefinition/entry/DisplayDefinitionEntryBoolean'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useMemo } from 'react'
import { tracker } from 'utils/tracking/tracker'
import { useAutocompleteItem } from './AutocompleteItem'
import { SearchFilterCategory } from './SearchFilterCategory/SearchFilterCategory'

type Props = {
  options: TDisplayDefinitionEntryBoolean[]
}

export const FeaturesFilter: React.FC<Props> = React.memo((props) => {
  const { features, ...searchParams } = useSearchParams()
  const { gotoSearchResults } = useSearchResultsRouting()
  const { t } = useTranslation(localeNamespace.common)
  const { renderOption } = useAutocompleteItem()

  const options = useMemo<TFilterOption[]>(
    () =>
      props.options
        .sort((a, b) => (a.label > b.label ? 1 : -1))
        .map(({ machineAttribute, label }) => ({
          label,
          id: machineAttribute,
          selected: features.includes(
            machineAttribute as TFilterableMachineAttribute
          ),
        })),
    [props.options, features]
  )

  const setFeatures = useCallback(
    (features: TFilterableMachineAttribute[]) =>
      gotoSearchResults({
        ...searchParams,
        features,
      }),
    [gotoSearchResults, searchParams]
  )

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent,
      value: TFilterOption[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<TFilterOption>
    ) => {
      const newFeatures = value.map(
        (option) => option.id
      ) as TFilterableMachineAttribute[]

      const addedFeatures = newFeatures.filter(
        (feat: TFilterableMachineAttribute) => !features.includes(feat)
      )

      setFeatures(newFeatures)

      // only track the last selected feature
      if (addedFeatures.length > 0) {
        tracker.trackEvent({
          name: 'filter-applied',
          filter_placement: 'serp',
          filter_category: 'feature',
          filter_feature: addedFeatures[0],
          is_interaction: true,
        })
      }
    },
    [features, setFeatures]
  )

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        placeholder={t(translations.searchResultsFiltersFeaturePlaceholder)}
        size='small'
      />
    ),
    [t]
  )

  const autocompleteValue = useMemo<TFilterOption[]>(() => {
    return options.filter((option) => option.selected)
  }, [options])

  return (
    <SearchFilterCategory
      title={t(translations.searchResultsFiltersFeatureLabel)}
    >
      <Autocomplete
        multiple
        disableCloseOnSelect
        limitTags={3}
        id='features-filter'
        options={options}
        renderOption={renderOption}
        renderInput={renderInput}
        value={autocompleteValue}
        onChange={handleChange}
      />
    </SearchFilterCategory>
  )
})

FeaturesFilter.displayName = 'FeaturesFilter'
