import classNames from 'classnames'
import React, { Children } from 'react'
import styles from './uiButtonGroup.module.scss'

type Props = {
  alignment?: 'left' | 'center'
  children?: React.ReactNode
}

export const UIButtonGroup: React.FC<Props> = (props) => (
  <div
    className={classNames(styles.buttonGroup, {
      [styles.center]: props.alignment === 'center',
    })}
  >
    {Children.map(props.children, (child) => {
      if (child) {
        return <div className={styles.button}>{child}</div>
      }
    })}
  </div>
)
