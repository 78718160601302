import { ChevronRightOutlined } from '@mui/icons-material'
import { Button, ServiceTitle } from '@renderer-ui-library/atoms'
import { Service, ServiceTypes } from 'blocks/ServiceBlock/IServiceBlockData'
import React, { useCallback } from 'react'
import { ClickEventTargetName } from 'utils/tracking/TTrackingEvent'
import { tracker } from 'utils/tracking/tracker'

import styles from './serviceBlockButton.module.scss'
import { ServiceEntries } from './serviceEntries'

interface ServiceBlockButton {
  service: Service
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    service: Service
  ) => void
}

const clickEvents: Record<ServiceTypes, ClickEventTargetName> = {
  [ServiceTypes.Inspection]: 'service_block.inspection',
  [ServiceTypes.Warranty]: 'service_block.warranty',
  [ServiceTypes.Transport]: 'service_block.transport',
  [ServiceTypes.Payment]: 'service_block.payment',
  [ServiceTypes.Financing]: 'service_block.financing',
}

export const ServiceBlockButton: React.FC<ServiceBlockButton> = React.memo(
  ({ service, onClick }) => {
    const { title, icon } = ServiceEntries[service.serviceType]

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick(event, service)
        tracker.trackEvent({
          name: 'click',
          target_name: clickEvents[service.serviceType],
          is_interaction: true,
        })
      },
      [onClick, service]
    )

    return (
      <Button
        variant='contained'
        fullWidth
        noMinWidth={true}
        color='secondary'
        onClick={handleClick}
      >
        <div className={styles.wrapper}>
          <ServiceTitle title={title} icon={icon} />
          <ChevronRightOutlined
            color='primary'
            fontSize='small'
            className={styles.chevron}
          />
        </div>
      </Button>
    )
  }
)

ServiceBlockButton.displayName = 'ServiceBlockButton'
