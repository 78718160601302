import { TNavigationEntry } from '@internal/utils/navigation/TNavigationEntry'
import {
  PopularTractorBrands,
  SocialButtons,
} from '@renderer-ui-library/molecules'
import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { TNavigation } from 'utils/navigation/TNavigation'
import { tracker } from 'utils/tracking/tracker'
import { allCategoriesNavigationKey } from '../../NavigationKeys'
import { MenuDivider } from '../MenuDivider/MenuDivider'
import { MenuEntry } from '../MenuEntry/MenuEntry'
import { MenuLink } from '../MenuLink/MenuLink'
import { TContent } from '../MobileMenu'
import styles from './levelOneNavigation.module.scss'

type Props = {
  primaryNavigation: TNavigation
  secondaryNavigation: TNavigation
  brandsNavigation: TNavigation
  onChangeMenuLocation: (location: TContent, entry?: TNavigationEntry) => void
  onEntryClick: () => void
}

export const LevelOneNavigation: React.FC<Props> = ({
  primaryNavigation,
  secondaryNavigation,
  brandsNavigation,
  onChangeMenuLocation,
  onEntryClick,
}) => {
  const { t } = useTranslation(localeNamespace.common)

  const onAllMachinesClick = useCallback(() => {
    tracker.trackEvent({
      name: 'click',
      target_name: 'main_menu.all_machinery',
      is_interaction: true,
    })
    onChangeMenuLocation('AllMachines')
  }, [onChangeMenuLocation])

  const onMenuLinkClick = useCallback(
    (entry: TNavigationEntry) => {
      const hasSubEntries = entry.children.length > 0
      if (hasSubEntries) {
        onChangeMenuLocation('LevelTwo', entry)
      } else {
        onEntryClick()
      }
    },
    [onChangeMenuLocation, onEntryClick]
  )

  return (
    <div className={styles.levelOneNavigationWrapper}>
      {primaryNavigation.entries.map((entry) => {
        if (entry.key === allCategoriesNavigationKey) {
          return (
            <div
              key='allMachines'
              className={styles.entry}
              onClick={onAllMachinesClick}
            >
              <MenuEntry
                title={t(translations.headerMainNavAllMachinesLabel)}
                hasSubEntries
              />
            </div>
          )
        }
        return (
          <MenuLink key={entry.id} entry={entry} onClick={onMenuLinkClick} />
        )
      })}
      <MenuDivider />
      {secondaryNavigation.entries.map((entry) => (
        <MenuLink key={entry.id} entry={entry} onClick={onMenuLinkClick} />
      ))}
      <MenuDivider />
      <Typography variant='body2' color='textSecondary'>
        {t(translations.headerMainNavPopularBrandsTitle)}
      </Typography>
      <div className={styles.brands}>
        <div className={styles.brandsContent}>
          <PopularTractorBrands
            onEntryClick={onEntryClick}
            navigation={brandsNavigation}
          />
        </div>
      </div>
      <div className={styles.socialButtons}>
        <SocialButtons />
      </div>
    </div>
  )
}

LevelOneNavigation.displayName = 'LevelOneNavigation'
