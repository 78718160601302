import { RatioContainer } from '@renderer-ui-library/atoms'
import Image from 'next/image'
import { ImageLoader } from 'utils/media/ImageLoader'
import styles from './fittedImage.module.scss'

type Props = {
  ratio: number
  src: string
  alt: string
}

export const FittedImage: React.FC<Props> = (props) => {
  return (
    <RatioContainer ratio={props.ratio}>
      <Image
        className={styles.image}
        src={props.src}
        alt={props.alt}
        layout='fill'
        loader={ImageLoader}
      />
    </RatioContainer>
  )
}
