import { Close } from '@mui/icons-material'
import { Box, Modal, Typography } from '@renderer-ui-library/mui'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import classNames from 'classnames'
import 'lazysizes'
import React, {
  LegacyRef,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import { TMedia } from '../Media/TMedia'
import styles from './mediaSliderOverlay.module.scss'

export type MediaSliderOverlayProps = {
  media: TMedia[]
  imageRatio: number
  open: boolean
  activeIndex: number
  onClose: () => void
  handleMediaChange: (activeIndex: number) => void
}

export const MediaSliderOverlay: React.FC<MediaSliderOverlayProps> = (
  props
) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const mainSliderRef = useRef<Splide>()
  const thumbSliderRef = createRef<Splide>()

  useEffect(() => {
    if (mainSliderRef?.current && thumbSliderRef?.current?.splide) {
      mainSliderRef?.current?.sync(thumbSliderRef.current.splide)
    }
  }, [mainSliderRef, thumbSliderRef])

  useEffect(() => {
    if (props.open) {
      setActiveIndex(props.activeIndex)
    } else {
      setActiveIndex(0)
    }
  }, [props.activeIndex, props.open])

  useEffect(() => {
    mainSliderRef?.current?.splide?.go(activeIndex)
  }, [activeIndex])

  const handleSlideChange = useCallback(
    (_: any, newIndex: number) => {
      props.handleMediaChange(newIndex)
      setActiveIndex(newIndex)
    },
    [props]
  )

  const slides = useMemo(
    () =>
      props.media.map((media, index) => (
        <SplideSlide key={`${index}=${media.attributes.url}`}>
          <img
            alt=''
            data-src={
              getMediaSrc(
                { data: media },
                {
                  ratio:
                    typeof media.attributes.ratio === 'number' &&
                    media.attributes.ratio <= 1
                      ? 'original'
                      : props.imageRatio,
                  isExtraLarge: true,
                }
              ).src
            }
            className={classNames(styles.image, 'lazyload')}
          />
        </SplideSlide>
      )),
    [props.imageRatio, props.media]
  )

  return (
    <Modal open={props.open} className={styles.modal} onClose={props.onClose}>
      <Box className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>
              <Typography color='inherit'>
                {`${activeIndex + 1}/${props.media?.length}`}
              </Typography>
            </div>
            <Close
              className={styles.closeButton}
              onClick={props.onClose}
              color='inherit'
            />
          </div>
          <div className={styles.main}>
            <Splide
              ref={mainSliderRef as LegacyRef<Splide>}
              options={{
                width: '100%',
                heightRatio: 1 / props.imageRatio,
                pagination: false,
                easing: 'ease',
              }}
              onMoved={handleSlideChange}
            >
              {slides}
            </Splide>
          </div>
        </div>

        {props.media.length > 1 && (
          <div className={styles.thumbnails}>
            <Splide
              ref={thumbSliderRef}
              options={{
                fixedWidth: '14%',
                fixedHeight: 85,
                gap: 10,
                cover: true,
                pagination: false,
                easing: 'ease',
                focus: 'center',
                isNavigation: true,
                lazyLoad: 'nearby',
              }}
              onMoved={handleSlideChange}
            >
              {slides}
            </Splide>
          </div>
        )}
      </Box>
    </Modal>
  )
}

MediaSliderOverlay.displayName = 'MediaSliderOverlay'
