import { SystemPageKey } from 'page/SystemPageKey'
import { TPageData } from 'page/TPageData'
import { TSystemPageData } from 'page/TSystemPageData'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { TrackingPageType } from '../TTrackingEvent'

export const getTrackingPageType = (
  pageData: TPageData
): TrackingPageType | null => {
  switch (pageData.type) {
    case PageTypeKey.SystemPage:
      const systemPageData = pageData as TSystemPageData
      switch (systemPageData.key) {
        case SystemPageKey.StartPage:
          return 'start'
        case SystemPageKey.NotFound:
          return '404'
        case SystemPageKey.PDP:
          return 'pdp'
        case SystemPageKey.SearchResults:
          return 'serp'
      }
      break
    case PageTypeKey.MachineCategoryPage:
      return 'machine_category_page'

    case PageTypeKey.Page:
      return pageData.trackingPageType
  }
  return null
}
