import { Text } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import styles from './wizardDataProtectionDisclaimer.module.scss'

export const WizardDataProtectionDisclaimer: React.FC = () => {
  const { t } = useTranslation(localeNamespace.common)

  return (
    <>
      <div className={styles.disclaimerHeadline}>
        <Typography variant='h6'>
          {t(translations.requestInformationWizardContactDataProtectionLabel)}
        </Typography>
      </div>
      <Text
        variant='body2'
        text={t(translations.requestInformationWizardContactDataProtectionText)}
      />
    </>
  )
}
