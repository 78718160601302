export const extractPhoneNumberLink = (phone: string) => {
  const formatNumberRegEx = /(tel:[+][0-9]+)/g
  const extractedNumberLink = phone.match(formatNumberRegEx)

  if (extractedNumberLink === null) {
    return null
  }

  return extractedNumberLink[0]
}
