import { Chevrons, Text } from '@renderer-ui-library/atoms'
import { Grid, Typography } from '@renderer-ui-library/mui'
import React from 'react'
import styles from './blockTopText.module.scss'

interface Props {
  title?: string | null
  text?: string | null
  highlight?: boolean
  contentRight?: React.ReactNode
}

export const BlockTopText: React.FC<Props> = React.memo(
  ({ title, text, highlight, contentRight }) => (
    <Grid container>
      <Grid item xs={12} md={contentRight ? 8 : 12} className={styles.left}>
        {title && (
          <div className={styles.title}>
            <Chevrons />
            <Typography
              variant='h2'
              color={highlight ? 'white' : 'textPrimary'}
            >
              {title}
            </Typography>
          </div>
        )}
        {text && (
          <Text
            text={text}
            highlight={highlight}
            color={highlight ? 'bright' : 'dark'}
          />
        )}
      </Grid>
      {contentRight && (
        <Grid
          item
          xs={12}
          md={4}
          display='flex'
          alignItems='flex-start'
          justifyContent='flex-end'
          className={styles.right}
        >
          {contentRight}
        </Grid>
      )}
    </Grid>
  )
)

BlockTopText.displayName = 'BlockTopText'
