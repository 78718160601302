import {
  TextField as MuiTextField,
  TextFieldProps,
} from '@renderer-ui-library/mui'
import classNames from 'classnames'
import styles from './textField.module.scss'

export const TextField: React.FC<TextFieldProps> = (props) => (
  <MuiTextField
    sx={{ background: 'white', ...props.sx }}
    {...props}
    InputProps={{
      ...props.InputProps,
      className: classNames(props.InputProps?.className, styles.textField),
    }}
  />
)
