import { TNavigationEntry } from '@internal/utils/navigation/TNavigationEntry'
import { MenuLink } from '../MenuLink/MenuLink'

type Props = {
  entry: TNavigationEntry
  onEntryClick: () => void
}

export const LevelTwoNavigation: React.FC<Props> = (props) => {
  return (
    <>
      {props.entry.children.map((entry) => (
        <MenuLink key={entry.id} entry={entry} onClick={props.onEntryClick} />
      ))}
    </>
  )
}

LevelTwoNavigation.displayName = 'LevelTwoNavigation'
