import { NextRouter } from 'next/router'

export const getFullUrl = (router: NextRouter) => {
  const path = router.asPath
  if (path.indexOf(`/${router.locale}`) === 0) {
    return path
  }

  return `/${router.locale}${path}`
}
