import { MachineCategoryPageModelType } from '@internal/page/TMachineCategoryPageData'
import { KnownBaleChamberType } from '@website-shared-library/machine/KnownBalerChamberType'
import { KnownMachineHeaderType } from '@website-shared-library/machine/KnownMachineHeaderType'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { TFilterableBaleChamberOption } from '@website-shared-library/search/TFilterableBalerChamberOption'
import { TFilterableHeaderTypeOption } from '@website-shared-library/search/TFilterableHeaderTypeOption'
import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import {
  isValidBaleChamberOption,
  isValidHeaderTypeOption,
} from '@website-shared-library/search/isValidBaleChamberOption'
import { isValidFilterableMachineAttribute } from '@website-shared-library/search/isValidFilterableMachineAttribute'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import { PageContext } from 'page/PageContext'
import { ParsedUrlQuery } from 'querystring'
import { useContext, useEffect, useMemo, useState } from 'react'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { SearchResultsSortKey } from './SearchResultsSortKey'
import { TRangeFiltersMap } from './TRangeFiltersMap'
import { mappedModelSuffix, SearchModel, TSearchParams } from './TSearchParams'
import { getCleanMachineSubType } from './getCleanMachineType'
import { isValidSortValue } from './isValidSortValue'
import { loadChunkSize } from './loadChunkSize'

const queryParamToMachineAttribute = (param: string) =>
  param.toUpperCase().replace(/-/g, '_')

const toArray = (items: string | string[] | undefined): string[] =>
  items ? (Array.isArray(items) ? items : [items]) : []

export const getLocations = (items: string | string[] | undefined): string[] =>
  toArray(items).map((location) => location.toUpperCase())

export const getFeatures = (items: string | string[] | undefined) =>
  toArray(items)
    .map(queryParamToMachineAttribute)
    .filter(isValidFilterableMachineAttribute)

export const getBaleChamber = (option: string): TFilterableBaleChamberOption =>
  isValidBaleChamberOption(option) ? option : KnownBaleChamberType.FIXED_CHAMBER

export const getHeaderType = (option: string): TFilterableHeaderTypeOption =>
  isValidHeaderTypeOption(option) ? option : KnownMachineHeaderType.HEADER

export const getSort = (item: string | string[] | undefined) => {
  const sort = `${item}` as SearchResultsSortKey
  return isValidSortValue(sort) ? sort : SearchResultsSortKey.RelevanceAsc
}

export const getRangeFiltersMap = (queryParams: ParsedUrlQuery) => {
  return Object.keys(queryParams).reduce<TRangeFiltersMap>((map, key) => {
    const attribute = queryParamToMachineAttribute(key)
    if (isValidFilterableMachineAttribute(attribute)) {
      const value = (queryParams[key] ?? '').toString()

      const values = value.replace('x', '').split('-')
      const minValue = parseInt(values[0] ?? '')
      const maxValue = parseInt(values[1] ?? '')
      const min = isNaN(minValue) ? undefined : minValue
      const max = isNaN(maxValue) ? undefined : maxValue

      if (min || max) {
        map[attribute] = {
          min,
          max,
        }
      }
    }
    return map
  }, {})
}

export const useSearchParams = () => {
  const {
    query: {
      brand: queryBrand,
      model: queryModel,
      baseModel: queryBaseModel,
      machineSubType: queryMachineSubType,
      headerType: queryHeaderType,
      baleChamber: queryBaleChamber,
      amount: queryAmount,
      feature: queryFeature,
      location: queryLocation,
      sort: querySort,
      ...queryRangeFilters
    },
  } = useRouter()

  const { pageData, machineType } = useContext(PageContext)
  const machineCategoryPageParams = useMemo(() => {
    if (!pageData || pageData.type !== PageTypeKey.MachineCategoryPage) {
      return null
    }

    const data: {
      machineType: MachineType
      model?: SearchModel | null
      brand?: string | null
    } = {
      machineType: pageData.machineType,
    }

    if (pageData.brand) {
      data.brand = pageData.brand
    }

    if (pageData.model) {
      data.model = {
        name: pageData.model,
        type:
          pageData.modelType === MachineCategoryPageModelType.Model
            ? 'mapped'
            : pageData.modelType === MachineCategoryPageModelType.BaseModel
              ? 'base'
              : 'raw',
      }
    }
    return data
  }, [pageData])
  const parsedAmount = parseInt(`${queryAmount}`)
  const amount = isNaN(parsedAmount) ? loadChunkSize : parsedAmount

  // BRANDS
  const [brands, setBrands] = useState<string[]>(
    toArray(queryBrand ?? machineCategoryPageParams?.brand ?? undefined)
  )

  useEffect(() => {
    const newBrands = toArray(
      queryBrand ?? machineCategoryPageParams?.brand ?? undefined
    )
    if (!isEqual(newBrands, brands)) {
      setBrands(newBrands)
    }
  }, [brands, queryBrand, pageData, machineCategoryPageParams?.brand])

  // LOCATIONS
  const [locations, setLocations] = useState<string[]>(
    getLocations(queryLocation)
  )
  useEffect(() => {
    const newLocations = getLocations(queryLocation)
    if (!isEqual(newLocations, locations)) {
      setLocations(newLocations)
    }
  }, [locations, queryLocation])

  // FEATURES
  const [features, setFeatures] = useState<TFilterableMachineAttribute[]>(
    getFeatures(queryFeature)
  )
  useEffect(() => {
    const newFeatures = getFeatures(queryFeature)
    if (!isEqual(newFeatures, features)) {
      setFeatures(newFeatures)
    }
  }, [features, queryFeature])

  // RANGE FILTERS
  const [rangeFilters, setRangeFilters] = useState<TRangeFiltersMap>(
    getRangeFiltersMap(queryRangeFilters)
  )

  // BALE_CHAMBER
  const baleChamber = queryBaleChamber
    ? getBaleChamber(queryBaleChamber?.toString())
    : undefined

  useEffect(() => {
    const newRangeFilters = getRangeFiltersMap(queryRangeFilters)
    if (!isEqual(newRangeFilters, rangeFilters)) {
      setRangeFilters(newRangeFilters)
    }
  }, [queryRangeFilters, rangeFilters])

  // HEADER_TYPE
  const headerType = queryHeaderType
    ? getHeaderType(queryHeaderType?.toString())
    : undefined

  const params = useMemo<TSearchParams>(() => {
    const machineSubType = machineType
      ? getCleanMachineSubType(queryMachineSubType?.toString(), machineType)
      : null

    let model: SearchModel | undefined

    if (typeof queryModel === 'string' && queryModel.length > 0) {
      model = queryModel
        ? {
            name: queryModel.replace(mappedModelSuffix, ''),
            type: queryModel.endsWith(mappedModelSuffix) ? 'mapped' : 'raw',
          }
        : undefined
    } else if (
      typeof queryBaseModel === 'string' &&
      queryBaseModel.length > 0
    ) {
      model = {
        name: queryBaseModel,
        type: 'base',
      }
    } else if (machineCategoryPageParams?.model) {
      model = machineCategoryPageParams.model
    }

    const sort = getSort(querySort)

    const params: TSearchParams = {
      model,
      brands,
      machineType: machineType ?? undefined,
      machineSubType: machineSubType ?? undefined,
      headerType,
      baleChamber,
      amount,
      features,
      rangeFilters,
      locations,
      sort,
    }

    return params
  }, [
    machineCategoryPageParams,
    machineType,
    queryMachineSubType,
    queryModel,
    queryBaseModel,
    querySort,
    brands,
    headerType,
    baleChamber,
    amount,
    features,
    rangeFilters,
    locations,
  ])

  return params
}
