import type {
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from '@mui/material'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import type { ReactNode, SyntheticEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { debounce, identity } from 'lodash'

import { areOptionsOfEqualValue } from './areOptionsOfEqualValue'
import styles from './remoteAutoComplete.module.scss'
import { SelectFilterOption } from './SelectFilterOption'
import { useSelectItem } from './SelectItem'

type BaseProps<T extends SelectFilterOption> = {
  id?: string
  disabled?: boolean
  required?: boolean
  label?: string
  tabIndex?: number
  wrapText?: boolean
  /**
   * via valuesReference you can couple the currently loeaded values to a specific reference
   * when the reference changes, the values will be cleared
   **/
  valuesReference?: unknown
  loadValues: (input: string, abortSignal: AbortSignal) => Promise<T[]>
  grouping?: boolean
  noOptionsText?: React.ReactNode
  inputEndAdornment?: ReactNode
  inputPlaceholder?: string
  clearOnBlur?: boolean
  listboxProps?: {
    height?: string | number
  }
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
  initialValues?: T[]
  fallbackValues?: T[]
  renderInput?: (params: AutocompleteRenderInputParams) => React.JSX.Element
  transparentBackground?: boolean
}

type SingleOptionProps<T extends SelectFilterOption> = BaseProps<T> & {
  type: 'single'
  value: T | null
  onChange: (newValue: T | null) => void
  freeSolo?: false
}
type SingleOptionPropsFreeSolo<T extends SelectFilterOption> = BaseProps<T> & {
  type: 'single'
  value: T | null
  onChange: (newValue: T | string | null) => void
  freeSolo: true
}

type MultipleOptionProps<T extends SelectFilterOption> = BaseProps<T> & {
  type: 'multiple'
  value: T[]
  onChange: (newValue: T[]) => void
  freeSolo?: false
}
type MultipleOptionPropsFreeSolo<T extends SelectFilterOption> =
  BaseProps<T> & {
    type: 'multiple'
    value: T[]
    onChange: (newValue: T[]) => void
    freeSolo: true
  }

type Props<T extends SelectFilterOption> =
  | SingleOptionProps<T>
  | SingleOptionPropsFreeSolo<T>
  | MultipleOptionProps<T>
  | MultipleOptionPropsFreeSolo<T>

const getOptionLabel = (option: string | SelectFilterOption) => {
  if (typeof option === 'string') {
    return option
  }
  return option?.label ?? ''
}

const groupBy = (option: SelectFilterOption) => option.group ?? ''

const PrivateRemoteAutoComplete = <T extends SelectFilterOption>({
  type,
  id,
  value,
  disabled,
  onChange,
  label,
  required,
  loadValues,
  tabIndex,
  wrapText,
  valuesReference,
  grouping,
  noOptionsText,
  freeSolo,
  inputEndAdornment,
  inputPlaceholder,
  clearOnBlur,
  listboxProps,
  onClose,
  onOpen,
  open,
  fallbackValues,
  initialValues,
  renderInput,
  transparentBackground,
}: Props<T>) => {
  const [options, setOptions] = useState<T[]>(initialValues ?? [])

  const [isLoading, setIsLoading] = useState(false)
  const abortControllerRef = useRef<AbortController | null>(null)

  const { renderSelectItem } = useSelectItem({ wrapText })

  useEffect(
    () => setOptions(fallbackValues ?? []),
    [fallbackValues, valuesReference]
  )

  const handleChange = useCallback(
    (
      ev: SyntheticEvent,
      value: string | T | (string | T)[] | null,
      reason: AutocompleteChangeReason
    ) => {
      if (type === 'multiple' && Array.isArray(value)) {
        const ops = value.reduce<T[]>((list, current) => {
          if (typeof current === 'string') {
            const element = options.find(
              (o) => o.value.toLowerCase() === current.trim().toLowerCase()
            )
            if (element) {
              return list.concat(element)
            }
            return list
          }
          return list.concat(current)
        }, [])

        onChange(ops)
        if (ops.length === 0 && fallbackValues) {
          setOptions(fallbackValues)
        }
      } else if (type === 'single' && !Array.isArray(value)) {
        if (typeof value === 'string') {
          const element = options.find(
            (o) => o.value.toLowerCase() === value.trim().toLowerCase()
          )
          if (freeSolo) {
            onChange(element ?? value)
          } else {
            onChange(element ?? null)
          }
        } else {
          onChange(value)
        }
      }
    },
    [type, onChange, fallbackValues, options, freeSolo]
  )

  const fetchValues = useMemo(() => {
    const debouncedFn = debounce((input: string) => {
      abortControllerRef.current = new AbortController()
      setIsLoading(true)
      void loadValues(input, abortControllerRef.current.signal)
        .then(setOptions)
        .catch((err) => {
          // ignore
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 200)

    return (input: string) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      if (input.length >= 2) {
        debouncedFn(input)
      } else {
        debouncedFn.flush()
        setOptions([])
      }
    }
  }, [loadValues])

  const handleTyping = useCallback(
    (
      ev: SyntheticEvent,
      input: string,
      reason: 'input' | 'clear' | 'reset'
    ) => {
      if (reason === 'input') {
        fetchValues(input)
      } else if (reason === 'clear') {
        setOptions(fallbackValues ?? [])
      }
    },
    [fallbackValues, fetchValues]
  )

  const renderDefaultInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        required={required}
        label={label}
        size='small'
        inputProps={{
          ...params.inputProps,
          tabIndex,
          placeholder: inputPlaceholder,
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {isLoading ? (
                <CircularProgress color='inherit' size={20} />
              ) : null}
              {params.InputProps.endAdornment ?? inputEndAdornment}
            </React.Fragment>
          ),
        }}
      />
    ),
    [required, label, tabIndex, isLoading, inputEndAdornment, inputPlaceholder]
  )

  return (
    <Autocomplete
      className={
        transparentBackground ? '' : styles.autoCompleteWithDefaultBackground
      }
      multiple={type === 'multiple'}
      disablePortal
      id={id}
      options={options}
      filterOptions={identity /* no filtering, we do it on the server */}
      fullWidth
      size='small'
      value={value}
      disabled={disabled}
      onInputChange={handleTyping}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={areOptionsOfEqualValue}
      loading={isLoading}
      disableCloseOnSelect={type === 'multiple'}
      renderOption={renderSelectItem}
      groupBy={grouping ? groupBy : undefined}
      renderInput={renderInput ?? renderDefaultInput}
      onChange={
        handleChange as (
          ev: SyntheticEvent,
          value:
            | string
            | SelectFilterOption
            | (string | SelectFilterOption)[]
            | null,
          reason: AutocompleteChangeReason
        ) => void /** necessary to cast, because MUI Autocomplete has issues dealing with our single / multiple values union type */
      }
      noOptionsText={noOptionsText}
      freeSolo={freeSolo}
      clearOnBlur={clearOnBlur}
      ListboxProps={{ style: { maxHeight: listboxProps?.height } }}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      forcePopupIcon
    />
  )
}

const MemoizedRemoteAutoComplete = React.memo(PrivateRemoteAutoComplete)
MemoizedRemoteAutoComplete.displayName = 'RemoteAutoComplete'
export const RemoteAutoComplete =
  MemoizedRemoteAutoComplete as typeof PrivateRemoteAutoComplete
