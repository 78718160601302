import { KeyboardArrowRight } from '@mui/icons-material'
import { Typography } from '@renderer-ui-library/mui'
import styles from './menuEntry.module.scss'

type Props = {
  title: string
  hasSubEntries?: boolean
}

export const MenuEntry: React.FC<Props> = ({ title, hasSubEntries }) => {
  return (
    <Typography
      variant={'body1'}
      color={'textPrimary'}
      className={styles.menuEntry}
      component={'span'}
    >
      {title}
      {hasSubEntries && <KeyboardArrowRight fontSize='small' />}
    </Typography>
  )
}

MenuEntry.displayName = 'MenuEntry'
