import { Container } from '@renderer-ui-library/mui'
import classNames from 'classnames'
import styles from './headerContentWrapper.module.scss'

type Props = {
  className?: string
  children?: React.ReactNode
}
export const HeaderContentWrapper: React.FC<Props> = (props) => (
  <div className={classNames(styles.wrapper, props.className)}>
    <Container maxWidth='lg' disableGutters>
      {props.children}
    </Container>
  </div>
)
