import { createContext, memo, PropsWithChildren } from 'react'

type UserContext = {
  user: {
    country: string | null
  }
}

export const defaultUserContext: UserContext = {
  user: {
    country: null,
  },
}

export const UserContext = createContext<UserContext>(defaultUserContext)

export const UserProvider: React.FC<PropsWithChildren<UserContext>> = memo(
  ({ children, ...value }) => (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  )
)

UserProvider.displayName = 'UserProvider'
