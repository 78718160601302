import {
  CallEvent,
  ClickEvent,
  CmsClickEventTargetName,
  GeneralPageTrackingPageType,
} from './TTrackingEvent'

export type GeneralPageTrackingPageTypeCmsNotation =
  | 'aboutUs'
  | 'auction'
  | 'buyingAbroad'
  | 'career'
  | 'contact'
  | 'cookiePolicy'
  | 'cookieSettings'
  | 'happyCustomers'
  | 'imprint'
  | 'magazine'
  | 'marketingLandingpage'
  | 'pressNews'
  | 'privacyPolicy'
  | 'referAFriend'
  | 'service_financing'
  | 'service_inspection'
  | 'service_payment'
  | 'service_transport'
  | 'service_warranty'
  | 'termsAndConditions'
  | 'whyEFarm'

const mapPageTypeEvents: Record<
  GeneralPageTrackingPageTypeCmsNotation,
  GeneralPageTrackingPageType
> = {
  aboutUs: 'about_us',
  auction: 'auction',
  buyingAbroad: 'buying_abroad',
  career: 'career',
  contact: 'contact',
  cookiePolicy: 'cookie_policy',
  cookieSettings: 'cookie_settings',
  happyCustomers: 'happy_customers',
  imprint: 'imprint',
  magazine: 'magazine',
  marketingLandingpage: 'marketing_landingpage',
  pressNews: 'press_news',
  privacyPolicy: 'privacy_policy',
  referAFriend: 'refer_a_friend',
  service_financing: 'service.financing',
  service_inspection: 'service.inspection',
  service_payment: 'service.payment',
  service_transport: 'service.transport',
  service_warranty: 'service.warranty',
  termsAndConditions: 'terms_and_conditions',
  whyEFarm: 'why_e_farm',
}
export const convertCmsTrackingPageType = (
  cmsTrackingPageType: GeneralPageTrackingPageTypeCmsNotation
) => mapPageTypeEvents[cmsTrackingPageType]

export type CmsClickEventTargetNameCmsNotation =
  | 'click_contact_contactPage_dropUsALine' // Click on "Drop us a line" on the contact page
  | 'click_topMenu_aboutUs'
  | 'click_topMenu_whyEFarm'
  | 'click_topMenu_happyCustomers'
  | 'click_topMenu_buyingAbroad'
  | 'click_topMenu_pressNews'
  | 'click_topMenu_career'
  | 'click_mainMenu_tractors'
  | 'click_mainMenu_combines'
  | 'click_mainMenu_balers'
  | 'click_mainMenu_potatoHarvesters'
  | 'click_mainMenu_telehandlers'
  | 'click_block_popularBrand'
  | 'click_topMenu_auctions'

const clickEventMapping: Record<
  CmsClickEventTargetNameCmsNotation,
  CmsClickEventTargetName
> = {
  click_contact_contactPage_dropUsALine: 'contact.contact_page.drop_us_a_line',
  click_topMenu_aboutUs: 'top_menu.about_us',
  click_topMenu_whyEFarm: 'top_menu.why_e_farm',
  click_topMenu_happyCustomers: 'top_menu.happy_customers',
  click_topMenu_buyingAbroad: 'top_menu.buying_abroad',
  click_topMenu_pressNews: 'top_menu.press_news',
  click_topMenu_career: 'top_menu.career',
  click_mainMenu_tractors: 'main_menu.tractors',
  click_mainMenu_combines: 'main_menu.combines',
  click_mainMenu_balers: 'main_menu.balers',
  click_mainMenu_potatoHarvesters: 'main_menu.potato_harvesters',
  click_mainMenu_telehandlers: 'main_menu.telehandlers',
  click_block_popularBrand: 'block.popular_brand',
  click_topMenu_auctions: 'top_menu.auctions',
}

export const convertCmsTrackingClickEvent = (
  trackingClickEvent: CmsClickEventTargetNameCmsNotation
): ClickEvent | CallEvent => ({
  name: 'click',
  target_name: clickEventMapping[trackingClickEvent],
  is_interaction: true,
})
