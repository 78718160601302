import {
  TLinkToCategoryPageData,
  TLinkToPageData,
  TLinkToSystemPageData,
} from '@renderer-ui-library/molecules'
import { useCallback } from 'react'
import { useMachineCategoryPageUrl } from '../hooks/useMachineCategoryPageUrl'
import { urlFor } from './urlFor'
import { urlForSystemPageWithoutParams } from './urlForSystemPageWithoutParams'

export const usePrioritizedUrl = () => {
  const { getMachineCategoryPageUrl } = useMachineCategoryPageUrl()

  const getPrioritizedUrl = useCallback(
    ({
      url,
      systemPage,
      machineCategoryPage,
      page,
    }: {
      url?: string
      systemPage?:
        | { data: null }
        | {
            data: { id: number; attributes: TLinkToSystemPageData }
          }
      machineCategoryPage?:
        | { data: null }
        | {
            data: { id: number; attributes: TLinkToCategoryPageData }
          }
      page?:
        | { data: null }
        | { data: { id: number; attributes: TLinkToPageData } }
    }) => {
      if (machineCategoryPage?.data) {
        return getMachineCategoryPageUrl({
          locale: machineCategoryPage.data.attributes.locale,
          machineType: machineCategoryPage.data.attributes.machineType,
          brandSlug: machineCategoryPage.data.attributes.brandSlug,
          modelSlug: machineCategoryPage.data.attributes.modelSlug,
        })
      }

      if (systemPage?.data) {
        return urlForSystemPageWithoutParams(
          systemPage.data.attributes.locale,
          systemPage.data.attributes.key
        )
      }

      if (page?.data) {
        return urlFor(page.data.attributes.locale, page.data.attributes.slug)
      }

      return url ? url : undefined
    },
    [getMachineCategoryPageUrl]
  )

  return { getPrioritizedUrl }
}
