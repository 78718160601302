export enum KnownBrands {
  New_Holland = 'New Holland',
  Massey_Ferguson = 'Massey Ferguson',
  Case_IH = 'Case IH',
  Claas = 'Claas',
  Deutz_Fahr = 'Deutz-Fahr',
  Fendt = 'Fendt',
  John_Deere = 'John Deere',
  Kubota = 'Kubota',
}
