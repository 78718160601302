import { extractPhoneNumberLink } from '@internal/utils/numbers/extractPhonenumberLink'
import { ArrowForward } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { TMediaFile } from '@renderer-ui-library/molecules'
import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import { tracker } from 'utils/tracking/tracker'
import styles from './contactUsCard.module.scss'

interface Props {
  name: string
  phoneNumber: string
  languagesSpoken: string
  image: { data: TMediaFile }
  email: string
}

export const ContactUsCard = (props: Props) => {
  const { t } = useTranslation(localeNamespace.common)
  const {
    name = '',
    phoneNumber = '',
    languagesSpoken = '',
    image,
    email = '',
  } = props

  const phoneLink = extractPhoneNumberLink(phoneNumber)

  const handleCallClick = useCallback(() => {
    tracker.trackEvent({
      name: 'call-click',
      target_name: 'contact_block.call_button',
      is_interaction: true,
    })
  }, [])

  return (
    <>
      <div className={styles.imageWrapper}>
        <img
          loading='lazy'
          alt={name}
          {...getMediaSrc(image, { ratio: 3 / 2 })}
        />
      </div>
      <div className={styles.contentWrapper}>
        <Typography variant='h6' color={'textSecondary'}>
          {name}
        </Typography>

        <div className={styles.contentInfoWrapper}>
          <Typography variant='body1' fontWeight={'bold'}>
            {`${t(translations.languagesKey)}: `}
          </Typography>
          <Typography variant='body1'>{languagesSpoken}</Typography>
        </div>

        <div className={styles.contentInfoWrapper}>
          <Typography variant='body1' fontWeight={'bold'}>
            {`${t(translations.phoneKey)}: `}
          </Typography>
          <Typography variant='body1' className={styles.link}>
            <ReactMarkdown>{phoneNumber}</ReactMarkdown>
          </Typography>
        </div>

        <div className={styles.contentInfoWrapper}>
          <Typography variant='body1' fontWeight={'bold'}>
            {`${t(translations.emailKey)}: `}
          </Typography>
          <a href={`mailto: ${email}`}>
            <Typography color={'primaryLight'} className={styles.link}>
              {email}
            </Typography>
          </a>
        </div>

        {phoneLink && (
          <Button
            variant='text'
            size='small'
            noMinWidth
            color={'primary'}
            endIcon={<ArrowForward />}
            sx={{ marginTop: '24px' }}
            href={phoneLink}
            onClick={handleCallClick}
          >
            {t(translations.callKey, { name: name.split(' ')[0] })}
          </Button>
        )}
      </div>
    </>
  )
}

ContactUsCard.displayName = 'ContactUsCard'
