import { RequestSubmissionFormType } from '@internal/utils/tracking/TTrackingEvent'
import { createContext } from 'react'

type ContactWizardContext = {
  openCallBackOption: (trackingEventName: RequestSubmissionFormType) => void
  openRequestInfo: () => void
  openContactUs: () => void
  openLeasingOption: () => void
  trackPageSource: (page: string) => void
}

export const ContactWizardContext = createContext<ContactWizardContext>({
  openRequestInfo: () => {},
  openContactUs: () => {},
  openLeasingOption: () => {},
  trackPageSource: () => {},
  openCallBackOption: () => {},
})
