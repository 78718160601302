import {
  ContactUsButton,
  TButtonAction,
  TButtonData,
} from '@renderer-ui-library/molecules'
import { Button, ButtonProps } from '@renderer-ui-library/mui'
import Link from 'next/link'
import React, { useCallback, useMemo } from 'react'
import { usePrioritizedUrl } from 'utils/routing/urls/usePrioritizedUrl'
import { convertCmsTrackingClickEvent } from 'utils/tracking/convertCmsTrackingEvents'
import { tracker } from 'utils/tracking/tracker'
import { InspectionButton } from '../InspectionButton/InspectionButton'

interface Props {
  button: TButtonData | null | undefined
  noMinWidth?: boolean
  fullHeight?: boolean
  enabled?: boolean
  onClick?: () => void
}

export const ActionableButton: React.FC<Props & ButtonProps> = React.memo(
  ({ button, endIcon, children, enabled, onClick, noMinWidth, ...rest }) => {
    const { getPrioritizedUrl } = usePrioritizedUrl()

    const url = useMemo(
      () => button && getPrioritizedUrl(button),
      [button, getPrioritizedUrl]
    )

    const handleClick = useCallback(() => {
      if (button?.trackingClickEvent) {
        tracker.trackEvent({
          ...convertCmsTrackingClickEvent(button.trackingClickEvent),
        })
      }
      if (onClick) {
        onClick()
      }
    }, [button?.trackingClickEvent, onClick])

    const onButtonClick =
      !!button?.trackingClickEvent || onClick ? handleClick : undefined

    const buttonContent = useMemo(() => {
      if (button) {
        if (button?.action) {
          switch (button?.action) {
            case TButtonAction.openContactUsForm:
              return (
                <ContactUsButton
                  text={button?.text}
                  onClick={onButtonClick}
                  {...rest}
                />
              )

            case TButtonAction.openInspectionForm:
              return (
                <InspectionButton
                  title={button?.text}
                  noMinWidth
                  onClick={onButtonClick}
                  {...rest}
                />
              )

            default:
              return null
          }
        }

        return (
          url && (
            <Link href={url} passHref prefetch={false} onClick={onButtonClick}>
              <Button component='span' endIcon={endIcon} {...rest}>
                {button?.text}
              </Button>
            </Link>
          )
        )
      }

      return null
    }, [button, endIcon, onButtonClick, rest, url])

    return <>{buttonContent}</>
  }
)

ActionableButton.displayName = 'ActionableButton'
