import axios from 'axios'
import dayjs from 'dayjs'

import { getGAClientId } from '@internal/utils/gaClient/gaClientId'
import { TTracker } from './TTracker'
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export const makeTracker: TTracker = {
  isEnabled: () => true,
  trackPageView() {},
  trackEvent(event) {
    if (event.name === 'call-click') {
      getGAClientId().then((clientId) => {
        axios
          .post('https://hook.eu1.make.com/83oqvs0m6j2biqmmf5rd4b2toxroem60', {
            name: event.target_name,
            action: event.name,
            machineGroupId: event.machine_group_id,
            GA_Client_Id: clientId,
            timestamp: (dayjs as any).utc().format(),
            isMobile:
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              ),
            userAgent: navigator.userAgent,
          })
          .catch((err) => null)
      })
    }
  },
}
