import { Typography } from '@renderer-ui-library/mui'
import React from 'react'
import styles from './searchFilterCategory.module.scss'

interface Props {
  title: string
  children: React.ReactNode
}

export const SearchFilterCategory: React.FC<Props> = React.memo((props) => (
  <div className={styles.wrapper}>
    <Typography variant='h6' className={styles.header}>
      {props.title}
    </Typography>
    <div>{props.children}</div>
  </div>
))
SearchFilterCategory.displayName = 'SearchFilterCategory'
