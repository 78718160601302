import { useMachineCategoryMap } from '@internal/utils/machine/useMachineCategoryMap'
import { Text } from '@renderer-ui-library/atoms'
import { Grid, Typography } from '@renderer-ui-library/mui'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import React from 'react'
import { IMachineCategoryPagesListingData } from './IMachineCategoryPagesListingBlockData'
import { MachineCategoryEntry } from './MachineCategoryEntry'
import styles from './machineCategoryPagesListingBlock.module.scss'

export const MachineCategoryPagesListingBlock: React.FC<
  BlockProps<IMachineCategoryPagesListingData>
> = React.memo(({ data, locale }) => {
  const { machineCategoryMap } = useMachineCategoryMap()

  return (
    <BlockWrapper blockData={data}>
      {(data.topTitle || data.topText) && (
        <div className={styles.textContainer}>
          {data.topTitle && (
            <>
              <Typography
                variant='h1'
                color={data.highlight ? 'white' : 'textPrimary'}
              >
                {data.topTitle}
              </Typography>
            </>
          )}
          {data.topText && (
            <Text
              text={data.topText}
              highlight={data.highlight}
              color={data.highlight ? 'bright' : 'dark'}
            />
          )}
        </div>
      )}

      <Grid container>
        <Grid item xs={12} md={6}>
          {machineCategoryMap
            .slice(0, machineCategoryMap.length - 1)
            .map((category) => (
              <MachineCategoryEntry
                key={category.name}
                category={category}
                locale={locale}
              />
            ))}
        </Grid>
        <Grid item xs={12} md={6}>
          {machineCategoryMap
            .slice(machineCategoryMap.length - 1)
            .map((category) => (
              <MachineCategoryEntry
                key={category.name}
                category={category}
                locale={locale}
              />
            ))}
        </Grid>
      </Grid>
    </BlockWrapper>
  )
})

MachineCategoryPagesListingBlock.displayName =
  'MachineCategoryPagesListingBlock'
