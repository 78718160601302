import { Icon } from '@renderer-ui-library/atoms'
import {
  BlockTopText,
  MediaColumn,
  TopLink,
} from '@renderer-ui-library/molecules'
import { Grid, GridSize } from '@renderer-ui-library/mui'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import classnames from 'classnames'
import React, { useMemo } from 'react'
import { IIconColumnsBlockData } from './IIconColumnsBlockData'
import styles from './iconColumnsBlock.module.scss'

export const IconColumnsBlock: React.FC<BlockProps<IIconColumnsBlockData>> =
  React.memo((props) => {
    const hasCta = React.useMemo(
      () => !props.data.columns.some((column) => column.cta),
      [props.data.columns]
    )

    const topLink = useMemo(
      () =>
        props.data.topButton ? (
          <TopLink
            button={props.data.topButton}
            highlight={props.data.highlight}
          />
        ) : null,
      [props.data.highlight, props.data.topButton]
    )

    return (
      <BlockWrapper blockData={props.data}>
        {(props.data.topText || props.data.topTitle) && (
          <BlockTopText
            highlight={props.data.highlight}
            title={props.data.topTitle}
            text={props.data.topText}
            contentRight={topLink}
          />
        )}
        <div className={styles.gridWrapper}>
          <Grid container columnSpacing={2} rowSpacing={3}>
            {props.data.columns.map((column) => (
              <Grid
                item
                xs={12}
                md={
                  props.data.stretchColumns
                    ? ((12 / props.data.columns.length) as GridSize)
                    : 3
                }
                key={column.id}
              >
                <div className={styles.columnContent}>
                  <MediaColumn
                    id={column.id}
                    title={column.title}
                    content={column.content}
                    cta={column.cta}
                    showInfo
                    noMarginBottom={hasCta}
                    highlight={props.data.highlight}
                  >
                    <div
                      className={classnames(styles.iconWrapper, {
                        [styles.iconHighlight]: props.data.highlight,
                        [styles.mediumIcon]: props.data.iconSize === 'medium',
                        [styles.largeIcon]: props.data.iconSize === 'large',
                        [styles.iconColorDeepGreen]:
                          props.data.iconColor === 'deep-green',
                        [styles.iconColorAuthenticGreen]:
                          props.data.iconColor === 'authentic-green',
                        [styles.iconColorActionPetrol]:
                          props.data.iconColor === 'action-petrol',
                        [styles.iconColorActionOrange]:
                          props.data.iconColor === 'action-orange',
                      })}
                    >
                      <Icon icon={column.icon.icon} />
                    </div>
                  </MediaColumn>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </BlockWrapper>
    )
  })

IconColumnsBlock.displayName = 'IconColumnsBlock'
