import { SvgIconComponent } from '@mui/icons-material'
import { Button, Text } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import classNames from 'classnames'
import React from 'react'
import { FinishedStepIcon } from './FinishedStepIcon'
import styles from './wizardFinishedStep.module.scss'

interface Props {
  title: string
  message: string
  onCloseClick: () => void
  buttonLabel: string
  variant: 'success' | 'error'
  icon?: SvgIconComponent
}

export const WizardFinishedStep: React.FC<Props> = React.memo((props) => {
  const { isMobile } = useMediaQuery()

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.icon, {
          [styles.error]: props.variant === 'error',
        })}
      >
        <FinishedStepIcon icon={props.icon} variant={props.variant} />
      </div>
      <div
        className={classNames(styles.title, {
          [styles.error]: props.variant === 'error',
        })}
      >
        <Typography
          variant='h4'
          color={props.variant === 'error' ? 'text' : 'primary'}
        >
          {props.title}
        </Typography>
      </div>
      <div className={styles.message}>
        <Text text={props.message} />
      </div>
      <div className={styles.button}>
        <Button
          onClick={props.onCloseClick}
          variant='outlined'
          noMinWidth
          fullWidth={isMobile}
        >
          {props.buttonLabel}
        </Button>
      </div>
    </div>
  )
})

WizardFinishedStep.displayName = 'WizardFinishedStep'
