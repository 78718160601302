import { Router, useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { TPageType } from 'utils/page/TPageType'
import { getPageTypeFromPageData } from 'utils/page/getPageTypeFromPageData'
import { getFullUrl } from '../getFullUrl'

type BrowserHistoryStackEntry = {
  url: string
  pageType?: TPageType
}

export type BrowserHistoryStack = BrowserHistoryStackEntry[]

export const latestStackEntryIndex = 0

export const useBrowserHistoryStack = () => {
  const [browserHistoryStack, setStack] = useState<BrowserHistoryStack>([])

  const router = useRouter()

  useEffect(() => {
    const pushEntry = (router: Router) => {
      const component = router.components['/[[...slugs]]']

      const url = getFullUrl(router)
      if (component && component.props?.pageProps) {
        const pageProps = component.props.pageProps
        setStack((s) => [
          { url, pageType: getPageTypeFromPageData(pageProps.data) },
          ...s,
        ])
      } else {
        setStack((s) => [{ url }, ...s])
      }
    }

    const onRouteChangeStart = () => pushEntry(router as Router)

    router.events.on('routeChangeStart', onRouteChangeStart)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [router])

  return {
    browserHistoryStack,
  }
}
