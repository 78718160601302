import { LinkComponent } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { SyntheticEvent, useCallback } from 'react'
import { TMachineCategory } from 'utils/machine/useMachineCategoryMap'
import styles from './machineCategory.module.scss'

type Props = {
  category: TMachineCategory
  noHeadline?: boolean
  sliceStart?: number
  sliceEnd?: number
  onClick: () => void
}

export const MachineCategory: React.FC<Props> = ({
  category,
  noHeadline,
  sliceStart = 0,
  sliceEnd,
  onClick,
}) => {
  const blurOnClick = useCallback(
    (
      event:
        | SyntheticEvent<HTMLAnchorElement>
        | SyntheticEvent<HTMLSpanElement>,
      machineType: string
    ) => {
      event.currentTarget.blur()
      onClick()
    },
    [onClick]
  )

  return (
    <div className={styles.category} key={category.name}>
      {!noHeadline && (
        <div className={styles.headline}>
          <Typography variant='h6'>{category.name}</Typography>
        </div>
      )}
      <ul className={styles.list}>
        {category.machines
          .slice(sliceStart, sliceEnd ? sliceEnd : category.machines.length)
          .map((machine) => (
            <li className={styles.listItem} key={machine.machineType}>
              <LinkComponent
                onClick={(e) => blurOnClick(e, machine.machineType)}
                textColorVariant='primary'
                href={machine.url}
                prefetch={false}
              >
                {machine.name}
              </LinkComponent>
            </li>
          ))}
      </ul>
    </div>
  )
}
