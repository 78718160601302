import { Machine } from './Machine'

import { FC } from 'react'
import { useMachineSharingPreviewImage } from './useMachineSharingPreviewImage'

type Props = {
  machine: Machine | null | undefined
  alt: string
}
// some messengers like Viber don't consume the open graph tags, instead they take the first picture in the html
export const PreviewImage: FC<Props> = ({ machine, alt }) => {
  const previewImage = useMachineSharingPreviewImage(machine)

  if (!previewImage) {
    return null
  }

  return (
    <img
      src={previewImage.url}
      width={previewImage.width}
      height={previewImage.height || undefined}
      alt={alt}
      style={{ display: 'none' }}
    />
  )
}
