import React, { FC } from 'react'
import { useMachineSharingPreviewImage } from 'utils/machine/useMachineSharingPreviewImage'
import { Machine } from '../utils/machine/Machine'

interface Props {
  machine?: Machine | null
  title: string
  description: string
}

export const OpenGraphTags: FC<Props> = React.memo(
  ({ machine, title, description }) => {
    const machineImage = useMachineSharingPreviewImage(machine)

    return (
      <>
        {machineImage && (
          <>
            <meta property='og:image' content={machineImage.url} />
            <meta property='og:image:secure_url' content={machineImage.url} />
            <meta
              property='og:image:width'
              content={machineImage.width.toString()}
            />
            {machineImage.height && (
              <meta
                property='og:image:height'
                content={machineImage.height.toString()}
              />
            )}
            <meta property='og:image:alt' content={title} />
          </>
        )}
        <>
          <meta property='og:type' content='website' />
          <meta property='og:title' content={title} />
          <meta property='og:description' content={description} />
        </>
      </>
    )
  }
)

OpenGraphTags.displayName = 'OpenGraphTags'
