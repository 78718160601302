import {
  getSerpUrlForAlternatives,
  MachineMeta,
} from '@internal/services/apiClient'
import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { ImageLoader } from '@internal/utils/media/ImageLoader'
import { PageTypeKey } from '@internal/utils/page/PageTypeKey'
import { DefaultQueryParams } from '@internal/utils/routing/urls/DefaultQueryParams'
import { urlFor } from '@internal/utils/routing/urls/urlFor'
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import {
  localeNamespace,
  TLocale,
} from '@website-shared-library/machine/i18n/Locale'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import PlaceholderImage from '../../assets/custom-404.png'
import styles from './notFoundMachineLoader.module.scss'
interface Props {
  machine: MachineMeta
  locale: TLocale
}

export const NotFoundMachineLoader: React.FC<Props> = React.memo(
  ({ machine, locale }) => {
    const { t } = useTranslation(localeNamespace.common)
    const { isMobile } = useMediaQuery()
    const { replace, query } = useRouter()
    const { noCache, refresh, ip } = query as DefaultQueryParams

    const { getMachineTypeName } = useMachineTypeName()

    useEffect(() => {
      void Promise.all([
        getSerpUrlForAlternatives({
          locale,
          machine,
          noCache: !!noCache,
          refreshCache: !!refresh,
          ip,
        }),
        new Promise((resolve) => setTimeout(resolve, 2000)),
      ])
        .then(([result]) => replace(result.url))
        .catch(() =>
          replace(
            urlFor(locale, {
              key: PageTypeKey.MachineCategoryPage,
              machineTypeName: getMachineTypeName(
                MachineType.TRACTOR,
                'plural'
              ),
            })
          )
        )
    }, [getMachineTypeName, ip, locale, machine, noCache, refresh, replace])

    return (
      <Container maxWidth='lg'>
        <Typography variant='h1'>{machine.name}</Typography>

        <div className={styles.notAvailable}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Image
                loading='lazy'
                {...PlaceholderImage}
                alt=''
                loader={ImageLoader}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant='h2'>
                {t(translations.notFoundmachineNoLongerAvailable)}
              </Typography>
              <Typography variant='body1'>
                {t(translations.notFoundmachineMightGotSoldOrRemoved)}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={styles.wrapper}>
              <Typography variant='h3' component='p'>
                {t(translations.findSimilarMachines)}
              </Typography>
              <div className={styles.progress}>
                <CircularProgress size={isMobile ? 32 : 50} />
              </div>
              <Typography variant='subtitle1' component='span'>
                {t(translations.willBeRedirectedShortly)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    )
  }
)

NotFoundMachineLoader.displayName = 'NotFoundMachineLoader'
