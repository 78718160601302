import { FC, memo, PropsWithChildren } from 'react'
import { Rollouts } from './getRollouts'
import { RolloutsContext } from './RolloutsContext'

export const RolloutsProvider: FC<
  PropsWithChildren<{
    rollouts: Rollouts
  }>
> = memo(({ children, rollouts }) => {
  return (
    <RolloutsContext.Provider value={rollouts}>
      {children}
    </RolloutsContext.Provider>
  )
})

RolloutsProvider.displayName = 'RolloutsProvider'
