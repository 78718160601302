import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { SubTypes } from '@website-shared-library/machine/MachineType'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { machineSubTypeTranslationKeyMapping } from 'utils/machineSubTypeTranslationKeyMapping'

export const useMachineSubTypeName = () => {
  const { t } = useTranslation(localeNamespace.common)

  const getMachineSubTypeName = useCallback(
    (machineSubType: SubTypes[keyof SubTypes], count: number = 0) => {
      return t(machineSubTypeTranslationKeyMapping[machineSubType], {
        count,
      }) as SubTypes[keyof SubTypes]
    },
    [t]
  )

  return { getMachineSubTypeName }
}
