import { translations } from '@internal/i18n/translations'
import { Machine } from '@internal/utils/machine/Machine'
import { useFinancingData } from '@internal/utils/machine/useFinancingData'
import { getResponsiveSmallMediaSrc } from '@internal/utils/media/getMediaSrc'
import { formatPrice } from '@internal/utils/numbers/formatPrice'
import { useMachineUrl } from '@internal/utils/routing/hooks/useMachineUrl'
import { RatioContainer } from '@renderer-ui-library/atoms'
import { ShareButton, TMedia } from '@renderer-ui-library/molecules'
import {
  CardActionArea,
  CardActions,
  CardContent,
  Card as MuiCard,
  Typography,
} from '@renderer-ui-library/mui'
import { ImageRatio } from '@renderer-ui-library/mui/utils/media/ImageRatio'
import {
  TLocale,
  localeNamespace,
} from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'
import styles from './serpCard.module.scss'

export type SerpCardProps = {
  media: { data: TMedia }
  title: string
  children: React.ReactNode
  isLink?: boolean
  grow?: boolean
  overlay?: boolean
  overlayImage?: string
  machine: Machine
  price: number | null
  locale: TLocale
}

const Content: React.FC<SerpCardProps> = (props) => {
  const { getMachineUrl } = useMachineUrl()
  const url = getMachineUrl(props.locale, props.machine)

  return (
    <>
      <Link href={url} passHref prefetch={false} style={{ width: '100%' }}>
        <RatioContainer ratio={ImageRatio.Landscape}>
          {props.overlay && props.overlayImage && (
            <>
              <img
                src={props.overlayImage}
                alt='Sold Stamp'
                className={styles.overlay}
              />
              <img
                loading='lazy'
                className={classNames(styles.media, styles.grayscale)}
                alt={props.media.data.attributes.alternativeText}
                {...getResponsiveSmallMediaSrc(props.media, {
                  ratio: ImageRatio.Landscape,
                  desktopViewportPrecentage: 4,
                })}
              />
            </>
          )}
          {!props.overlay && (
            <img
              loading='lazy'
              className={styles.media}
              alt={props.media.data.attributes.alternativeText}
              {...getResponsiveSmallMediaSrc(props.media, {
                ratio: ImageRatio.Landscape,
              })}
            />
          )}
        </RatioContainer>

        <CardContent className={styles.content}>
          <>
            <Typography
              variant='h5'
              title={props.title}
              className={styles.title}
            >
              {props.title}
            </Typography>
            {props.children}
          </>
        </CardContent>
      </Link>
    </>
  )
}

export const SerpCard: React.FC<SerpCardProps> = React.memo((props) => {
  const { t } = useTranslation(localeNamespace.common)
  const { getFinancingData } = useFinancingData()
  const financingData = props.machine && getFinancingData(props.machine)
  return (
    <div className={classNames({ [styles.grow]: props.grow })}>
      <MuiCard variant='outlined'>
        {props.isLink ? (
          <>
            <CardActionArea sx={{ justifyContent: 'flex-start' }}>
              <Content {...props} />
            </CardActionArea>
            {false && financingData && (
              <Typography variant='h6' color='text.secondary'>
                {formatPrice(props.locale, financingData!.monthlyRate)} /
                {t(translations.leasingPriceMonthly)}
              </Typography>
            )}

            {financingData && (
              <div className={styles.leasingTag}>
                <Typography variant='body2'>
                  {t(translations.leasingAvailableTag).toUpperCase()}
                </Typography>
              </div>
            )}
            <CardActions className={styles.shareWrapper}>
              <ShareButton
                machine={props.machine}
                positionList='top'
                locale={props.locale}
                variant='small'
              />
              {props.price ? (
                <div className={styles.priceContainer}>
                  <Typography
                    variant='h5'
                    color='text.primaryLight'
                    className={styles.shareWrapperPriceHeadline}
                  >
                    {props.price && formatPrice(props.locale, props.price)}
                  </Typography>
                  <span className={styles.vatInfo}>
                    {t(translations.priceTaxInfo)}
                  </span>
                </div>
              ) : (
                <Typography
                  variant='h6'
                  color='text.primaryLight'
                  className={styles.shareWrapperPriceOnRequestHeadline}
                >
                  {t(translations.priceOnRequest)}
                </Typography>
              )}
            </CardActions>
          </>
        ) : (
          <Content {...props} />
        )}
      </MuiCard>
    </div>
  )
})

SerpCard.displayName = 'SerpCard'
