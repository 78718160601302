import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { TPerson } from 'services/TSalesManagerPayload'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import styles from './salesManagerContact.module.scss'

interface Props {
  salesManager: TPerson
}

export const SalesManagerContact: React.FC<Props> = React.memo(
  ({ salesManager }) => {
    const { t } = useTranslation(localeNamespace.common)

    return (
      <div className={styles.salesConatiner}>
        <img
          loading='lazy'
          className={styles.image}
          key={salesManager.image.data.id}
          alt={salesManager.image.data.attributes.alternativeText}
          {...getMediaSrc(salesManager.image, {
            ratio: 1 / 1,
            desktopViewportPrecentage: 3,
          })}
          sizes='(max-width: 1200px) 400px'
        />
        <Typography variant='body2'>
          {salesManager.firstName} {salesManager.lastName}
        </Typography>

        <Typography variant='caption' color='textSecondary'>
          {t(translations.MachineDetailsContactCountryManagerText)}
        </Typography>
      </div>
    )
  }
)

SalesManagerContact.displayName = 'SalesManagerContact'
