import { TButtonData, TMediaData } from '@renderer-ui-library/molecules'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import { BlockType } from 'blocks/BlockType'
import { IBaseBlockData } from 'blocks/IBaseBlockData'

export enum ServiceTypes {
  Inspection = 'Inspection',
  Warranty = 'Warranty',
  Transport = 'Transport',
  Payment = 'Payment',
  Financing = 'Financing',
}

export interface Service {
  serviceType: ServiceTypes
  headline: string | null
  description: string
  media: TMediaData | null
  button: TButtonData | null
  videoUrl?: string
}

export interface IServiceValuePropBlockData extends IBaseBlockData {
  services: Service[]
  type: BlockType.ServiceBlock
  locale: TLocale
}

export interface IServiceBlockData extends IBaseBlockData {
  headline: string
  services: Service[]
  type: BlockType.ServiceBlock
}
