import { Machine } from '@internal/utils/machine/Machine'
import { CollapseArea, Table } from '@renderer-ui-library/molecules'
import { TDisplayDefinition } from '@website-shared-library/displayDefinition/TDisplayDefinition'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import classNames from 'classnames'
import { FC, ReactNode, memo, useMemo } from 'react'
import { mapMachineAttributesToTableRow } from '../../../utils/table/mapMachineAttributesToTableRow'
import styles from './detailsTable.module.scss'

type Props = {
  displayDefinition: TDisplayDefinition
  machine: Machine
  locale: TLocale
  title?: string
  titleComponent?: ReactNode
  showCategoriesTitle: boolean
}

export const DetailsTable: FC<Props> = memo(
  ({
    displayDefinition,
    machine,
    locale,
    title,
    titleComponent,
    showCategoriesTitle,
  }) => {
    const categories = useMemo(
      () =>
        displayDefinition.categories.map((category) => ({
          name: category.name,
          rows: mapMachineAttributesToTableRow(
            machine,
            category.entries,
            locale
          ),
        })),
      [displayDefinition.categories, locale, machine]
    )

    return (
      <div>
        <CollapseArea title={title} titleComponent={titleComponent}>
          <div className={classNames(styles.tableWrapper)}>
            {categories.map((category, index) => (
              <div key={`${category.name}-${index}`} className={styles.table}>
                <Table
                  key={`${category.name}-${index}`}
                  title={showCategoriesTitle ? category.name : undefined}
                  rows={category.rows}
                />
              </div>
            ))}
          </div>
        </CollapseArea>
      </div>
    )
  }
)

DetailsTable.displayName = 'DetailsTable'
