export const pathContainsClassName = (
  className: string,
  element: HTMLElement
) => {
  let el: HTMLElement | null = element

  while (el) {
    if (`${el.className}`.includes(className)) {
      return true
    }
    el = el.parentElement
  }

  return false
}
