import { Locale } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'

export const longLocaleName: Record<TLocale, string> = {
  [Locale.de]: 'deutsch',
  [Locale.en]: 'english',
  [Locale.fr]: 'français',
  [Locale.el]: 'ελληνικά',
  [Locale.it]: 'italiano',
  [Locale.pl]: 'polski',
  [Locale.ro]: 'limba română',
  [Locale.ru]: 'Русский язык',
  [Locale.hu]: 'magyar',
  [Locale.es]: 'español',
  [Locale.bg]: 'Български',
  [Locale.sl]: 'slovenskega',
  [Locale.sr]: 'Srpski',
  [Locale.da]: 'Dansk',
  [Locale.cs]: 'Czech',
  [Locale.lt]: 'Lietuvių',
}
