import dynamic from 'next/dynamic'
import { MobileFloatingButtonOverlayProps } from './MobileFloatingButtonOverlay'

export const DynamicMobileFloatingButtonOverlay =
  dynamic<MobileFloatingButtonOverlayProps>(
    () =>
      import('./MobileFloatingButtonOverlay').then(
        (md) => md.MobileFloatingButtonOverlay
      ),
    { ssr: false }
  )
