import { Chip } from '@renderer-ui-library/atoms'
import React, { useCallback } from 'react'
import { TFilterOption } from './TFilterOption'
import styles from './selectedOption.module.scss'

type Props = {
  option: TFilterOption
  onClick: (option: TFilterOption) => void
}
export const SelectedOption: React.FC<Props> = ({ option, onClick }) => {
  const handleClick = useCallback(() => onClick(option), [option, onClick])

  return (
    <Chip
      className={styles.chip}
      label={option.label}
      onDelete={handleClick}
      onClick={handleClick}
    />
  )
}
