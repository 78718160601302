import { MachineMedia } from '@internal/utils/machine/Machine'
import { ignoreColumnCtaClickClass } from '@renderer-ui-library/mui/base/constants'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import classNames from 'classnames'
import React from 'react'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import styles from './compareMachinesImageSlider.module.scss'

export type ComparMachinesImageSliderProps = {
  images: MachineMedia[]
  ratio: number | 'original' // width / height
  autoPlay?: boolean
  pagination: boolean
  arrows: boolean
}

export const CompareMachinesImageSlider: React.FC<
  ComparMachinesImageSliderProps
> = ({ images, ratio, autoPlay, pagination, arrows }) => {
  return (
    <Splide
      options={{
        width: '100%',
        heightRatio: typeof ratio === 'number' ? 1 / ratio : undefined,
        rewind: true,
        autoplay: autoPlay,
        pagination: pagination,
        arrows: arrows,
        interval: 3500,
        speed: autoPlay ? 1000 : 400,
        easing: 'ease',
        lazyLoad: 'nearby',
        classes: {
          arrows: `splide__arrows ${ignoreColumnCtaClickClass}`,
          pagination: `splide__pagination ${ignoreColumnCtaClickClass}`,
        },
      }}
      className={classNames(styles.slider, {
        [styles.cover]: !ratio,
      })}
    >
      {images.map((image, index) => {
        return (
          <SplideSlide key={`${index}-${image.url}`}>
            <img
              className={styles.image}
              alt=''
              data-splide-lazy={`${
                getMediaSrc(
                  { data: { id: index, attributes: image } },
                  { ratio }
                ).src
              }`}
            />
          </SplideSlide>
        )
      })}
    </Splide>
  )
}
