import { TNavigationEntry } from '@internal/utils/navigation/TNavigationEntry'
import Link from 'next/link'
import { MouseEvent, useCallback } from 'react'
import { getNavigationItemLabel } from '../../getNavigationItemLabel'
import { MenuEntry } from '../MenuEntry/MenuEntry'

type Props = {
  entry: TNavigationEntry
  onClick: (entry: TNavigationEntry) => void
}

export const MenuLink: React.FC<Props> = ({ entry, onClick }) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      const openLink = event.ctrlKey || event.metaKey
      if (openLink) {
        return
      }
      if (entry.children.length > 0) {
        event.preventDefault()
      }
      onClick(entry)
    },
    [entry, onClick]
  )

  const hasSubEntries = entry.children.length > 0
  return (
    <Link
      href={entry.route}
      passHref
      prefetch={false}
      onClick={handleClick}
      target={entry.external ? '_blank' : undefined}
      tabIndex={0}
    >
      <MenuEntry
        title={getNavigationItemLabel(entry)}
        hasSubEntries={hasSubEntries}
      />
    </Link>
  )
}

MenuLink.displayName = 'MenuLink'
