import {
  BlockTopText,
  Media,
  MediaColumn,
  TopLink,
} from '@renderer-ui-library/molecules'
import { Grid } from '@renderer-ui-library/mui'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { IMediaSplitColumnsBlockData } from './IMediaSplitColumnsBlockData'
import styles from './mediaSplitColumnsBlock.module.scss'

export const MediaSplitColumnsBlock: React.FC<
  BlockProps<IMediaSplitColumnsBlockData>
> = React.memo((props) => {
  const containsOnlyImages = useMemo(
    () => !props.data.columns.find((col) => !!col.media?.videoUrl),
    [props]
  )

  const topLink = useMemo(
    () =>
      props.data.topButton ? (
        <TopLink
          button={props.data.topButton}
          highlight={props.data.highlight}
        />
      ) : null,
    [props.data.highlight, props.data.topButton]
  )

  return (
    <BlockWrapper blockData={props.data}>
      {(props.data.topText || props.data.topTitle) && (
        <BlockTopText
          title={props.data.topTitle}
          text={props.data.topText}
          contentRight={topLink}
          highlight={props.data.highlight}
        />
      )}
      <div
        className={classNames(styles.gridWrapper, {
          [styles.stretched]: props.data.stretchColumns,
        })}
      >
        <Grid container columnSpacing={2} rowSpacing={6}>
          {props.data.columns.map((column) => (
            <Grid item xs={12} md={6} key={column.id}>
              <div className={styles.columnContent}>
                <MediaColumn
                  id={column.id}
                  title={column.title}
                  content={column.content}
                  cta={column.cta}
                  showInfo
                  orientation='row'
                  highlight={props.data.highlight}
                >
                  {column.media && (
                    <div className={styles.image}>
                      <Media
                        media={column.media}
                        ratio={containsOnlyImages ? 3 / 2 : 16 / 9}
                      />
                    </div>
                  )}
                </MediaColumn>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </BlockWrapper>
  )
})

MediaSplitColumnsBlock.displayName = 'MediaSplitColumnsBlock'
