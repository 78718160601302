import classNames from 'classnames'
import React from 'react'
import styles from './boxContainer.module.scss'

export interface BoxContainerProps {
  children: React.ReactNode
  className?: string
}

export const BoxContainer: React.FC<BoxContainerProps> = React.memo((props) => (
  <div className={classNames(styles.boxContainer, props.className)}>
    {props.children}
  </div>
))

BoxContainer.displayName = 'BoxContainer'
