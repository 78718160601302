import { SvgIconComponent } from '@mui/icons-material'
import { Typography } from '@renderer-ui-library/mui'
import { memo } from 'react'
import styles from './iconHeadline.module.scss'

type Props = {
  title: string
  icon?: SvgIconComponent
}

export const IconHeadline: React.FC<Props> = memo((props) => {
  const IconComponent = props.icon

  return (
    <div className={styles.wrapper}>
      {IconComponent && (
        <div className={styles.icon}>
          <IconComponent color='action' fontSize='inherit' />
        </div>
      )}
      <Typography variant='h4'>{props.title}</Typography>
    </div>
  )
})

IconHeadline.displayName = 'IconHeadline'
