import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { getFullUrl } from 'utils/routing/getFullUrl'
import { BrowserHistoryStack } from 'utils/routing/hooks/useBrowserHistoryStack'

const prefix = 'scroll-position:'

const oneHourInMs = 60 * 60 * 1000

const isExpired = (timestamp: number) => Date.now() - timestamp > oneHourInMs

interface PersistedData {
  timestamp: number
  position: {
    left: number
    top: number
  }
}

const saveScrollPos = (url: string) => {
  try {
    sessionStorage.setItem(
      prefix + url,
      JSON.stringify({
        timestamp: Date.now(),
        position: {
          left: 0,
          top: window.scrollY,
        },
      })
    )
  } catch (err) {
    // ignore
  }
}

const restoreScrollPos = (url: string) => {
  try {
    const key = prefix + url
    const item = sessionStorage.getItem(key)
    if (item) {
      const data = JSON.parse(item)
      if (
        data.timestamp &&
        data.position &&
        !isExpired((data as PersistedData).timestamp)
      ) {
        window.scrollTo((data as PersistedData).position)
      } else {
        sessionStorage.removeItem(key)
      }
    }
  } catch (err) {
    // ignore
  }
}

// inspired by: https://github.com/CTNicholas/next-restore-scroll/blob/master/src/index.js
export const useScrollPositionRestoration = (
  browserHistoryStack: BrowserHistoryStack
) => {
  const router = useRouter()
  const browserHistoryStackRef =
    useRef<BrowserHistoryStack>(browserHistoryStack)
  browserHistoryStackRef.current = browserHistoryStack

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      // https://developer.mozilla.org/en-US/docs/Web/API/History/scrollRestoration
      window.history.scrollRestoration = 'manual'

      // we only want to restore the scroll position when
      // a user navigates a -> b -> a, then we restore the scroll position on page a
      if (getFullUrl(router) === browserHistoryStackRef.current[1]?.url) {
        restoreScrollPos(getFullUrl(router))
      }

      const onBeforeUnload = () => {
        saveScrollPos(getFullUrl(router))
      }
      const onRouteChangeStart = () => saveScrollPos(getFullUrl(router))

      window.addEventListener('beforeunload', onBeforeUnload)
      router.events.on('routeChangeStart', onRouteChangeStart)

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload)
        router.events.off('routeChangeStart', onRouteChangeStart)
      }
    }
  }, [router, browserHistoryStackRef])
}
