import React from 'react'
import { BrowserHistoryStackContext } from './BrowserHistoryStackContext'
import { BrowserHistoryStack } from './hooks/useBrowserHistoryStack'

interface BrowserHistoryStackProviderProps {
  children?: React.ReactNode
  browserHistoryStack: BrowserHistoryStack
}

export const BrowserHistoryStackProvider: React.FC<BrowserHistoryStackProviderProps> =
  React.memo(({ children, browserHistoryStack }) => {
    return (
      <BrowserHistoryStackContext.Provider value={{ browserHistoryStack }}>
        {children}
      </BrowserHistoryStackContext.Provider>
    )
  })

BrowserHistoryStackProvider.displayName = 'BrowserHistoryStackProvider'
