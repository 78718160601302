import { Button } from '@mui/material'
import CaseIHLogo from '@renderer-ui-library/assets/companyLogos/caseIH.svg'
import ClaasLogo from '@renderer-ui-library/assets/companyLogos/claas.svg'
import DeutzFahrLogo from '@renderer-ui-library/assets/companyLogos/deutzFahr.svg'
import FendtLogo from '@renderer-ui-library/assets/companyLogos/fendt.svg'
import JohnDeereLogo from '@renderer-ui-library/assets/companyLogos/johnDeere.svg'
import KubotaLogo from '@renderer-ui-library/assets/companyLogos/kubota.svg'
import MasseyFergusonLogo from '@renderer-ui-library/assets/companyLogos/masseyFerguson.svg'
import NewHollandLogo from '@renderer-ui-library/assets/companyLogos/newHolland.svg'
import { KnownBrands } from '@website-shared-library/machine/KnownBrands'
import classnames from 'classnames'
import Image, { StaticImageData } from 'next/image'
import { ImageLoader } from 'utils/media/ImageLoader'
import styles from './brandButton.module.scss'

export type SupportedBrand =
  | KnownBrands.New_Holland
  | KnownBrands.Massey_Ferguson
  | KnownBrands.Case_IH
  | KnownBrands.Claas
  | KnownBrands.Deutz_Fahr
  | KnownBrands.Fendt
  | KnownBrands.John_Deere
  | KnownBrands.Kubota

type Props = {
  brand: SupportedBrand
  route: string
  variant: 'text' | 'outlined' | 'contained'
  onClick?: () => void
  classname?: string
}

const images: Record<SupportedBrand, StaticImageData> = {
  [KnownBrands.New_Holland]: NewHollandLogo,
  [KnownBrands.Massey_Ferguson]: MasseyFergusonLogo,
  [KnownBrands.Case_IH]: CaseIHLogo,
  [KnownBrands.Claas]: ClaasLogo,
  [KnownBrands.Deutz_Fahr]: DeutzFahrLogo,
  [KnownBrands.Fendt]: FendtLogo,
  [KnownBrands.John_Deere]: JohnDeereLogo,
  [KnownBrands.Kubota]: KubotaLogo,
}

export const BrandButton: React.FC<Props> = ({
  brand,
  onClick,
  route,
  classname,
  variant,
}) => {
  if (!images[brand]) {
    return <></>
  }

  return (
    <Button
      variant={variant}
      color='inherit'
      className={classnames(classname, styles.button, {
        [styles.outlined]: variant === 'outlined',
      })}
      title={brand}
      href={route}
      onClick={onClick}
    >
      <Image src={images[brand]} alt={brand} loader={ImageLoader} fill />
    </Button>
  )
}
