import classNames from 'classnames'
import { useCallback, useState } from 'react'
import styles from './globalScrolling.module.scss'

export const useBlockGlobalScrolling = () => {
  const [isBlocked, setBlocked] = useState(false)

  const disableGlobalScrolling = useCallback(() => {
    if (!isBlocked) {
      setBlocked(true)
      document.body.className = classNames(
        document.body.className,
        styles.block
      )
    }
  }, [isBlocked])

  const enableGlobalScrolling = useCallback(() => {
    if (isBlocked) {
      setBlocked(false)
      document.body.className = document.body.className
        .replace(styles.block, '')
        .trim()
    }
  }, [isBlocked])

  return {
    enableGlobalScrolling,
    disableGlobalScrolling,
  }
}
