import { Check } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import classNames from 'classnames'
import { memo, useCallback } from 'react'
import styles from './radioButtonGroup.module.scss'

export interface RadioOption {
  value: string
  label: string
}

export const Option: React.FC<{
  option: RadioOption
  onClick: (value: string) => void
  isSelected: boolean
  mobile?: boolean
  fullWidth?: boolean
}> = memo(({ option, onClick, isSelected, mobile, fullWidth }) => {
  const handleClick = useCallback(
    () => onClick(option.value),
    [option, onClick]
  )
  return (
    <Button
      key={option.value}
      className={classNames(styles.button, {
        [styles.selected]: isSelected,
      })}
      onClick={handleClick}
      variant={mobile ? 'text' : undefined}
      fullWidth={fullWidth}
    >
      {option.label}
      {/*  always render the icon on mobile devices and show/hide it via css. This avoids height jumps */}
      {mobile && (
        <Check
          className={classNames({ [styles.checkIcon]: isSelected })}
          color='primary'
        />
      )}
    </Button>
  )
})

Option.displayName = 'Option'
