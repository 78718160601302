import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { countriesCodes, CountryCodeType } from './phoneCountryCodes'

const localeToCountryCodeMap: Record<TLocale, string> = {
  en: 'EN',
  de: 'DE',
  fr: 'FR',
  el: 'GR',
  it: 'IT',
  pl: 'PL',
  ro: 'RO',
  ru: 'RU',
  hu: 'HU',
  es: 'ES',
  bg: 'BG',
  sl: 'SI',
  sr: 'RS',
  da: 'DK',
  cs: 'CZ',
  lt: 'LT',
}

export const getLanguageForLocale = (locale: TLocale) =>
  localeToCountryCodeMap[locale]

export const getCountryCodeByLocale = (locale: TLocale): CountryCodeType => {
  const countryCode = countriesCodes.find(
    ({ code }) => code === localeToCountryCodeMap[locale]
  )

  if (!countryCode) {
    return {
      code: 'GB',
      phone: '44',
    }
  }

  return countryCode
}
