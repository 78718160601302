export enum EIcon {
  Garage = 'Garage',
  Language = 'Language',
  Lock = 'Lock',
  QuestionAnswer = 'QuestionAnswer',
  Recommend = 'Recommend',
  PermMedia = 'PermMedia',
  CheckCircleOutline = 'CheckCircleOutline',
  ChatBubbleOutline = 'ChatBubbleOutline',
  Work = 'Work',
  LocationOn = 'LocationOn',
  FavoriteBorder = 'FavoriteBorder',
  Person = 'Person',
  NotificationsActiveOutlined = 'NotificationsActiveOutlined',
  Money = 'Money',
  Discount = 'Discount',
  CreditScore = 'CreditScore',
  Agriculture = 'Agriculture',
  PublishedWithChanges = 'PublishedWithChanges',
}
