import { ArrowForward } from '@mui/icons-material'
import { TButtonData } from '@renderer-ui-library/molecules'
import React from 'react'
import { ActionableButton } from '../ActionableButton/ActionableButton'

interface TopLinkProps {
  highlight?: boolean
  button: TButtonData | null
}

export const TopLink: React.FC<TopLinkProps> = React.memo(
  ({ highlight, button }) => (
    <ActionableButton
      button={button}
      variant='text'
      size='small'
      noMinWidth
      color={highlight ? 'secondary' : 'primary'}
      endIcon={<ArrowForward />}
    />
  )
)

TopLink.displayName = 'TopLink'
