import { getLocalImage } from 'utils/media/getLocalImage'

export const BrandImageMapper = (brand: string) => {
  const formatBrand =
    brand.charAt(0).toLowerCase() + brand.slice(1).replace(' ', '')

  return getLocalImage(
    require(`../../renderer-ui-library/assets/companyLogos/${formatBrand}.svg`),
    'image/svg+xml'
  )
}
