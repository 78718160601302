import { Cancel, CheckCircle } from '@mui/icons-material'
import { TTableRowValue } from './TTableRowValue'
import styles from './tableValueContent.module.scss'

export const TableValueContent: React.FC<{
  value: TTableRowValue
  marker?: boolean
}> = ({ value, marker }) => {
  const markerComponent = marker ? <sup className={styles.marker}>*</sup> : ''

  if (typeof value === 'boolean') {
    return value ? (
      <span className={styles.featureAvailable}>
        <CheckCircle fontSize='small' color='inherit' />
        {markerComponent}
      </span>
    ) : (
      <>
        <Cancel fontSize='small' className={styles.featureMissing} />
        {markerComponent}
      </>
    )
  }

  return (
    <>
      {value}
      {markerComponent}
    </>
  )
}
