import Logo from '@renderer-ui-library/assets/logo.svg'
import { Text } from '@renderer-ui-library/atoms'
import {
  DynamicTrustPilot,
  SocialButtons,
} from '@renderer-ui-library/molecules'
import { Container, Grid, Typography } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { Locales } from '@renderer-ui-library/scaffolding/Locales/Locales'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { translations } from 'i18n/translations'
import Image from 'next/image'
import NextLink from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TNavigationsMap } from 'services/TNavigationsMap'
import { ImageLoader } from 'utils/media/ImageLoader'
import { MenuColumn } from './MenuColumn'
import styles from './footer.module.scss'

interface Props {
  navigationsMap: TNavigationsMap
  locale: TLocale
  background: 'light' | 'dark'
}

export const Footer: React.FC<Props> = React.memo((props) => {
  const { t } = useTranslation(localeNamespace.common)
  const { isMobile } = useMediaQuery()

  return (
    <div className={styles.footer}>
      <Container component='footer' maxWidth='lg' disableGutters>
        <Grid container spacing='2' rowSpacing='16px'>
          <Grid item xs={12} lg={3}>
            <NextLink href='/' passHref prefetch={false} legacyBehavior>
              <div className={styles.logoWrapper}>
                <Image src={Logo} alt='E-FARM GmbH' loader={ImageLoader} fill />
              </div>
            </NextLink>
            <div className={styles.locales}>
              <Locales locale={props.locale} longNames />
            </div>
            {!isMobile && (
              <>
                <Typography variant='h6' color={Colors.textInvertedPrimary}>
                  {t(translations.footerSocialHeadline)}
                </Typography>
                <SocialButtons />
              </>
            )}
          </Grid>
          <Grid item xs={6} lg={3}>
            <MenuColumn
              isMobile={isMobile}
              name={props.navigationsMap.footer0.name}
              entries={props.navigationsMap.footer0.entries}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <MenuColumn
              isMobile={isMobile}
              name={props.navigationsMap.footer1.name}
              entries={props.navigationsMap.footer1.entries}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            justifyContent='space-between'
            display='flex'
            flexDirection='column'
          >
            <div className={styles.localesMobile}>
              <Locales locale={props.locale} longNames />
            </div>
            <div className={styles.widgetContainer}>
              {isMobile && (
                <>
                  <Typography variant='h6' color={Colors.textInvertedPrimary}>
                    {t(translations.footerSocialHeadline)}
                  </Typography>
                  <SocialButtons />
                </>
              )}

              <div className={styles.trustPilot}>
                <DynamicTrustPilot
                  locale={props.locale}
                  variant='small'
                  theme='dark'
                />
              </div>
            </div>
            <div className={styles.copyright}>
              <Text
                text={t(translations.footerCopyright, {
                  year: new Date().getFullYear(),
                })}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
})

Footer.displayName = 'Footer'
