import { Machine } from '@internal/utils/machine/Machine'
import { SplideSlide } from '@splidejs/react-splide'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import React from 'react'
import { ProductCard } from '../ProductCard/ProductCard'
import styles from './machineryCard.module.scss'

interface Props {
  machine: Machine
  locale: TLocale
  url: string
}

export const MachineryCard: React.FC<Props> = React.memo(
  ({ machine, locale, url }) => (
    <SplideSlide className={styles.card}>
      <ProductCard
        title={machine.name}
        media={machine.media[0]}
        price={machine.price}
        highlightData={{
          power: machine.enginePower,
          year: machine.yearOfProduction,
          hours: machine.engineHours,
        }}
        url={url}
        machineType={machine.machineType}
        locale={locale}
      ></ProductCard>
    </SplideSlide>
  )
)

MachineryCard.displayName = 'MachineryCard'
