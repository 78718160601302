import {
  CreditScoreOutlined,
  LocalPoliceOutlined,
  LocalShippingOutlined,
  SearchOutlined,
  VerifiedOutlined,
} from '@mui/icons-material'
import { SvgIcon } from '@renderer-ui-library/mui'
import { ServiceTypes } from 'blocks/ServiceBlock/IServiceBlockData'
import { translations } from 'i18n/translations'

export const ServiceEntries: Record<
  ServiceTypes,
  { icon: typeof SvgIcon; title: string }
> = {
  [ServiceTypes.Inspection]: {
    title: translations.headerMainNavServiceItemInspectionTitle,
    icon: SearchOutlined,
  },
  [ServiceTypes.Payment]: {
    title: translations.promoteServiceFinanceTitle,
    icon: CreditScoreOutlined,
  },
  [ServiceTypes.Warranty]: {
    title: translations.promoteServiceWarrantyTitle,
    icon: VerifiedOutlined,
  },
  [ServiceTypes.Transport]: {
    title: translations.promoteServiceTransportTitle,
    icon: LocalShippingOutlined,
  },
  [ServiceTypes.Financing]: {
    title: '',
    icon: LocalPoliceOutlined,
  },
}
