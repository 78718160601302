import { environment } from 'environment/environment'
import { TTracker } from '../TTracker'

export const consoleTracker: TTracker = {
  isEnabled: () => environment.CONSOLE_TRACKER_ENABLED === 'true',
  trackPageView(path, title, trackingPageType, pageData) {
    console.log('PageView', trackingPageType, path, title, pageData)
  },
  trackEvent({ name, ...data }) {
    console.log('Event', name, JSON.stringify(data), data)
  },
}
