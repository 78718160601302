import React, { HTMLProps } from 'react'
import classnames from 'classnames'
import styles from './neutralizerStyles.module.scss'

type Props = HTMLProps<HTMLDivElement> & {
  innerRef?: React.Ref<HTMLDivElement>
  flex?: boolean
}

const NeutralizerWithInnerRef: React.FC<Props> = (props) => {
  const { innerRef, flex, ...divProps } = props

  return (
    <div
      {...divProps}
      ref={innerRef}
      className={classnames(props.className, styles.neutralizer, {
        [styles.flex]: props.flex,
      })}
    >
      {props.children}
    </div>
  )
}

export const Neutralizer = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => <NeutralizerWithInnerRef innerRef={ref} {...props} />
)
