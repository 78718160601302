import { TextField } from '@renderer-ui-library/atoms'
import { TFilterOption } from '@renderer-ui-library/molecules'
import { Autocomplete, Box } from '@renderer-ui-library/mui'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { ChangeEvent, useCallback } from 'react'
import { useMachineTypeName } from 'utils/machine/useMachineTypeName'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import styles from './machineTypeSelectFilter.module.scss'
import { useMachineTypeOptions } from './useMachineTypeOptions'

type Props = {
  name?: string
  selectedMachineType?: MachineType
  machineTypeSelectPlaceholder?: string
  onChange: (machineType: MachineType) => void
  onBlur?: (e: ChangeEvent) => void
  error?: string | boolean | undefined
  required?: true
}

export const MachineTypeSelectFilter: React.FC<Props> = React.memo(
  ({
    name,
    onChange,
    selectedMachineType,
    machineTypeSelectPlaceholder,
    error,
    required,
  }) => {
    const { t } = useTranslation(localeNamespace.common)
    const { getMachineTypeName } = useMachineTypeName()
    const machineTypeOptions = useMachineTypeOptions()

    const handleChangeMachineType = useCallback(
      (_: any, option: TFilterOption | null) => {
        if (option) {
          onChange(option.id as MachineType)
        }
      },
      [onChange]
    )

    return (
      <Autocomplete
        id='machine-type'
        options={machineTypeOptions}
        value={
          selectedMachineType
            ? {
                id: selectedMachineType,
                label: getMachineTypeName(selectedMachineType, 'plural'),
              }
            : {
                id: 'any',
                label:
                  machineTypeSelectPlaceholder ??
                  t(translations.machineTypePlaceholder).toString(),
              }
        }
        onChange={handleChangeMachineType}
        isOptionEqualToValue={(a, b) => a && b && a.id === b.id}
        groupBy={(option) => option.groupBy!}
        renderOption={(props, option) => (
          <Box component='li' {...props}>
            {option.img && (
              <img
                loading='lazy'
                className={styles.machineTypeImg}
                alt=''
                {...getMediaSrc({ data: option.img }, { ratio: 'original' })}
              />
            )}
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            placeholder={t(translations.searchResultsFiltersMachineTypeLabel)}
            size='small'
            error={!!error}
            helperText={error}
            required={required}
          />
        )}
      />
    )
  }
)

MachineTypeSelectFilter.displayName = 'MachineTypeSelectFilter'
