import { SvgIconComponent } from '@mui/icons-material'
import { SvgIcon, Typography } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { useTranslation } from 'next-i18next'
import React from 'react'
import styles from './serviceTitle.module.scss'

interface Props {
  icon: SvgIconComponent
  title: string
}

export const ServiceTitle: React.FC<Props> = React.memo(({ title, icon }) => {
  const { isMobile } = useMediaQuery()
  const { t } = useTranslation(localeNamespace.common)
  return (
    <div className={styles.wrapper}>
      <SvgIcon
        component={icon}
        fontSize={isMobile ? 'small' : 'medium'}
        className={styles.icon}
      />
      <div className={styles.title}>
        <Typography
          variant='h4'
          textTransform='capitalize'
          fontSize={{ xs: 'body2.fontSize', md: 'h4.fontSize' }}
        >
          {t(title)}
        </Typography>
      </div>
    </div>
  )
})

ServiceTitle.displayName = 'ServiceTitle'
