import {
  TLinkToCategoryPageData,
  TLinkToPageData,
  TLinkToSystemPageData,
} from '@renderer-ui-library/molecules'
import { CmsClickEventTargetNameCmsNotation } from 'utils/tracking/convertCmsTrackingEvents'

export enum TButtonAction {
  openContactUsForm = 'openContactUsForm',
  openInspectionForm = 'openInspectionForm',
  openSearchRequestForm = 'openSearchRequestForm',
}

export type TButtonData = {
  id: string
  text: string
  url: string
  systemPage:
    | { data: null }
    | { data: { id: number; attributes: TLinkToSystemPageData } }
  page: { data: null } | { data: { id: number; attributes: TLinkToPageData } }
  machineCategoryPage:
    | { data: null }
    | { data: { id: number; attributes: TLinkToCategoryPageData } }
  action: null | undefined | TButtonAction
  trackingClickEvent?: CmsClickEventTargetNameCmsNotation | null
}
