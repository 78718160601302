import { useCallback, useContext } from 'react'
import { UserContext } from '../user/UserContext'
import { Machine } from './Machine'

export const useFinancingData = () => {
  const { user } = useContext(UserContext)

  const getFinancingData = useCallback(
    (machine: Machine) =>
      user.country ? (machine.financing[user.country] ?? null) : null,
    [user.country]
  )

  return { getFinancingData }
}
