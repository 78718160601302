import { consoleTracker } from './gtm/consoleTracker'
import { gtmTracker } from './gtm/gtmTracker'
import { makeTracker } from './makeTracker'
import { TTracker } from './TTracker'

const trackers: TTracker[] = [gtmTracker, consoleTracker, makeTracker].filter(
  (t) => t.isEnabled()
)

export const tracker: TTracker = {
  isEnabled: () => true,
  trackPageView(...args) {
    trackers.forEach((tracker) => tracker.trackPageView(...args))
  },
  trackEvent(...args) {
    trackers.forEach((tracker) => tracker.trackEvent(...args))
  },
  init() {
    trackers.forEach((tracker) => tracker.init?.())
  },
}
