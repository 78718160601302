import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { boolean, object, string } from 'yup'

export const useValidationSchema = () => {
  const { t } = useTranslation(localeNamespace.common)

  const requestLeasingValidationSchema = useMemo(
    () =>
      object({
        mail: string()
          .email(t(translations.formErrorInvalidEmail))
          .required(t(translations.formErrorRequiredField)),
        areaCode: object()
          .shape({
            code: string().required(t(translations.formErrorRequiredField)),
            phone: string().required(t(translations.formErrorRequiredField)),
          })
          .required(t(translations.formErrorRequiredField)),
        phone: string()
          .test(
            'phone-number',
            t(translations.formErrorRequiredField),
            (value, context) => {
              const phoneNumber = parsePhoneNumber(
                `+${context.parent.areaCode.phone}${value}`
              )
              return !!phoneNumber?.isValid()
            }
          )
          .required(t(translations.formErrorRequiredField)),
        firstName: string().required(t(translations.formErrorRequiredField)),
        lastName: string().required(t(translations.formErrorRequiredField)),
        subscribeToNewsletter: boolean().optional(),
        message: string().optional(),
        smsAgreement: boolean().optional(),
      }),
    [t]
  )

  const contactUsValidationSchema = useMemo(
    () =>
      object({
        mail: string()
          .email(t(translations.formErrorInvalidEmail))
          .required(t(translations.formErrorRequiredField)),
        message: string().optional(),
        areaCode: object()
          .shape({
            code: string().required(t(translations.formErrorRequiredField)),
            phone: string().required(t(translations.formErrorRequiredField)),
          })
          .required(t(translations.formErrorRequiredField)),
        phone: string()
          .test(
            'phone-number',
            t(translations.formErrorRequiredField),
            (value, context) => {
              const phoneNumber = parsePhoneNumber(
                `+${context.parent.areaCode.phone}${value}`
              )
              return !!phoneNumber?.isValid()
            }
          )
          .required(t(translations.formErrorRequiredField)),
        firstName: string().required(t(translations.formErrorRequiredField)),
        lastName: string().required(t(translations.formErrorRequiredField)),
        subscribeToNewsletter: boolean().optional(),
        smsAgreement: boolean().optional(),
        budget: string().optional(),
        timing: string().optional(),
        reason: string().optional(),
      }),
    [t]
  )

  const searchRequestValidationSchema = useMemo(
    () =>
      object({
        mail: string()
          .email(t(translations.formErrorInvalidEmail))
          .required(t(translations.formErrorRequiredField)),
        firstName: string().required(t(translations.formErrorRequiredField)),
        lastName: string().required(t(translations.formErrorRequiredField)),
      }),
    [t]
  )

  const newsletterValidationSchema = useMemo(
    () =>
      object({
        email: string()
          .email(t(translations.formErrorInvalidEmail))
          .required(t(translations.formErrorRequiredField)),
        firstName: string().required(t(translations.formErrorRequiredField)),
        lastName: string().required(t(translations.formErrorRequiredField)),
      }),
    [t]
  )

  const callBackValidationSchema = useMemo(
    () =>
      object({
        areaCode: object()
          .shape({
            code: string().required(t(translations.formErrorRequiredField)),
            phone: string().required(t(translations.formErrorRequiredField)),
          })
          .required(t(translations.formErrorRequiredField)),
        phone: string()
          .test(
            'phone-number',
            t(translations.formErrorRequiredField),
            (value, context) => {
              const phoneNumber = parsePhoneNumber(
                `+${context.parent.areaCode.phone}${value}`
              )
              return !!phoneNumber?.isValid()
            }
          )
          .required(t(translations.formErrorRequiredField)),
        topic: string().required(t(translations.formErrorRequiredField)),
        subscribeToNewsletter: boolean().optional(),
        smsAgreement: boolean().optional(),
      }),
    [t]
  )

  return {
    requestLeasingValidationSchema,
    contactUsValidationSchema,
    searchRequestValidationSchema,
    newsletterValidationSchema,
    callBackValidationSchema,
  }
}
