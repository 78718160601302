import { MachineDataSource } from '@website-shared-library/machine/MachineDataSource'
import { MachineData } from './Machine'
import { TUnconfirmedMachineAttributesMap } from './TUnconfirmedMachineAttributesMap'

const unconfirmedSources: MachineDataSource[] = [
  MachineDataSource.vin,
  MachineDataSource.model_metadata,
  MachineDataSource.reap,
]

export const getUnconfirmedMachineAttributesMap = (
  keySources: MachineData['KEY_SOURCES']
): TUnconfirmedMachineAttributesMap => {
  const sources = keySources ?? {}
  const map: TUnconfirmedMachineAttributesMap = {}

  Object.keys(sources).forEach((attribute) => {
    const source = sources[attribute]!

    if (unconfirmedSources.includes(source)) {
      map[attribute] = true
    }
  })

  return map
}
