import { ArrowForward, Close } from '@mui/icons-material'
import { ServiceTitle } from '@renderer-ui-library/atoms'
import { Popover, Typography } from '@renderer-ui-library/mui'
import { Service } from 'blocks/ServiceBlock/IServiceBlockData'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { ActionableButton } from '../ActionableButton/ActionableButton'
import { ServiceEntries } from '../ServiceBlockButton/serviceEntries'
import styles from './servicePopoverModal.module.scss'

interface Props {
  handleClose: () => void
  anchorEl: HTMLButtonElement | null
  openOverlay: boolean
  service: Service | null
}

export const ServicePopoverModal: React.FC<Props> = React.memo(
  ({ openOverlay, handleClose, anchorEl, service }) => (
    <>
      {service && (
        <Popover
          open={openOverlay}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <div className={styles.container}>
            <div className={styles.titleWrapper}>
              <ServiceTitle
                title={ServiceEntries[service.serviceType].title}
                icon={ServiceEntries[service.serviceType].icon}
              />
              <Close
                onClick={handleClose}
                fontSize='medium'
                className={styles.closeButton}
              />
            </div>
            <Typography variant='body1'>
              <ReactMarkdown>{service.description}</ReactMarkdown>
            </Typography>
            {service.button && (
              <ActionableButton
                button={service.button}
                variant='contained'
                color='secondary'
                endIcon={<ArrowForward />}
              />
            )}
          </div>
        </Popover>
      )}
    </>
  )
)

ServicePopoverModal.displayName = 'ServicePopoverModal'
