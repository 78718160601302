import { Typography } from '@renderer-ui-library/mui'
import { lightPetrol } from '@renderer-ui-library/mui/base/colors.module.scss'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { TPerson } from 'services/TSalesManagerPayload'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import { MachineDetailsContactButtons } from './MachineDetailsContactButtons'
import styles from './machineDetailsContact.module.scss'

interface Props {
  contact: TPerson
  machineGroupId: string
  locale: TLocale
}

export const MachineDetailsContactInfo: React.FC<Props> = React.memo(
  ({ contact, machineGroupId, locale }) => {
    const { t } = useTranslation(localeNamespace.common)

    return (
      <div className={styles.contactWrapper}>
        <div className={styles.headlineWrapper}>
          <Typography
            variant='h6'
            className={styles.contactHeadline}
            sx={{ textTransform: 'uppercase' }}
          >
            {t(translations.yourContact)}
          </Typography>
        </div>

        <div className={styles.contactInfoContainerDesktop}>
          <div className={styles.contactInfo}>
            <div className={styles.imageWrapper}>
              <img
                loading='lazy'
                key={contact.image.data.id}
                alt={contact.image.data.attributes.alternativeText}
                {...getMediaSrc(
                  {
                    data: {
                      id: contact.image.data.id,
                      attributes: contact.image.data.attributes,
                    },
                  },
                  {
                    ratio: 1 / 1,
                  }
                )}
              />
            </div>
            <div>
              <Typography
                variant='h6'
                color='primary'
                textTransform='uppercase'
                className={styles.contactPersonName}
              >
                {`${contact.firstName} ${contact.lastName}`}
              </Typography>
              <Typography variant='subtitle2' color={lightPetrol}>
                {t(translations.machineDetailsContactCountryManagerText)}
              </Typography>
            </div>
          </div>

          <MachineDetailsContactButtons
            isMobile={false}
            contact={contact}
            machineGroupId={machineGroupId}
            iconOnly={false}
            locale={locale}
          />
        </div>
      </div>
    )
  }
)

MachineDetailsContactInfo.displayName = 'MachineDetailsContactInfo'
