import { SystemPageKey } from 'page/SystemPageKey'
import { TPageData } from 'page/TPageData'
import { PageTypeKey } from './PageTypeKey'
import { TPageType } from './TPageType'

export const getPageTypeFromPageData = (pageData: TPageData): TPageType => {
  if (!pageData)
    return {
      type: PageTypeKey.SystemPage,
      key: SystemPageKey.NotFound,
    }

  switch (pageData.type) {
    case PageTypeKey.SystemPage:
      return {
        type: PageTypeKey.SystemPage,
        key: pageData.key,
      }
    case PageTypeKey.MachineCategoryPage:
      return {
        type: PageTypeKey.MachineCategoryPage,
      }
    case PageTypeKey.Page:
      return {
        type: pageData.type,
      }
  }
}
