import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { Button, LinkComponent, TextInput } from '@renderer-ui-library/atoms'
import {
  TFilterOption,
  useMachineTypeOptions,
} from '@renderer-ui-library/molecules'
import { useAutocomplete } from '@renderer-ui-library/mui'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import styles from './allMachinesList.module.scss'

type Props = {
  onEntryClick: () => void
  allOffersUrl: string
}

export const AllMachinesList: React.FC<Props> = (props) => {
  const { t } = useTranslation(localeNamespace.common)
  const machineTypeOptions = useMachineTypeOptions()

  const getOptionLabel = useCallback(
    (option: TFilterOption) => option.label,
    []
  )

  const { getRootProps, getInputProps, getListboxProps, groupedOptions } =
    useAutocomplete({
      id: 'AllMachinesList',
      options: machineTypeOptions,
      getOptionLabel,
      multiple: false,
      open: true,
    })

  const rootProps = useMemo(() => getRootProps(), [getRootProps])

  const inputProps = getInputProps()
  const { getMachineTypeName } = useMachineTypeName()

  const links = useMemo(
    () =>
      (groupedOptions as TFilterOption[]).map((o) => ({
        id: o.id,
        label: o.label,
        url: getMachineTypeName(o.id as MachineType, 'plural'),
      })),
    [getMachineTypeName, groupedOptions]
  )

  return (
    <>
      <div {...rootProps} className={styles.inputWrapper}>
        <TextInput
          inputProps={inputProps}
          fullWidth
          placeholder={t(
            translations.mainNavigationMachineTypeSearchPlaceholder
          )}
        />
      </div>
      <ul className={styles.list} {...getListboxProps()}>
        {links.map(({ id, url, label }) => (
          <li className={styles.item} key={id}>
            <LinkComponent
              prefetch={false}
              onClick={props.onEntryClick}
              href={url}
              textColorVariant='primary'
            >
              {label}
            </LinkComponent>
          </li>
        ))}
      </ul>
      <div className={styles.allButton}>
        <Button
          color='secondary'
          href={props.allOffersUrl}
          onClick={props.onEntryClick}
          fullWidth
        >
          {t(translations.mobileMenuAllMachinesAllOffersButton)}
        </Button>
      </div>
    </>
  )
}
