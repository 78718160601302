import { useRouter } from 'next/router'
import { SystemPageKey } from 'page/SystemPageKey'
import { TPageProps } from 'page/TPageProps'
import { useEffect, useMemo } from 'react'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { getTrackingPageType } from 'utils/tracking/gtm/getTrackingPageType'
import { tracker } from 'utils/tracking/tracker'

export const usePageTracking = (pageProps: TPageProps) => {
  const router = useRouter()

  const machine = pageProps.meta.pdp?.machine
  const pageData = pageProps.data

  useEffect(() => {
    if (!pageData) {
      return
    }

    if (
      pageData.type === PageTypeKey.SystemPage &&
      pageData.key === SystemPageKey.PDP &&
      machine
    ) {
      tracker.trackEvent({
        name: 'ProductDetailImpressions',
        action: 'product-tag',
        ecommerce: {
          detail: {
            products: [{ id: machine.machineGroupId }],
          },
        },
      })

      tracker.trackEvent({
        name: 'view_item',
        ecommerce: {
          items: [
            {
              machine_group_id: machine.machineGroupId,
              item_name: machine.model,
              item_brand: machine.brand,
              price: machine.price ?? undefined,
              item_hp: machine.enginePower ?? undefined,
              item_hours: machine.engineHours ?? undefined,
              item_year: machine.yearOfProduction ?? undefined,
              item_location: machine.locationCountry ?? undefined,
              item_category: machine.machineType,
              item_id: machine.machineGroupId,
            },
          ],
        },
      })
    }
  }, [machine, pageData])

  const trackingPageType = useMemo(
    () => (pageProps.data ? getTrackingPageType(pageProps.data) : null),
    [pageProps.data]
  )

  const machineCategoryPageFilter = useMemo(() => {
    if (!pageProps.data) {
      return
    }

    if (pageProps.data.type === PageTypeKey.MachineCategoryPage) {
      return [
        pageProps.data.machineType,
        pageProps.data.brand,
        pageProps.data.model,
      ]
        .filter((e): e is string => !!e)
        .map((e) => e.toLowerCase().replace(/\s/g, '_'))
        .join('.')
    }
  }, [pageProps.data])

  useEffect(() => {
    tracker.trackPageView(
      location.pathname + location.search,
      document.title,
      trackingPageType,
      {
        machineCategoryPageFilter,
        machineGroupId: machine?.machineGroupId,
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleRouteChange = (path: string) =>
      tracker.trackPageView(path, document.title, trackingPageType, {
        machineCategoryPageFilter,
        machineGroupId: machine?.machineGroupId,
      })
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [
    router,
    trackingPageType,
    machineCategoryPageFilter,
    machine?.machineGroupId,
  ])
}
