import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from '@mui/icons-material'
import { Checkbox } from '@renderer-ui-library/mui'
import { useCallback } from 'react'
import { TFilterOption } from './TFilterOption'
import styles from './option.module.scss'

type Props = {
  option: TFilterOption
  onClick: (option: TFilterOption) => void
  optionProps: React.HTMLAttributes<HTMLLIElement>
}

export const Option: React.FC<Props> = ({ onClick, option, optionProps }) => {
  const handleClick = useCallback(() => onClick(option), [onClick, option])
  return (
    <li className={styles.option} {...optionProps} onClick={handleClick}>
      <Checkbox
        icon={<CheckBoxOutlineBlank fontSize='small' />}
        checkedIcon={<CheckBoxIcon fontSize='small' />}
        style={{ marginRight: 8 }}
        checked={option.selected}
      />
      {option.label}
    </li>
  )
}
