import { createContext } from 'react'
import { BrowserHistoryStack } from './hooks/useBrowserHistoryStack'

type TBrowserHistoryStackContext = {
  browserHistoryStack: BrowserHistoryStack
}
export const BrowserHistoryStackContext =
  createContext<TBrowserHistoryStackContext>({
    browserHistoryStack: [],
  })
