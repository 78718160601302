import { availableLocales } from './availableLocales'

/*
 * one can limit the locales that get exposed to our users while we are rolling out new languages
 * not exposing an available locale will make it accessible if you type the url directly,
 * but it will show show up in the sitemap and in the language selection
 *
 * eg: filter `bg, sl` locales
 * export const exposedLocales = availableLocales.filter(
 *   (locale) => !['bg', 'sl'].includes(locale)
 * );
 */

export const exposedLocales = availableLocales
