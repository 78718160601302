import dynamic from 'next/dynamic'
import { BlockProps } from '../BlockProps'
import { IAuctionBlockData } from './IAuctionBlockData'

export const DynamicAuctionBlock = dynamic<BlockProps<IAuctionBlockData>>(
  () => import('./AuctionBlock').then((md) => md.AuctionBlock),
  {
    ssr: false,
  }
)
