import { ButtonProps, Button as MUIButton } from '@mui/material'
import classNames from 'classnames'
import { ElementType } from 'react'
import styles from './button.module.scss'

export type Props = ButtonProps & {
  noMinWidth?: boolean
  fullHeight?: boolean
  flex?: boolean
  component?: ElementType // should be defined in ButtonProps, but is not and we cannot find it, so we have to manually add it here
}

export const Button: React.FC<Props> = ({
  noMinWidth,
  fullHeight,
  flex,
  ...muiButtonProps
}) => (
  <div
    className={classNames(styles.buttonWrapper, {
      [styles.fullWidth]: muiButtonProps.fullWidth,
      [styles.flex]: flex,
    })}
  >
    <MUIButton
      {...muiButtonProps}
      variant={muiButtonProps.variant ? muiButtonProps.variant : 'contained'}
      className={classNames(muiButtonProps.className, styles.button, {
        [styles.noMinWidth]: noMinWidth,
        [styles.fullHeight]: fullHeight,
      })}
    />
  </div>
)
