import { useMediaQuery as MuiUseMediaQuery } from '../../'
import { Font, useCreateTheme } from '../../base/useCreateTheme'

export const useMediaQuery = () => {
  const { theme } = useCreateTheme(Font.Default)
  const isMobileSmall = MuiUseMediaQuery(theme.breakpoints.down('sm')) // < 600
  const isMobile = MuiUseMediaQuery(theme.breakpoints.down('md')) // < 900
  const isTablet = MuiUseMediaQuery(theme.breakpoints.down('lg')) // < 1200
  const isDesktop = MuiUseMediaQuery(theme.breakpoints.up('lg')) // >= 1200

  return {
    isMobileSmall,
    isMobile,
    isTablet,
    isDesktop,
  }
}
