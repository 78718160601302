import { ImageRatio } from '@internal/utils/media/ImageRatio'
import { MediaContext, TMedia } from '@renderer-ui-library/molecules'
import {
  CardActionArea,
  CardContent,
  Card as MuiCard,
  Typography,
} from '@renderer-ui-library/mui'
import classNames from 'classnames'
import React, { useContext } from 'react'
import styles from './list.module.scss'

export type ListProps = {
  media: TMedia
  title: string
  children: React.ReactNode
  isLink?: boolean
  grow?: boolean
}

const Content: React.FC<ListProps> = (props) => {
  const { getMediaSrc } = useContext(MediaContext)

  return (
    <div className={styles.cardArea}>
      <img
        loading='lazy'
        className={styles.media}
        alt={props.media.attributes.alternativeText}
        {...getMediaSrc(props.media, {
          ratio: ImageRatio.Landscape,
        })}
      />
      <CardContent className={styles.content}>
        <Typography noWrap variant='h4' title={props.title}>
          {props.title}
        </Typography>
        {props.children}
      </CardContent>
    </div>
  )
}

export const List: React.FC<ListProps> = React.memo((props) => {
  return (
    <div className={classNames({ [styles.grow]: props.grow })}>
      <MuiCard variant='outlined'>
        {props.isLink ? (
          <CardActionArea disableRipple>
            <Content {...props} />
          </CardActionArea>
        ) : (
          <Content {...props} />
        )}
      </MuiCard>
    </div>
  )
})

List.displayName = 'List'
