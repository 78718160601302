import { FC, useEffect, useRef, useState } from 'react'

type Props = {
  children: React.ReactNode
  className?: string
  onVisibilityChange?: (isVisible: boolean) => void
  onScrolledIntoViewportOnce?: () => void
}
export const VisibilityChangeWrapper: FC<Props> = ({
  children,
  className,
  onVisibilityChange,
  onScrolledIntoViewportOnce,
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [scrolledIntoViewport, setScrolledIntoViewport] = useState(false)

  useEffect(() => {
    const handleIntersection: IntersectionObserverCallback = (entries) => {
      const entry = entries[0]

      if (entry) {
        onVisibilityChange?.(entry.isIntersecting)
        if (entry.isIntersecting && !scrolledIntoViewport) {
          setScrolledIntoViewport(true)
          onScrolledIntoViewportOnce?.()
        }
      }
    }
    let observer = new IntersectionObserver(handleIntersection, {})
    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [onScrolledIntoViewportOnce, onVisibilityChange, scrolledIntoViewport])

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  )
}
