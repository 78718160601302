import React from 'react'
import { GetMediaSrcFn, MediaContext } from './MediaContext'

interface MediaProviderProps {
  getMediaSrc: GetMediaSrcFn
  children?: React.ReactNode
}

export const MediaProvider: React.FC<MediaProviderProps> = React.memo(
  ({ children, getMediaSrc }) => (
    <MediaContext.Provider value={{ getMediaSrc }}>
      {children}
    </MediaContext.Provider>
  )
)

MediaProvider.displayName = 'MediaProvider'
