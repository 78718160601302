import { ClickAwayListener } from '@renderer-ui-library/mui'
import { useBlockGlobalScrolling } from '@renderer-ui-library/mui/utils/globalScrolling/useBlockGlobalScrolling'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './callbackModal.module.scss'

interface Props {
  open: boolean
  onClose: () => void
  children: React.ReactNode
}

export const CallbackModal: React.FC<Props> = React.memo(
  ({ open, children, onClose }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { disableGlobalScrolling, enableGlobalScrolling } =
      useBlockGlobalScrolling()

    useEffect(() => {
      if (open) {
        setModalOpen(true)
        disableGlobalScrolling()
      } else {
        enableGlobalScrolling()
        setTimeout(() => {
          setModalOpen(false)
        }, 400)
      }
    }, [disableGlobalScrolling, enableGlobalScrolling, open])

    return (
      <div
        className={classnames(styles.dialog, {
          [styles.open]: modalOpen,
        })}
      >
        <div
          className={classnames(styles.overlay, {
            [styles.open]: open,
          })}
        />
        <div
          className={classnames(styles.container, {
            [styles.open]: open,
          })}
        >
          <ClickAwayListener onClickAway={onClose}>
            <div className={styles.content}>{children}</div>
          </ClickAwayListener>
        </div>
      </div>
    )
  }
)

CallbackModal.displayName = 'CallbackModal'
