import { TMedia, isMediaFile } from '@renderer-ui-library/molecules'
import { ImageRatio } from '@renderer-ui-library/mui/utils/media/ImageRatio'
import { environment } from 'environment/environment'
import { isLocalMedia } from './isLocalMedia'
import { isMachineMedia } from './isMachineMedia'
const {
  CMS_MEDIA_CDN_URL,
  APP_MEDIA_CDN_URL,
  EXTERNAL_MEDIA_CDN_URL,
  SMARTTRADE_MEDIA_CDN_URL,
} = environment

const sizes = [400, 600, 900, 1200]
const smallSizes = [150, 300]
const mediumSizes = [300, 600]
const xlSizes = [900, 1200, 1600, 3200]

type ImgAttributes = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

type GetMediaSrcFn = (
  media: { data: TMedia },
  options: {
    ratio: 'original' | ImageRatio
    desktopViewportPrecentage?: number
    disableMachineWatermark?: boolean
    isExtraLarge?: boolean
  }
) => ImgAttributes

export const getMediaUrl = (
  media: { data: TMedia },
  ratio: 'original' | ImageRatio = 'original',
  width: number,
  disableMachineWatermark?: boolean
): string => {
  let baseUrl = media.data.attributes.url
  const transformations: string[] = []

  transformations.push(`w-${width}`)

  if (ratio !== 'original') {
    transformations.push(`ar-1000-${Math.round(1000 / ratio)}`)
  }

  if (isMediaFile(media.data)) {
    baseUrl = `${CMS_MEDIA_CDN_URL}/${media.data.attributes.hash}${media.data.attributes.ext}`
  } else if (isMachineMedia(media.data)) {
    baseUrl = `${EXTERNAL_MEDIA_CDN_URL}${media.data.attributes.url}`
    if (!disableMachineWatermark) {
      // get space base on image with in range of [5,10]px
      const space = Math.min(10, Math.max(5, Math.floor(width / 100)))
      const wmWidth = Math.floor(width * 0.15)
      // svg watermarks cannot be scaled, so we have to use a png in this case
      transformations.push(
        `l-image,i-@@website-machine-images-watermarks@@watermark_DZDDMXPYs_M9F2mQxfH.png,lx-${space},ly-${space},w-${wmWidth},l-end`
      )
    }
  } else if (isLocalMedia(media.data)) {
    baseUrl = media.data.attributes.url
  }

  return `${baseUrl}?tr=${transformations.join(',')}`
}

export const getMediaSrc: GetMediaSrcFn = (
  media,
  {
    ratio: displayRatio,
    disableMachineWatermark = undefined,
    isExtraLarge = false,
  }
) => {
  const srcSetSizes = isExtraLarge ? xlSizes : sizes
  const attributes: ImgAttributes = {
    src: getMediaUrl(
      media,
      displayRatio,
      isExtraLarge ? sizes[2] : sizes[0],
      disableMachineWatermark
    ),
    srcSet: srcSetSizes
      .map((size) => {
        return `${getMediaUrl(
          media,
          displayRatio,
          size,
          disableMachineWatermark
        )} ${size}w`
      })
      .join(', '),
    height: 'auto',
    alt:
      isMediaFile(media.data) || media.data.attributes.alternativeText
        ? media.data.attributes.alternativeText
        : '',
  }
  return attributes
}

export const getResponsiveSmallMediaSrc: GetMediaSrcFn = (
  media,
  { ratio: displayRatio, disableMachineWatermark = undefined }
) => {
  const attributes: ImgAttributes = {
    src: getMediaUrl(media, displayRatio, smallSizes[0]),
    srcSet: smallSizes
      .map(
        (size) =>
          `${getMediaUrl(
            media,
            displayRatio,
            size,
            disableMachineWatermark
          )} ${size}w`
      )
      .join(', '),
    height: 'auto',
    alt:
      isMediaFile(media.data) || media.data.attributes.alternativeText
        ? media.data.attributes.alternativeText
        : '',
  }
  return attributes
}

export const getResponsiveMediumMediaSrc: GetMediaSrcFn = (
  media,
  { ratio: displayRatio, disableMachineWatermark = undefined }
) => {
  const attributes: ImgAttributes = {
    src: getMediaUrl(media, displayRatio, mediumSizes[0]),
    srcSet: mediumSizes
      .map(
        (size) =>
          `${getMediaUrl(
            media,
            displayRatio,
            size,
            disableMachineWatermark
          )} ${size}w`
      )
      .join(', '),
    height: 'auto',
    alt:
      isMediaFile(media.data) || media.data.attributes.alternativeText
        ? media.data.attributes.alternativeText
        : '',
  }
  return attributes
}
