import { Button } from '@renderer-ui-library/atoms'
import { ButtonProps } from '@renderer-ui-library/mui'
import { ContactWizardContext } from '@renderer-ui-library/organisms'
import React, { useCallback, useContext } from 'react'

interface Props {
  noMinWidth?: boolean
}

export const InspectionButton: React.FC<Props & ButtonProps> = React.memo(
  ({ title, onClick, ...rest }) => {
    const { openContactUs } = useContext(ContactWizardContext)

    const handleClick = useCallback(
      (ev: React.MouseEvent<HTMLButtonElement>) => {
        openContactUs()
        if (onClick) {
          onClick(ev)
        }
      },
      [openContactUs, onClick]
    )

    return (
      <Button onClick={handleClick} {...rest}>
        {title}
      </Button>
    )
  }
)

InspectionButton.displayName = 'InspectionButton'
