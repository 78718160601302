import { CheckCircleOutline } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React from 'react'
import styles from './callBackWizard.module.scss'

interface Props {
  onCloseClick?: () => void
}

export const CallBackWizardFinishedStep: React.FC<Props> = React.memo(
  ({ onCloseClick }) => {
    const { t } = useTranslation(localeNamespace.common)

    return (
      <>
        <div className={styles.content}>
          <div className={styles.icon}>
            <CheckCircleOutline color='primary' fontSize='inherit' />
          </div>
          <div className={styles.title}>
            <Typography variant='h4' component='p' color='primary'>
              {t(translations.requestCallBackWizardTitle)}
            </Typography>
          </div>
          <div className={styles.message}>
            <Typography variant='body1'>
              {t(translations.requestCallBackWizardSuccessDescription)}
            </Typography>
          </div>
          <Button onClick={onCloseClick} variant='outlined'>
            {t(translations.requestCallBackWizardButtonText)}
          </Button>
        </div>
        <div>
          <Typography variant='body2' color='textSecondary'>
            * {t(translations.requestCallBackWizardExpectDelayHint)}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {t(translations.requestCallBackWizardWorkingHoursText)}
          </Typography>
        </div>
      </>
    )
  }
)

CallBackWizardFinishedStep.displayName = 'CallBackWizardFinishedStep'
