import { LocalShippingOutlined } from '@mui/icons-material'
import { TFilterOption } from '@renderer-ui-library/molecules'
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  TextField,
  Typography,
} from '@renderer-ui-library/mui'
import { TDisplayDefinitionEntryOption } from '@website-shared-library/displayDefinition/entry/TDisplayDefinitionEntryOption'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useMemo } from 'react'
import { tracker } from 'utils/tracking/tracker'
import { useAutocompleteItem } from './AutocompleteItem'
import { SearchFilterCategory } from './SearchFilterCategory/SearchFilterCategory'
import styles from './locationFilter.module.scss'

type Props = {
  options: TDisplayDefinitionEntryOption[]
}

export const LocationFilter: React.FC<Props> = React.memo((props) => {
  const { locations, ...searchParams } = useSearchParams()
  const { gotoSearchResults } = useSearchResultsRouting()
  const { t } = useTranslation(localeNamespace.common)
  const { renderOption } = useAutocompleteItem()

  const options = useMemo<TFilterOption[]>(
    () =>
      props.options.map(({ key, name }) => ({
        label: name,
        id: key,
        selected: locations.includes(key),
      })),
    [props.options, locations]
  )

  const setLocations = useCallback(
    (locations: string[]) =>
      gotoSearchResults({
        ...searchParams,
        locations,
      }),
    [gotoSearchResults, searchParams]
  )

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent,
      value: TFilterOption[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<TFilterOption>
    ) => {
      const newLocations = value.map((option) => option.id)
      const addedLocations = newLocations.filter(
        (loc: string) => !locations.includes(loc)
      )
      setLocations(newLocations)

      if (addedLocations.length > 0) {
        tracker.trackEvent({
          name: 'filter-applied',
          filter_placement: 'serp',
          filter_category: 'location',
          filter_location: addedLocations[0],
          is_interaction: true,
        })
      }
    },
    [locations, setLocations]
  )

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        placeholder={t(translations.searchResultsFiltersLocationPlaceholder)}
        size='small'
      />
    ),
    [t]
  )

  const autocompleteValue = useMemo<TFilterOption[]>(() => {
    return options.filter((option) => option.selected)
  }, [options])

  const groupBy = useCallback(
    (option: TFilterOption) => {
      return options.findIndex((o) => o.id === option.id) < 5
        ? t(translations.searchResultsFiltersLocationPopularLabel)
        : 'All locations' // TODO Put "all locations" into translations
    },
    [t, options]
  )

  return (
    <SearchFilterCategory
      title={t(translations.searchResultsFiltersLocationLabel)}
    >
      <Autocomplete
        multiple
        disableCloseOnSelect
        limitTags={3}
        id='filtered-locations'
        options={options}
        renderOption={renderOption}
        renderInput={renderInput}
        value={autocompleteValue}
        onChange={handleChange}
        groupBy={groupBy}
      />
      <div className={styles.locationContentTop}>
        <LocalShippingOutlined className={styles.icon} />
        <Typography component='p' variant='caption' color='textSecondary'>
          {t(translations.searchResultsFiltersLocationContentLabel)}
        </Typography>
      </div>
    </SearchFilterCategory>
  )
})

LocationFilter.displayName = 'LocationFilter'
