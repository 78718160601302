import { AutocompleteRenderOptionState, Checkbox } from '@mui/material'
import { TFilterOption } from '@renderer-ui-library/molecules'
import { FC, useCallback } from 'react'

export type AutocompleteItemProps = {
  option: TFilterOption
  htmlAttributes?: React.HTMLAttributes<HTMLLIElement>
  state: AutocompleteRenderOptionState
}

export const AutocompleteItem: FC<AutocompleteItemProps> = (
  props: AutocompleteItemProps
) => {
  return (
    <li {...props.htmlAttributes}>
      <Checkbox size='small' checked={props.state.selected} />
      {props.option.label}
    </li>
  )
}

export const useAutocompleteItem = () => {
  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: TFilterOption,
      state: AutocompleteRenderOptionState
    ) => (
      <AutocompleteItem
        htmlAttributes={props}
        option={option}
        state={state}
        key={option.id}
      />
    ),
    []
  )
  return { renderOption }
}
