import { useEffect, useState } from 'react'
import styles from './globalScrolling.module.scss'

export const useBlockGlobalScrolling = () => {
  const [isBlocked, setBlocked] = useState(false)
  const hasClass = () => document.body.classList.contains(styles.blockScrolling)

  useEffect(() => {
    if (isBlocked && !hasClass()) {
      document.body.classList.add(styles.blockScrolling)
    } else if (!isBlocked && hasClass()) {
      document.body.classList.remove(styles.blockScrolling)
    }

    return () => {
      if (hasClass()) {
        document.body.classList.remove(styles.blockScrolling)
      }
    }
  }, [isBlocked])

  return {
    enableGlobalScrolling: () => setBlocked(false),
    disableGlobalScrolling: () => setBlocked(true),
  }
}
