import { Typography } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { HeaderContentWrapper } from '../HeaderContentWrapper/HeaderContentWrapper'
import styles from './salesAgentAwayBanner.module.scss'

interface IProps {
  name: string
  to: Date
  from: Date
}

export const SalesAgentAwayBanner: React.FC<IProps> = React.memo((props) => {
  const { t } = useTranslation(localeNamespace.common)
  const { name, to, from } = props
  const toYear = to.getFullYear()
  const toDay = to.getDate()
  const toMonth = to.getUTCMonth() + 1
  const fromYear = from.getFullYear()
  const fromDay = from.getDate()
  const fromMonth = from.getUTCMonth() + 1

  const text = t(translations.salesAgentAwayBanner, {
    name,
    from: `${fromYear}-${fromMonth}-${fromDay}`,
    to: `${toYear}-${toMonth}-${toDay}`,
  })

  if (!text) {
    return null
  }

  return (
    <div className={styles.outerWrapper}>
      <HeaderContentWrapper className={styles.wrapper}>
        <div className={styles.subtext}>
          <Typography color={Colors.textInvertedPrimary} variant='h6'>
            {text}
          </Typography>
        </div>
      </HeaderContentWrapper>
    </div>
  )
})

SalesAgentAwayBanner.displayName = 'SalesAgentAwayBanner'
