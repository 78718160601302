import { MachineType } from '@website-shared-library/machine/MachineType'
import React from 'react'
import { ProductCard } from '../ProductCard'
import styles from './productCardGhost.module.scss'

export const ProductCardGhost: React.FC = React.memo(() => (
  <div className={styles.ghost}>
    <ProductCard
      title='Placeholder'
      price={1}
      highlightData={{
        hours: 1,
        power: 1,
        year: 1,
      }}
      url='/en/'
      locale='en'
      machineType={MachineType.TRACTOR}
    />
  </div>
))

ProductCardGhost.displayName = 'ProductCardGhost'
