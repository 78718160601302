import Facebook from '@renderer-ui-library/assets/facebook.svg'
import Instagram from '@renderer-ui-library/assets/instagram.svg'
import Linkedin from '@renderer-ui-library/assets/linkedin.svg'
import Image from 'next/image'
import { ImageLoader } from 'utils/media/ImageLoader'
import styles from './socialButtons.module.scss'

export const SocialButtons: React.FC = () => (
  <div className={styles.social}>
    <a
      href='https://www.facebook.com/Efarmcom/'
      target='_blank'
      rel='noreferrer'
      className={styles.socialIcon}
    >
      <Image src={Facebook} alt='facebook' loader={ImageLoader} />
    </a>
    <a
      href='https://www.linkedin.com/company/e-farmcom/'
      target='_blank'
      rel='noreferrer'
      className={styles.socialIcon}
    >
      <Image src={Linkedin} alt='linkedin' loader={ImageLoader} />
    </a>
    <a
      href='https://www.instagram.com/efarm_com/'
      target='_blank'
      rel='noreferrer'
      className={styles.socialIcon}
    >
      <Image src={Instagram} alt='instagram' loader={ImageLoader} />
    </a>
  </div>
)
