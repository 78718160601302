import dynamic from 'next/dynamic'
import { Props } from './ScrollToTopButton'

export const DynamicScrollToTopButton = dynamic<Props>(
  () =>
    import('./ScrollToTopButton').then(
      (md) => md.ScrollToTopButton as any /* please TS */
    ),
  {
    ssr: false,
  }
)
