import { KeyboardArrowDown } from '@mui/icons-material'
import {
  InputAdornment,
  MenuItem,
  SvgIcon,
  TextField,
} from '@renderer-ui-library/mui'
import classnames from 'classnames'
import React, {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styles from './select.module.scss'

export type SelectOption = {
  value: string
  key: string
  iconComponent?: ReactNode
}

type SvgIconComponent = typeof SvgIcon

export type SelectProps = {
  options: SelectOption[]
  id?: string
  value?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  icon?: SvgIconComponent
  noOutline?: boolean
  fontSmall?: boolean
  size?: 'small' | 'medium'
}

const ArrowDownIconColorInherit = forwardRef<SVGSVGElement>((props, ref) => (
  <KeyboardArrowDown ref={ref} {...props} style={{ color: 'inherit' }} />
))
ArrowDownIconColorInherit.displayName = 'WhiteArrowDownIcon'

export const Select: React.FC<SelectProps> = (props) => {
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value, setValue])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value)
      props.onChange(event)
    },
    [props]
  )

  const IconComponent = props.icon

  return (
    <div
      className={classnames(styles.select, {
        [styles.noOutline]: props.noOutline,
        [styles.sizeMedium]: props.size === 'medium',
      })}
    >
      <TextField
        select
        size={props.size ? props.size : 'small'}
        id={props.id}
        value={value}
        color='primary'
        onChange={handleChange}
        SelectProps={{
          IconComponent: ArrowDownIconColorInherit,
        }}
        InputProps={{
          startAdornment: IconComponent ? (
            <InputAdornment position='start'>
              <IconComponent color='secondary' fontSize='small' />
            </InputAdornment>
          ) : null,
          className: props.fontSmall ? styles.fontSmall : undefined,
        }}
      >
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <div className={styles.menuItem}>
              {option.iconComponent && (
                <div className={styles.menuItemIcon}>
                  {option.iconComponent}
                </div>
              )}
              {option.key}
            </div>
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}
