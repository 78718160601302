import { LightbulbOutlined } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TFiltersConfiguration } from 'blocks/SearchResultsBlock/TFiltersConfiguration'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { AvailableMachineSubTypes } from 'utils/machine/AvailableMachineSubTypes'
import { BrandsFilter } from './BrandsFilter'
import { FeaturesFilter } from './FeaturesFilter'
import { LocationFilter } from './LocationFilter'
import { MachineSubTypeFilter } from './MachineSubTypeFilter'
import { MachineTypeFilter } from './MachineTypeFilter'
import { ModelFilter } from './ModelFilter'
import { RadioFilter } from './RadioFilter'
import { RangeFilter } from './RangeFilter'
import styles from './searchFilterBar.module.scss'

type Props =
  | {
      type: 'desktop'
      config: TFiltersConfiguration
      machineType: MachineType | undefined
      onClickApply?: () => void
      onClickReset?: () => void
    }
  | {
      type: 'mobile'
      config: TFiltersConfiguration
      machineType: MachineType | undefined
      onClickApply: () => void
      onClickReset: () => void
    }

export const SearchFilterBar: React.FC<Props> = React.memo(
  ({ machineType, config, onClickApply, onClickReset, type }) => {
    const { t } = useTranslation(localeNamespace.common)

    const openSubTypeFilter = AvailableMachineSubTypes.hasOwnProperty(
      machineType as MachineType
    )

    return (
      <>
        <div
          className={classNames(styles.wrapper, {
            [styles.desktop]: type === 'desktop',
          })}
        >
          <MachineTypeFilter />
          {machineType ? (
            <>
              <BrandsFilter selectedMachineType={machineType} />
              <ModelFilter />
              <MachineSubTypeFilter openSubTypeFilter={openSubTypeFilter} />
              {machineType === MachineType.BALER && <RadioFilter />}
              {machineType === MachineType.HEADER && <RadioFilter />}
              {config.rangeFilters?.map((config) => (
                <RangeFilter key={config.machineAttribute} config={config} />
              ))}
              {config.features && <FeaturesFilter options={config.features} />}
              {config.location && <LocationFilter options={config.location} />}
            </>
          ) : (
            <div className={styles.noMachine}>
              <Typography color='textSecondary' variant='body2'>
                <LightbulbOutlined className={styles.icon} color='inherit' />
              </Typography>

              <Typography color='textSecondary' variant='body2'>
                {t(translations.chooseMachineTypeToExploring)}
              </Typography>
            </div>
          )}
        </div>

        {onClickReset && onClickApply && (
          <div className={styles.buttons}>
            <div className={styles.button}>
              <Button
                noMinWidth
                fullWidth
                variant='outlined'
                size='medium'
                onClick={onClickReset}
              >
                {t(translations.searchResultsFilterReset)}
              </Button>
            </div>
            <div className={styles.button}>
              <Button
                color='secondary'
                noMinWidth
                fullWidth
                size='medium'
                onClick={onClickApply}
              >
                {t(translations.searchResultsFilterApply)}
              </Button>
            </div>
          </div>
        )}
      </>
    )
  }
)

SearchFilterBar.displayName = 'SearchFilterBar'
