import { KnownMachineHeaderType } from '../machine/KnownMachineHeaderType';

export const FilterableHeaderTypeOptions = {
  FRONT_END_EXTENSION: KnownMachineHeaderType.FRONT_END_EXTENSION.toString(),
  GPS_CUTTING_UNIT: KnownMachineHeaderType.GPS_CUTTING_UNIT.toString(),
  GRAIN_HEADER: KnownMachineHeaderType.GRAIN_HEADER.toString(),
  GRASS_SEED_HEADER: KnownMachineHeaderType.GRASS_SEED_HEADER.toString(),
  HEADER: KnownMachineHeaderType.HEADER.toString(),
  MAIZE_HEADER: KnownMachineHeaderType.MAIZE_HEADER.toString(),
  MAIZE_PICKER: KnownMachineHeaderType.MAIZE_PICKER.toString(),
  OTHER: KnownMachineHeaderType.OTHER.toString(),
  PICKER: KnownMachineHeaderType.PICKER.toString(),
  PICK_UP: KnownMachineHeaderType.PICK_UP.toString(),
  RAPESEED_HEADER: KnownMachineHeaderType.RAPESEED_HEADER.toString(),
  SUNFLOWER_HEADER: KnownMachineHeaderType.SUNFLOWER_HEADER.toString(),
};
