import {
  Text,
  TextField,
  WizardDataProtectionDisclaimer,
} from '@renderer-ui-library/atoms'
import { CountryCodeAutoComplete } from '@renderer-ui-library/molecules'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { ChangeEvent, SyntheticEvent } from 'react'
import { CountryCodeType } from 'utils/contact/phoneCountryCodes'
import styles from './wizardleasingStep.module.scss'

interface TLeasingData {
  areaCode: CountryCodeType
  phone: number | string
  mail: string
  firstName: string
  lastName: string
  message?: string
  newsletter: boolean
  smsAgreement: boolean
}

interface Props {
  onChange: (e: ChangeEvent) => void
  onChangeCountryCode: (
    e: SyntheticEvent,
    newValue: CountryCodeType | null
  ) => void
  onBlur: (e: ChangeEvent) => void
  data: TLeasingData
  phoneError?: string | false
  mailError?: string | false
  firstNameError?: string | false
  lastNameError?: string | false
}

export const WizardLeasingStep: React.FC<Props> = ({
  data,
  onChange,
  onChangeCountryCode,
  onBlur,
  phoneError,
  mailError,
  firstNameError,
  lastNameError,
}) => {
  const { t } = useTranslation(localeNamespace.common)

  return (
    <>
      <div className={styles.inputGroup}>
        <TextField
          size='small'
          id='wizard-first-name'
          name='firstName'
          label={t(translations.requestInformationWizardContactFirstNameLabel)}
          value={data.firstName}
          onChange={onChange}
          onBlur={onBlur}
          variant='outlined'
          error={!!firstNameError}
          helperText={firstNameError}
          fullWidth
          autoComplete='given-name'
          required
        />
        <div className={styles.input}>
          <TextField
            size='small'
            id='wizard-last-name'
            name='lastName'
            label={t(translations.requestInformationWizardContactLastNameLabel)}
            value={data.lastName}
            onChange={onChange}
            onBlur={onBlur}
            variant='outlined'
            error={!!lastNameError}
            helperText={lastNameError}
            fullWidth
            autoComplete='family-name'
            required
          />
        </div>
      </div>
      <div className={styles.inputGroup}>
        <TextField
          size='small'
          id='wizard-mail'
          name='mail'
          label={t(translations.requestInformationWizardContactEmailLabel)}
          value={data.mail}
          onChange={onChange}
          onBlur={onBlur}
          variant='outlined'
          error={!!mailError}
          helperText={mailError}
          fullWidth
          type='email'
          autoComplete='email'
          required
        />
      </div>
      <div className={styles.inputGroupPhone}>
        <div className={styles.inputCountryCode}>
          <CountryCodeAutoComplete
            areaCode={data.areaCode}
            onChange={onChangeCountryCode}
          />
        </div>

        <div className={styles.inputPhone}>
          <TextField
            size='small'
            id='wizard-phone'
            name='phone'
            label={t(translations.requestInformationWizardContactPhoneLabel)}
            value={data.phone}
            onChange={onChange}
            onBlur={onBlur}
            variant='outlined'
            error={!!phoneError}
            helperText={phoneError}
            fullWidth
            type='number'
            autoComplete='tel-national'
            required
          />
        </div>
      </div>
      <div>
        <TextField
          id='wizard-message'
          name='message'
          label={t(translations.requestInformationWizardMessageInputLabel)}
          multiline
          rows={3}
          value={data.message}
          onChange={onChange}
          onBlur={onBlur}
          variant='outlined'
          fullWidth
        />
      </div>

      <div className={styles.leasingDisclaimerLongText}>
        <Text
          variant='body2'
          text={t(translations.leasingDisclaimerLongText)}
        />
      </div>

      <WizardDataProtectionDisclaimer />
    </>
  )
}
