import { MachineTypeSelectFilter } from '@renderer-ui-library/molecules'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { tracker } from 'utils/tracking/tracker'
import { SearchFilterCategory } from './SearchFilterCategory/SearchFilterCategory'

export const MachineTypeFilter: React.FC = React.memo(() => {
  const { machineType, brands, sort } = useSearchParams()
  const { gotoSearchResults } = useSearchResultsRouting()
  const { t } = useTranslation(localeNamespace.common)

  const [newMachineType, setNewMachineType] = useState<MachineType | undefined>(
    machineType
  )

  useEffect(() => {
    setNewMachineType(machineType), [machineType]
  }, [machineType])

  const handleMachineTypeChange = useCallback(
    (machineType: MachineType) => {
      setNewMachineType(machineType)
      gotoSearchResults(
        {
          machineType,
          machineSubType: undefined,
          brands,
          features: [],
          locations: [],
          sort,
        },
        false // no shallow routing, to get filters from backend
      )
      tracker.trackEvent({
        name: 'filter-applied',
        filter_placement: 'serp',
        filter_category: 'machine-type',
        filter_machine_type: machineType,
        is_interaction: true,
      })
    },
    [brands, gotoSearchResults, sort]
  )

  return (
    <SearchFilterCategory
      title={t(translations.searchResultsFiltersMachineTypeLabel)}
    >
      <MachineTypeSelectFilter
        selectedMachineType={newMachineType}
        onChange={handleMachineTypeChange}
      />
    </SearchFilterCategory>
  )
})

MachineTypeFilter.displayName = 'MachineTypeFilter'
