import { noop } from 'lodash'
import { createContext } from 'react'

type TLeasingQualifiedContext = {
  toggleLeasingQualified: () => void
  isLeasingQualifiedEnabled: boolean
  enterCompareMode: () => void
  leaveCompareMode: () => void
}
export const LeasingQualifiedContext = createContext<TLeasingQualifiedContext>({
  toggleLeasingQualified: noop,
  isLeasingQualifiedEnabled: false,
  enterCompareMode: noop,
  leaveCompareMode: noop,
})
