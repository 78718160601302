import { createContext } from 'react'
import { TPerson } from 'services/TSalesManagerPayload'

interface SalesManagerContext {
  salesManager: TPerson | null
}

export const SalesManagerContext = createContext<SalesManagerContext>({
  salesManager: null,
})
