import { Container } from '@renderer-ui-library/mui'
import { IBaseBlockData } from 'blocks/IBaseBlockData'
import classNames from 'classnames'
import { PageContext } from 'page/PageContext'
import { useContext } from 'react'
import styles from './blockWrapper.module.scss'

type Props = {
  blockData: IBaseBlockData
  noSpacingTop?: boolean
  noSpacingBottom?: boolean
  fullWidth?: boolean
  children?: React.ReactNode
}

export const BlockWrapper: React.FC<Props> = (props) => {
  const { pageData } = useContext(PageContext)
  const highlightClass = props.blockData.highlight
    ? styles.highlight
    : pageData?.lightBackground
      ? styles.highlightDark
      : styles.highlight

  return (
    <section
      className={classNames(styles.wrapper, {
        [highlightClass]: props.blockData.highlight,
        [styles.noSpacingTop]: props.noSpacingTop,
        [styles.noSpacingBottom]: props.noSpacingBottom,
        [styles.fullWidth]: props.fullWidth,
      })}
    >
      <Container maxWidth={props.fullWidth ? false : 'lg'} disableGutters>
        {props.children}
      </Container>
    </section>
  )
}
