import React, { PropsWithChildren } from 'react'
import { PageContext, PageContextProps } from './PageContext'

export const PageProvider: React.FC<PropsWithChildren<PageContextProps>> =
  React.memo(
    ({ children, pageData, pathParams, machineType, localizedUrlMap }) => (
      <PageContext.Provider
        value={{ pageData, pathParams, machineType, localizedUrlMap }}
      >
        {children}
      </PageContext.Provider>
    )
  )

PageProvider.displayName = 'PageProvider'
