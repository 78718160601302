import { Typography, TypographyProps } from '@renderer-ui-library/mui'
import classnames from 'classnames'
import { FC, memo, useContext, useMemo } from 'react'
import ReactMarkdown, { Components } from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { TextContext } from './TextContext'
import { getMarkdownComponents } from './getMarkdownComponents'
import styles from './text.module.scss'

export type TextProps = {
  text: string
  variant?: TypographyProps['variant']
  color?: 'bright' | 'dark'
  alignment?: 'left' | 'center'
  components?: Components
  highlight?: boolean
}

export const Text: FC<TextProps> = memo(
  ({
    text,
    variant,
    color = 'dark',
    alignment = 'left',
    components,
    highlight,
  }) => {
    const { onLinkClick } = useContext(TextContext)
    let markdownComponents = useMemo(
      () => getMarkdownComponents({ color, onLinkClick }),
      [color, onLinkClick]
    )
    if (variant) {
      markdownComponents!.p = (props) => (
        <Typography
          variant={variant}
          color={color === 'dark' ? 'textSecondary' : 'white'}
        >
          {props.children}
        </Typography>
      )
    }

    if (components) {
      markdownComponents = { ...markdownComponents, ...components }
    }

    return (
      <div
        className={classnames(styles.text, {
          [styles.textAlignCenter]: alignment === 'center',
          [styles.highlight]: highlight,
        })}
      >
        <ReactMarkdown
          components={markdownComponents}
          rehypePlugins={[rehypeRaw]}
        >
          {text}
        </ReactMarkdown>
      </div>
    )
  }
)

Text.displayName = 'Text'
