import React, { ReactNode, useCallback, useState } from 'react'
import { LeasingQualifiedContext } from './leasingQualifiedContext'

type Props = {
  children: ReactNode
}
export const LeasingQualifiedContextProvider: React.FC<Props> = React.memo(
  ({ children }) => {
    const [isLeasingQualifiedEnabled, setIsLeasingQualifiedEnabled] =
      useState(false)

    const leaveCompareMode = useCallback(() => {
      setIsLeasingQualifiedEnabled(false)
    }, [])

    const enterCompareMode = useCallback(() => {
      setIsLeasingQualifiedEnabled(true)
    }, [])

    const toggleLeasingQualified = useCallback(() => {
      if (isLeasingQualifiedEnabled) {
        leaveCompareMode()
      } else {
        enterCompareMode()
      }
    }, [enterCompareMode, isLeasingQualifiedEnabled, leaveCompareMode])

    return (
      <LeasingQualifiedContext.Provider
        value={{
          enterCompareMode,
          leaveCompareMode,
          isLeasingQualifiedEnabled,
          toggleLeasingQualified,
        }}
      >
        {children}
      </LeasingQualifiedContext.Provider>
    )
  }
)

LeasingQualifiedContextProvider.displayName = 'LeasingQualifiedContextProvider'
