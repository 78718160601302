import { MachineType } from '@website-shared-library/machine/MachineType'
import { TLocalizedUrlMap } from 'i18n/TLocalizedUrlMap'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { TBasePageData } from './TBasePageData'

export enum MachineCategoryPageModelType {
  Model = 'model',
  BaseModel = 'baseModel',
  Fuzzy = 'fuzzy',
}
export type TMachineCategoryPageData = TBasePageData & {
  type: PageTypeKey.MachineCategoryPage
  localizations: Partial<TLocalizedUrlMap>
  machineType: MachineType
  machineTypeSlug: string
  brand?: string | null
  brandSlug?: string | null
  model?: string | null
  modelType: MachineCategoryPageModelType
  modelSlug?: string | null
}

export type TMachineCategoryPageDataForPDP = Pick<
  TMachineCategoryPageData,
  | 'localizations'
  | 'machineType'
  | 'machineTypeSlug'
  | 'brand'
  | 'brandSlug'
  | 'model'
  | 'modelSlug'
>

export const getMachineCategoryPageDataForPDP = (
  data: TMachineCategoryPageData
) => ({
  localizations: data.localizations,
  machineType: data.machineType,
  machineTypeSlug: data.machineTypeSlug,
  brand: data.brand,
  brandSlug: data.brandSlug,
  model: data.model,
  modelSlug: data.modelSlug,
})
