import { ArrowForward, Close } from '@mui/icons-material'
import { SvgIcon, Typography } from '@renderer-ui-library/mui'

import { MobileOverlay } from '@renderer-ui-library/atoms'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { Service } from 'blocks/ServiceBlock/IServiceBlockData'
import { useTranslation } from 'next-i18next'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { ActionableButton } from '../ActionableButton/ActionableButton'
import { ServiceEntries } from '../ServiceBlockButton/serviceEntries'
import styles from './serviceMobileOverlay.module.scss'

interface Props {
  openOverlay: boolean
  handleClose: () => void
  service: Service | null
}

export const ServiceMobileOverlay: React.FC<Props> = React.memo(
  ({ openOverlay, handleClose, service }) => {
    const { t } = useTranslation(localeNamespace.common)

    return (
      <>
        {service && (
          <div className={styles.mobileOverlayWrapper}>
            <MobileOverlay
              open={openOverlay}
              hideTitle
              onCloseClick={handleClose}
            >
              <div className={styles.overlayTitleContainer}>
                <div className={styles.overlayTitleWrapper}>
                  <SvgIcon
                    sx={{ marginRight: '5px' }}
                    component={ServiceEntries[service.serviceType].icon}
                  />
                  <Typography variant='h4' color='primary'>
                    {t(ServiceEntries[service.serviceType].title)}
                  </Typography>
                </div>
                <Close fontSize='small' onClick={handleClose} />
              </div>
              <div className={styles.overlayContentWrapper}>
                <Typography variant='body1'>
                  <ReactMarkdown>{service.description}</ReactMarkdown>
                </Typography>
              </div>
              {service.button && (
                <ActionableButton
                  fullWidth
                  button={service.button}
                  variant='contained'
                  color='secondary'
                  endIcon={<ArrowForward />}
                />
              )}
            </MobileOverlay>
          </div>
        )}
      </>
    )
  }
)

ServiceMobileOverlay.displayName = 'ServiceMobileOverlay'
