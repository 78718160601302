import { SystemPageKey } from '@internal/page/SystemPageKey'
import { TNavigationEntry } from '@internal/utils/navigation/TNavigationEntry'
import { urlForSystemPageWithoutParams } from '@internal/utils/routing/urls/urlForSystemPageWithoutParams'
import Logo from '@renderer-ui-library/assets/logo.svg'
import { LinkComponent, MobileOverlay } from '@renderer-ui-library/atoms'
import {
  TLocale,
  localeNamespace,
} from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { memo, useCallback, useMemo, useState } from 'react'
import { ImageLoader } from 'utils/media/ImageLoader'
import { TNavigation } from 'utils/navigation/TNavigation'
import { getNavigationItemLabel } from '../getNavigationItemLabel'
import { AllMachinesList } from './AllMachinesList/AllMachinesList'
import { LevelOneNavigation } from './LevelOneNavigation/LevelOneNavigation'
import { LevelTwoNavigation } from './LevelTwoNavigation/LevelTwoNavigation'
import styles from './mobileMenu.module.scss'

export type MobileMenuProps = {
  open: boolean
  onCloseClick: () => void
  locale: TLocale
  allOffersUrl: string
  primaryNavigation: TNavigation
  secondaryNavigation: TNavigation
  brandsNavigation: TNavigation
  footerNavigation: TNavigation
}

export type TContent = 'LevelOne' | 'LevelTwo' | 'AllMachines'

export type TMenuLocation = {
  content: TContent
  entry?: TNavigationEntry
}

export const MobileMenu: React.FC<MobileMenuProps> = memo(
  ({
    primaryNavigation,
    secondaryNavigation,
    brandsNavigation,
    onCloseClick,
    open,
    locale,
    allOffersUrl,
  }) => {
    const { t } = useTranslation(localeNamespace.common)
    const [menuLocation, setMenuLocation] = useState<TMenuLocation>({
      content: 'LevelOne',
    })

    const onChangeMenuLocation = useCallback(
      (location: TContent, entry?: TNavigationEntry) => {
        setMenuLocation({ content: location, entry })
      },
      []
    )
    const onClickBack = useCallback(
      () => setMenuLocation({ content: 'LevelOne', entry: undefined }),
      []
    )
    const onClose = useCallback(() => {
      setMenuLocation({ content: 'LevelOne', entry: undefined })
      onCloseClick()
    }, [onCloseClick, setMenuLocation])

    const titleContent = useMemo(() => {
      switch (menuLocation.content) {
        case 'LevelTwo': {
          return { title: getNavigationItemLabel(menuLocation.entry) }
        }
        case 'AllMachines': {
          return {
            title: t(translations.headerMainNavAllMachinesLabel),
          }
        }
        default: {
          return {
            titleContent: (
              <div className={styles.logo}>
                <LinkComponent
                  href={urlForSystemPageWithoutParams(
                    locale,
                    SystemPageKey.StartPage
                  )}
                  prefetch={false}
                  onClick={onClose}
                  locale={locale}
                >
                  <Image
                    src={Logo.src}
                    alt='E-FARM GmbH'
                    loader={ImageLoader}
                    width={137}
                    height={35}
                  />
                </LinkComponent>
              </div>
            ),
          }
        }
      }
    }, [menuLocation, locale, onClose, t])

    return (
      <div
        className={classNames(styles.mobileMenuWrapper, {
          [styles.mobileMenuWrapperOpen]: open,
        })}
      >
        <MobileOverlay
          {...titleContent}
          fullHeight
          open={open}
          onCloseClick={onClose}
          onBackButtonClick={
            menuLocation.content !== 'LevelOne' ? onClickBack : null
          }
          animationDirection='left'
        >
          <div className={styles.contentWrapper}>
            {menuLocation.content === 'LevelOne' && (
              <LevelOneNavigation
                primaryNavigation={primaryNavigation}
                secondaryNavigation={secondaryNavigation}
                brandsNavigation={brandsNavigation}
                onChangeMenuLocation={onChangeMenuLocation}
                onEntryClick={onClose}
              />
            )}

            {menuLocation.content === 'AllMachines' && (
              <AllMachinesList
                onEntryClick={onClose}
                allOffersUrl={allOffersUrl}
              />
            )}

            {menuLocation.content === 'LevelTwo' && menuLocation.entry && (
              <LevelTwoNavigation
                entry={menuLocation.entry}
                onEntryClick={onClose}
              />
            )}
          </div>
        </MobileOverlay>
      </div>
    )
  }
)

MobileMenu.displayName = 'MobileMenu'
