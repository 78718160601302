import { Typography } from '@renderer-ui-library/mui'
import React from 'react'
import styles from './headlineSubline.module.scss'

interface HeadlineSublineProps {
  headline: string | null
  subline: string | null
}

export const HeadlineSubline = React.memo(
  ({ headline, subline }: HeadlineSublineProps) => (
    <div>
      {headline && (
        <div className={styles.headline}>
          <Typography variant='h4' fontSize='inherit'>
            {headline}
          </Typography>
        </div>
      )}
      {subline && (
        <div className={styles.subline}>
          <Typography variant='body2' fontSize='inherit'>
            {subline}
          </Typography>
        </div>
      )}
    </div>
  )
)

HeadlineSubline.displayName = 'HeadlineSubline'
