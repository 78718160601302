import React from 'react'
import { BlockProps } from './BlockProps'
import { getBlockComponent } from './blockComponentsRegistry'

export const Block: React.FC<BlockProps> = React.memo((props) => {
  const Component = getBlockComponent(props.data.type)
  if (!Component) {
    return null
  }
  return <Component {...props} />
})

Block.displayName = 'Block'
