import {
  Agriculture,
  ChatBubbleOutline,
  CheckCircleOutline,
  CreditScore,
  Discount,
  Euro,
  FavoriteBorder,
  Garage,
  Language,
  LocationOn,
  Lock,
  NotificationsActiveOutlined,
  PermMedia,
  Person,
  PublishedWithChanges,
  QuestionAnswer,
  Recommend,
  SvgIconComponent,
  Work,
} from '@mui/icons-material'
import { EIcon } from '@renderer-ui-library/atoms'
import styles from './icon.module.scss'

type Props = {
  icon: EIcon
  className?: string
}

const Icons: Record<EIcon, SvgIconComponent> = {
  [EIcon.Garage]: Garage,
  [EIcon.Language]: Language,
  [EIcon.Lock]: Lock,
  [EIcon.QuestionAnswer]: QuestionAnswer,
  [EIcon.Recommend]: Recommend,
  [EIcon.PermMedia]: PermMedia,
  [EIcon.CheckCircleOutline]: CheckCircleOutline,
  [EIcon.ChatBubbleOutline]: ChatBubbleOutline,
  [EIcon.Work]: Work,
  [EIcon.LocationOn]: LocationOn,
  [EIcon.FavoriteBorder]: FavoriteBorder,
  [EIcon.Person]: Person,
  [EIcon.NotificationsActiveOutlined]: NotificationsActiveOutlined,
  [EIcon.Money]: Euro,
  [EIcon.Discount]: Discount,
  [EIcon.CreditScore]: CreditScore,
  [EIcon.Agriculture]: Agriculture,
  [EIcon.PublishedWithChanges]: PublishedWithChanges,
}

export const Icon: React.FC<Props> = ({ icon, className }) => {
  const IconComponent = Icons[icon]

  return (
    <div className={(styles.iconWrapper, className)}>
      <IconComponent fontSize='inherit' color='inherit' />
    </div>
  )
}
