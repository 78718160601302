import { TextContext } from '@renderer-ui-library/atoms'
import React, { useCallback, useContext } from 'react'
import { MachineContext } from 'utils/machine/MachineContext'
import { tracker } from 'utils/tracking/tracker'

export const TextProvider: React.FC<{
  children?: React.ReactNode
}> = React.memo((props) => {
  const { machine } = useContext(MachineContext)

  const linkClickHandler = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>) => {
      const href = (ev.target as HTMLAnchorElement).href
      if (href && href.indexOf('tel:') === 0) {
        tracker.trackEvent({
          name: 'call-click',
          target_name: 'in_text',
          machine_group_id: machine?.machineGroupId,
          is_interaction: true,
        })
      }
    },
    [machine]
  )

  return (
    <TextContext.Provider value={{ onLinkClick: linkClickHandler }}>
      {props.children}
    </TextContext.Provider>
  )
})

TextProvider.displayName = 'TextProvider'
