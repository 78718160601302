import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableProps,
  TableRow,
  Typography,
} from '@renderer-ui-library/mui'
import { TTableRowValue } from './TTableRowValue'
import { TableValueContent } from './TableValueContent'
import styles from './table.module.scss'

export type TTableRow = {
  key: string
  value: TTableRowValue
  marker?: boolean
}

export type OwnProps = {
  rows: TTableRow[]
  title?: string
}

export type TTableProps = TableProps & OwnProps

export const Table: React.FC<TTableProps> = ({
  title,
  rows,
  ...TableProps
}) => {
  return (
    <>
      {title && (
        <div className={styles.titleRow}>
          <Typography
            component='h4'
            variant='h6'
            className={styles.titleRowText}
          >
            {title}
          </Typography>
        </div>
      )}
      <MuiTable {...TableProps} size='small'>
        <TableBody className={styles.tableBody}>
          {rows.map((row, index) => (
            <TableRow key={`${row.key}-${index}`}>
              <TableCell width='50%' className={styles.leftColumn}>
                {row.key}
              </TableCell>
              <TableCell>
                <TableValueContent value={row.value} marker={row.marker} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </>
  )
}
