import { ImageLoader } from '@internal/utils/media/ImageLoader'
import Image from 'next/image'
import React from 'react'
import chevronsIcon from '../../assets/chevrons.svg'
import styles from './chevrons.module.scss'

export const Chevrons = React.memo(() => (
  <div className={styles.wrapper}>
    <Image
      src={chevronsIcon.src}
      loading='lazy'
      alt=''
      loader={ImageLoader}
      width={58}
      height={40}
    />
  </div>
))

Chevrons.displayName = 'Chevrons'
