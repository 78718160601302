import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import bg from './bg.svg'
import cs from './cs.svg'
import da from './da.svg'
import de from './de.svg'
import el from './el.svg'
import en from './en.svg'
import es from './es.svg'
import fr from './fr.svg'
import hu from './hu.svg'
import it from './it.svg'
import lt from './lt.svg'
import pl from './pl.svg'
import ro from './ro.svg'
import ru from './ru.svg'
import sl from './sl.svg'
import sr from './sr.svg'

const countryFlags: Record<TLocale, { src: string }> = {
  bg,
  da,
  de,
  el,
  en,
  es,
  fr,
  hu,
  it,
  pl,
  ro,
  ru,
  sl,
  sr,
  cs,
  lt,
}

export default countryFlags
