import { PageContext } from 'page/PageContext'
import { useContext, useMemo } from 'react'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { getLocalizedUrlMapForStartPage } from 'utils/routing/urls/getLocalizedUrlMapForStartPage'
import { TLocalizedUrlMap } from './TLocalizedUrlMap'

export const useLocalizedUrlMap = () => {
  const { pageData, localizedUrlMap } = useContext(PageContext)

  const localizations = useMemo(() => {
    let map: TLocalizedUrlMap = {
      ...getLocalizedUrlMapForStartPage(),
      ...(localizedUrlMap ?? []),
    }

    if (!pageData) {
      return map
    }

    switch (pageData.type) {
      case PageTypeKey.MachineCategoryPage:
      case PageTypeKey.Page: {
        map = Object.assign({}, map, pageData.localizations)
        break
      }
    }

    return map
  }, [localizedUrlMap, pageData])

  return localizations
}
