import { RadioSelectFilter } from '@renderer-ui-library/molecules'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TFilterableBaleChamberOption } from '@website-shared-library/search/TFilterableBalerChamberOption'
import { TFilterableHeaderTypeOption } from '@website-shared-library/search/TFilterableHeaderTypeOption'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { tracker } from 'utils/tracking/tracker'
import { SearchFilterCategory } from './SearchFilterCategory/SearchFilterCategory'

enum ParamType {
  baler = 'baleChamber',
  header = 'headerType',
}

type FilterOption = TFilterableBaleChamberOption | TFilterableHeaderTypeOption

export const RadioFilter: React.FC = React.memo(() => {
  const { machineType, baleChamber, headerType, ...searchParams } =
    useSearchParams()
  const { gotoSearchResults } = useSearchResultsRouting()
  const { t } = useTranslation(localeNamespace.common)

  const [title, setTitle] = useState<string>('')
  const [selectedOption, setSelectedOption] = useState<
    FilterOption | undefined
  >(undefined)

  useEffect(() => {
    if (machineType === MachineType.BALER) {
      setTitle(t(translations.searchResultsFiltersBaleChamber))
      setSelectedOption(baleChamber)
    }
    if (machineType === MachineType.HEADER) {
      setTitle(t(translations.headerTypeFilterTitle))
      setSelectedOption(headerType)
    }
  }, [baleChamber, headerType, machineType, t])

  const setSelection = useCallback(
    (option: { paramId: ParamType; value: FilterOption }) =>
      gotoSearchResults({
        machineType,
        ...searchParams,
        [option.paramId]: option.value,
      }),
    [gotoSearchResults, machineType, searchParams]
  )

  const handleSelectionChange = useCallback(
    (id: string) => {
      let selection:
        | {
            paramId: ParamType
            value: FilterOption
          }
        | undefined

      switch (machineType) {
        case MachineType.BALER:
          selection = {
            paramId: ParamType.baler,
            value: id as TFilterableBaleChamberOption,
          }
          break
        case MachineType.HEADER:
          selection = {
            paramId: ParamType.header,
            value: id as TFilterableHeaderTypeOption,
          }
          break
      }

      if (selection) {
        setSelection(selection)
        tracker.trackEvent({
          name: 'filter-applied',
          filter_placement: 'serp',
          filter_category: 'filter-other',
          filter_attribute: selection.paramId,
          filter_value: selection.value,
        })
      }
    },
    [machineType, setSelection]
  )

  return (
    <>
      <SearchFilterCategory title={title}>
        <RadioSelectFilter
          selectedOption={selectedOption}
          onChange={handleSelectionChange}
        />
      </SearchFilterCategory>
    </>
  )
})

RadioFilter.displayName = 'RadioFilter'
