import { KeyboardArrowDown } from '@mui/icons-material'
import {
  ClickAwayListener,
  Paper,
  Popover,
  Typography,
} from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import classNames from 'classnames'
import { useCallback, useState } from 'react'
import styles from './dropdown.module.scss'

export type Props = {
  children?: React.ReactNode
  render?: ({ close }: { close: () => void }) => React.ReactNode | undefined
  label: string
  // Todo remove this prop and refactor when we can apply rebranding changes everywhere
  textColor?: string
  color?: 'primary' | 'secondary'
  variant?: 'primary' | 'secondary'
  orientation?: 'left' | 'center' | 'right'
}

export const Dropdown: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const toggleOpen = useCallback(
    (ev: React.MouseEvent<HTMLElement>) =>
      setAnchorEl((anchorEl) => {
        if (anchorEl) {
          return null
        }
        return ev.currentTarget as HTMLElement
      }),
    []
  )
  const close = useCallback(() => setAnchorEl(null), [])

  const open = !!anchorEl

  return (
    <div className={styles.wrapper}>
      <Typography
        className={styles.button}
        onClick={toggleOpen}
        color={
          props.textColor
            ? props.textColor
            : props.color === 'secondary'
              ? Colors.textSecondary
              : Colors.textPrimary
        }
        variant={props.variant === 'secondary' ? 'body2' : 'body1'}
        component='div'
      >
        {props.label}
        <div className={classNames(styles.icon, { [styles.open]: open })}>
          <KeyboardArrowDown fontSize='inherit' />
        </div>
      </Typography>
      <Popover
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: props.variant === 'secondary' ? 28 : 32,
          horizontal: props.orientation ? props.orientation : 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: props.orientation ? props.orientation : 'center',
        }}
      >
        <ClickAwayListener onClickAway={close}>
          <Paper elevation={4}>
            <div className={styles.content}>
              {props.children || props.render?.({ close })}
            </div>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </div>
  )
}
