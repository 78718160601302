import { ArrowDropDown } from '@mui/icons-material'
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
} from '@renderer-ui-library/mui'
import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { RangeInputProps } from '../RangeInput'
import { DynamicRangeInput } from '../RangeInput/dynamicRangeInput'
import styles from './rangeSelect.module.scss'

type Props = RangeInputProps & {
  disabled?: boolean
  testId?: string
  label: string
}

export const RangeSelect: React.FC<Props> = React.memo(
  ({
    label,
    minValue,
    maxValue,
    minPlaceholder,
    maxPlaceholder,
    disabled,
    testId,
    ...rangeProps
  }) => {
    const [isOpen, setOpen] = useState(false)

    const toggleOpen = useCallback(
      () => setOpen((isOpen) => !(isOpen || disabled)),
      [disabled]
    )
    const close = useCallback(() => setOpen(false), [])

    return (
      <ClickAwayListener
        onClickAway={close}
        mouseEvent={isOpen ? undefined : false}
        touchEvent={isOpen ? undefined : false}
      >
        <div
          className={classNames(styles.wrapper, {
            [styles.open]: isOpen,
            [styles.disabled]: disabled,
          })}
          data-testid={testId}
        >
          <div className={styles.textField} onClick={toggleOpen}>
            {!minValue && !maxValue && (
              <span className={styles.placeholder}>{label}</span>
            )}
            {(minValue || maxValue) && (
              <span className={styles.valueWrapper}>
                <span className={styles.value}>{`${
                  minValue ?? minPlaceholder
                } - ${maxValue ?? maxPlaceholder}`}</span>
                {rangeProps.unit && (
                  <span className={styles.unit}>{rangeProps.unit}</span>
                )}
              </span>
            )}
            <IconButton
              size='medium'
              aria-label='open range select'
              className={styles.icon}
              disabled={disabled}
            >
              <ArrowDropDown fontSize='inherit' />
            </IconButton>
          </div>

          <div
            className={classNames(styles.rangeInputWrapper, {
              [styles.show]: isOpen,
            })}
          >
            <Grow style={{ transformOrigin: '0 0 0' }} in={isOpen}>
              <div>
                <Box boxShadow={5} className={styles.rangeInput}>
                  <DynamicRangeInput
                    minValue={minValue}
                    maxValue={maxValue}
                    onEnterPress={close}
                    {...rangeProps}
                  />
                </Box>
              </div>
            </Grow>
          </div>
        </div>
      </ClickAwayListener>
    )
  }
)

RangeSelect.displayName = 'RangeSelect'
