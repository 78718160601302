import {
  OldMachineDTO,
  oldMachineDTOtoMachine,
} from '@internal/utils/machine/Machine'
import {
  ICallbackRequestForm,
  IContactForm,
  IRequestLeasingForm,
} from '@website-shared-library/forms'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { SortOrder } from '@website-shared-library/search/SortOrder'
import { TSearchFilter } from '@website-shared-library/search/TSearchFilter'
import { TSearchFilterBoolean } from '@website-shared-library/search/TSearchFilterBoolean'
import { TSearchPayload } from '@website-shared-library/search/TSearchPayload'
import { TSearchSortOption } from '@website-shared-library/search/TSearchSortOption'
import axios from 'axios'
import { environment } from 'environment/environment'
import { getAxiosData } from 'utils/api/getAxiosData'
import { TSearchResult } from './TSearchResult'

const { BACKEND_URL } = environment

const unauthenticatedBackendClient = axios.create({
  baseURL: BACKEND_URL,
  timeout: 30000,
})

export const searchMachine = async (
  params: TSearchPayload,
  controller?: AbortController
): Promise<TSearchResult> => {
  const excludeMachinesWithoutMedia: TSearchFilterBoolean = {
    type: 'boolean',
    attribute: 'excludeMachinesWithoutMedia',
  }
  return unauthenticatedBackendClient
    .post<{ results: OldMachineDTO[]; count: number }>(
      `/machines/search`,
      {
        ...params,
        filters: [...params.filters, excludeMachinesWithoutMedia],
      },
      {
        signal: controller?.signal,
      }
    )
    .then(getAxiosData)
    .then((res) => ({
      results: res.results.map(oldMachineDTOtoMachine),
      count: res.count,
    }))
}

export const searchMachineTop = async (
  params: { sort: TSearchSortOption },
  controller?: AbortController
): Promise<TSearchResult> =>
  unauthenticatedBackendClient
    .post<{ results: OldMachineDTO[]; count: number }>(
      `/machines/top/?${MachineType.TRACTOR}=30&${MachineType.COMBINE_HARVESTER}=5&${MachineType.BALER}=5&${MachineType.FORAGE_HARVESTER_SELF_PROPELLED}=5&${MachineType.TELEHANDLER}=5`,
      params,
      {
        signal: controller?.signal,
      }
    )
    .then(getAxiosData)
    .then((res) => ({
      results: res.results.map(oldMachineDTOtoMachine),
      count: res.count,
    }))

export const getSearchResultsCount = async (
  params: Omit<TSearchPayload, 'offset' | 'limit' | 'sort'>,
  controller?: AbortController
): Promise<number> => {
  const filters: TSearchFilter[] = [
    {
      type: 'boolean',
      attribute: 'excludeMachinesWithoutMedia',
    },
  ]
  return searchMachine(
    {
      ...params,
      limit: 0,
      offset: 0,
      filters,
      sort: {
        by: 'relevance',
        order: SortOrder.Ascending,
      },
    },
    controller
  ).then((data) => data.count)
}

export const sendLeasingRequest = (data: IRequestLeasingForm) =>
  unauthenticatedBackendClient.post<any>(`/forms`, { ...data })

export const sendContactUsRequest = (data: IContactForm) =>
  unauthenticatedBackendClient.post<any>(`/forms`, { ...data })

export const sendCallBackRequest = (data: ICallbackRequestForm) =>
  unauthenticatedBackendClient.post<any>(`/forms/callback`, { ...data })
