import { Checkbox, Typography } from '@renderer-ui-library/mui'
import { memo } from 'react'
import { IForms } from 'services/convertFormsPayload'
import styles from './topicsChecklist.module.scss'

interface Props {
  callBackTopics: IForms['callBackTopics']
  onTopicChange: (newValue: string) => void
  topic: string
  topicError: string | false | undefined
}

export const TopicsChecklist = memo((props: Props) => {
  if (!props.callBackTopics) return null
  return (
    <div className={styles.topicsChecklist}>
      <Typography variant='h6' color='textPrimary'>
        {props.callBackTopics.title}
      </Typography>
      {props.callBackTopics.topics.map((topic) => (
        <div key={topic.id}>
          <Checkbox
            checked={topic.text === props.topic}
            onChange={() => props.onTopicChange(topic.text)}
          />
          {topic.text}
        </div>
      ))}
    </div>
  )
})

TopicsChecklist.displayName = 'TopicsChecklist'
