import { Close } from '@mui/icons-material'
import { Typography } from '@renderer-ui-library/mui'
import styles from './wizardOverlayMobileHeader.module.scss'

type Props = {
  machineImage: React.ReactNode | null
  title: string
  price: string
  info: string
  onCloseClick: () => void
}

export const WizardOverlayMobileHeader: React.FC<Props> = (props) => {
  return (
    <div className={styles.wrapper}>
      {props.machineImage && (
        <div className={styles.left}>
          <div className={styles.image}>{props.machineImage}</div>
          <div>
            <Typography variant='h3'>{props.title}</Typography>
            <Typography variant='body1' color='primary'>
              {props.price}
            </Typography>
            <Typography
              className={styles.info}
              variant='subtitle2'
              color='textSecondary'
            >
              {props.info}
            </Typography>
          </div>
        </div>
      )}
      <Close
        onClick={props.onCloseClick}
        fontSize='small'
        sx={{ marginLeft: 'auto' }}
      />
    </div>
  )
}
