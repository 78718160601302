import { FormControlLabel, Radio, RadioGroup } from '@renderer-ui-library/mui'
import {
  MachineType,
  SubTypes,
} from '@website-shared-library/machine/MachineType'
import React, { ChangeEvent, useCallback } from 'react'
import { tracker } from 'utils/tracking/tracker'
import { useMachineSubTypeOptions } from './useMachineSubTypeOptions'

type Props = {
  machineType: MachineType
  onChange: (machineSubType: SubTypes[keyof SubTypes]) => void
  selectedMachineSubType: SubTypes[keyof SubTypes] | undefined
}

export const MachineSubTypeSelectFilter: React.FC<Props> = React.memo(
  ({ onChange, selectedMachineSubType, machineType }) => {
    const { machineSubTypeOptions } = useMachineSubTypeOptions(machineType)

    const handleSubTypeChange = useCallback(
      (_: ChangeEvent<HTMLInputElement>, option: string) => {
        if (option) {
          onChange(option as SubTypes[keyof SubTypes])

          tracker.trackEvent({
            name: 'filter-applied',
            filter_placement: 'serp',
            filter_category: 'machine-sub-type',
            filter_machine_sub_type: option,
            is_interaction: true,
          })
        }
      },
      [onChange]
    )

    return (
      <RadioGroup
        onChange={handleSubTypeChange}
        defaultValue={selectedMachineSubType}
      >
        {machineSubTypeOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    )
  }
)

MachineSubTypeSelectFilter.displayName = 'MachineSubTypeSelectFilter'
