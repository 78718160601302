import { RangeSelect } from '@renderer-ui-library/molecules'
import { TDisplayDefinitionEntryNumber } from '@website-shared-library/displayDefinition/entry/DisplayDefinitionEntryNumber'
import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import { useRangeFilterOptions } from 'blocks/SearchResultsBlock/useRangeFilterOptions'
import React, { useCallback } from 'react'

export type RangeSelectValue = number | undefined

type Props = {
  disabled?: boolean
  config: TDisplayDefinitionEntryNumber
  min: number | undefined
  max: number | undefined
  testId?: string
  onChange: (
    machineAttribute: TFilterableMachineAttribute,
    value: {
      min?: RangeSelectValue
      max?: RangeSelectValue
    }
  ) => void
}

export const RangeSelectFilter: React.FC<Props> = React.memo(
  ({ onChange, config, min, max, disabled, testId }) => {
    const rangeFilterOptions = useRangeFilterOptions(
      config.machineAttribute as TFilterableMachineAttribute,
      config.label
    )

    const handleChange = useCallback(
      (value: { min?: RangeSelectValue; max?: RangeSelectValue }) =>
        onChange(config.machineAttribute as TFilterableMachineAttribute, value),
      [config, onChange]
    )

    return (
      <RangeSelect
        {...rangeFilterOptions}
        step={1}
        onChange={handleChange}
        minValue={min}
        maxValue={max}
        disabled={disabled}
        testId={testId}
      />
    )
  }
)

RangeSelectFilter.displayName = 'RangeSelectFilter'
