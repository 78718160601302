import { useMemo } from 'react'
import { getMediaUrl } from 'utils/media/getMediaSrc'
import { Machine } from '../machine/Machine'
import { MachinePlaceholderImageMapper } from './placeholder/MachinePlaceholderImageMapper'

export const useMachineSharingPreviewImage = (
  machine: Machine | null | undefined
) =>
  useMemo(() => {
    if (!machine?.previewImage) {
      return null
    }

    const image = machine.previewImage
      ? { id: 0, attributes: machine.previewImage }
      : MachinePlaceholderImageMapper(machine.machineType, 'image')

    const newWidth = 400
    const newHeight = image.attributes?.ratio
      ? Math.floor(newWidth / image.attributes.ratio)
      : null

    const url = getMediaUrl({ data: image }, 'original', newWidth)

    return {
      width: newWidth,
      height: newHeight,
      url,
    }
  }, [machine])
