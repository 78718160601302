import {
  FormControl,
  FormControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@renderer-ui-library/mui'

export type RadioOption = {
  label: string
  value: string
}
export type RadioGroupProps = {
  options: RadioOption[]
  value: string
  onChange: (value: string) => void
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange((event.target as HTMLInputElement).value)
  }

  return (
    <>
      <FormControl>
        <MuiRadioGroup value={props.value} onChange={handleChange}>
          {props.options.map((option) => (
            <FormControlLabel
              key={option.value}
              label={option.label}
              value={option.value}
              control={<MuiRadio />}
            />
          ))}
        </MuiRadioGroup>
      </FormControl>
    </>
  )
}
