import React from 'react'
import { TPerson } from 'services/TSalesManagerPayload'
import { SalesManagerContext } from './SalesManagerContext'

interface SalesManagerProviderProps {
  salesManager: TPerson | null
  children?: React.ReactNode
}

export const SalesManagerProvider: React.FC<SalesManagerProviderProps> =
  React.memo(({ children, salesManager }) => (
    <SalesManagerContext.Provider value={{ salesManager }}>
      {children}
    </SalesManagerContext.Provider>
  ))

SalesManagerProvider.displayName = 'SalesManagerProvider'
