import { Text } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { ITestimonialData } from 'blocks/TestimonialsBlock/ITestimonialsBlockData'
import classnames from 'classnames'
import React from 'react'
import { Media } from '../Media/Media'
import styles from './testimonial.module.scss'

interface TestimonialProps {
  testimonials: ITestimonialData[]
  highlight: boolean
}

export const Testimonail: React.FC<TestimonialProps> = React.memo(
  ({ testimonials, highlight }) => {
    const color = highlight ? 'white' : 'textPrimary'

    return (
      <div
        className={classnames(styles.testimonailSlider, {
          [styles.testimonialHighlight]: highlight,
        })}
      >
        <Splide
          options={{
            type: 'loop',
            heightRatio: 2 / 3,
            pagination: true,
            perPage: 1,
            pauseOnHover: true,
            pauseOnFocus: true,
            height: 'auto',
            autoplay: true,
            rewind: true,
            arrows: false,
          }}
        >
          {testimonials.map((testimonial) => (
            <SplideSlide key={testimonial.id}>
              <div key={testimonial.id} className={styles.testimonialContent}>
                <div className={styles.header}>
                  {testimonial.media && (
                    <div className={styles.mediaWrapper}>
                      <Media
                        loading='lazy'
                        media={testimonial.media}
                        ratio='original'
                      />
                    </div>
                  )}
                  <div className={styles.mobileTitle}>
                    <Typography variant='h6' color={color}>
                      {testimonial.author}
                    </Typography>
                    <Typography variant='body1' color={color}>
                      {testimonial.country}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div className={styles.title}>
                    <Typography variant='h6' color={color}>
                      {`${testimonial.author}, ${testimonial.country}`}
                    </Typography>
                  </div>
                  <div className={styles.text}>
                    <Text
                      text={testimonial.text ?? ''}
                      highlight={highlight}
                      color={highlight ? 'bright' : 'dark'}
                    />
                  </div>
                  <div
                    className={classnames(styles.bottomText, {
                      [styles.highlight]: highlight,
                    })}
                  >
                    <Typography variant='body2' color='inherit'>
                      {testimonial.bottomText}
                    </Typography>
                  </div>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    )
  }
)
Testimonail.displayName = 'Testimonail'
