import { CompareOutlined, Sort, Tune } from '@mui/icons-material'
import { Grid, Typography } from '@renderer-ui-library/mui'
import { LeasingQualifiedFilter } from '@renderer-ui-library/organisms'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { MachineComparisonContext } from 'blocks/SearchResultsBlock/CompareMachines/MachineComparisonContext'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useContext, useMemo } from 'react'
import styles from './filterMenuBar.module.scss'
import { useFilterBarOptions } from './usefilterBarOptions'

interface FilterMenuBarProps {
  machineType: MachineType | undefined
  onFilterClick: () => void
  onSortClick: () => void
}

export const FilterMenuBar: React.FC<FilterMenuBarProps> = React.memo(
  (props) => {
    const { t } = useTranslation(localeNamespace.common)
    const { sort } = useSearchParams()
    const { toggleCompareMode } = useContext(MachineComparisonContext)
    const { options } = useFilterBarOptions()

    const selectedOption = useMemo<string>(
      () => options.filter((option) => option.value === sort)[0].label,
      [options, sort]
    )

    const handleCompareClick = useCallback(
      () => props.machineType && toggleCompareMode(props.machineType),
      [props.machineType, toggleCompareMode]
    )

    return (
      <Grid item xs={12} className={styles.mobileMenuBar}>
        <div className={styles.filterContainer}>
          <div className={styles.content} onClick={props.onFilterClick}>
            <div className={styles.smallIcon}>
              <Tune color='primary' fontSize='small' />
            </div>
            <Typography color='primary' variant='body2'>
              {t(translations.searchResultsMobileFiltersButton)}
            </Typography>
          </div>

          <div className={styles.content} onClick={props.onSortClick}>
            <div className={styles.smallIcon}>
              <Sort color='primary' fontSize='small' />
            </div>
            <Typography color='primary' variant='body2'>
              {selectedOption}
            </Typography>
          </div>

          <div className={styles.content}>
            <LeasingQualifiedFilter />
          </div>

          {props.machineType && (
            <div className={styles.content} onClick={handleCompareClick}>
              <div className={styles.smallIcon}>
                <CompareOutlined color='primary' fontSize='small' />
              </div>
              <Typography color='primary' variant='body2'>
                {t(translations.compareMachinesSearchResultLabel)}
              </Typography>
            </div>
          )}
        </div>
      </Grid>
    )
  }
)

FilterMenuBar.displayName = 'FilterMenuBar'
