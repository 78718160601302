import React from 'react'
import { ABTestingContext } from './ABTestingContext'
import { ABTestMap } from './ABTestMap'

interface ABTestingProviderProps {
  abTests: ABTestMap
  children?: React.ReactNode
}

export const ABTestingProvider: React.FC<ABTestingProviderProps> = React.memo(
  ({ children, abTests }) => (
    <ABTestingContext.Provider value={abTests}>
      {children}
    </ABTestingContext.Provider>
  )
)

ABTestingProvider.displayName = 'ABTestingProvider'
