import dynamic from 'next/dynamic'
import { MultipleFloatingButtonOverlayProps } from './MultipleFloatingButtonOverlay'

export const DynamicMultipleFloatingButtonOverlay =
  dynamic<MultipleFloatingButtonOverlayProps>(
    () =>
      import('./MultipleFloatingButtonOverlay').then(
        (md) => md.MultipleFloatingButtonOverlay
      ),
    { ssr: false }
  )
