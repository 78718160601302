import { useCallback, useContext } from 'react'
import { FeatureFlagsContext } from './FeatureFlagsContext'
import { TFeatureFlag } from './TFeatureFlag'

export const useFeatureFlag = () => {
  const featureFlags = useContext(FeatureFlagsContext)
  const hasFeature = useCallback(
    (flag: TFeatureFlag) => featureFlags[flag] === true,
    [featureFlags]
  )

  return { hasFeature }
}
