import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import { TSearchFilterNumber } from '@website-shared-library/search/TSearchFilterNumber'
import { isValidFilterableMachineAttribute } from '@website-shared-library/search/isValidFilterableMachineAttribute'
import { TRangeFiltersMap } from './TRangeFiltersMap'

export const rangeFiltersMapToSearchFilters = (
  rangeFilters: TRangeFiltersMap
) => {
  const filters: TSearchFilterNumber[] = []
  Object.keys(rangeFilters).forEach((key) => {
    const attribute = key as TFilterableMachineAttribute
    if (!isValidFilterableMachineAttribute(attribute)) {
      return
    }
    const data = rangeFilters[attribute]

    if (data.min || data.max) {
      const filter: TSearchFilterNumber = {
        type: 'number',
        attribute,
        min: data.min,
        max: data.max,
      }

      filters.push(filter)
    }
  })

  return filters
}
