const localesMap = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  el: 'el',
  it: 'it',
  pl: 'pl',
  ro: 'ro',
  ru: 'ru',
  hu: 'hu',
  es: 'es',
  bg: 'bg',
  sl: 'sl',
  sr: 'sr',
  da: 'da',
  cs: 'cs',
  lt: 'lt',
};

module.exports = localesMap;
