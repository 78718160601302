import { CallOutlined, EmailOutlined } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { ButtonGroup, IconButton } from '@renderer-ui-library/mui'
import { ContactWizardContext } from '@renderer-ui-library/organisms'
import {
  TLocale,
  localeNamespace,
} from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { SyntheticEvent, useContext } from 'react'
import { TPerson } from 'services/TSalesManagerPayload'
import { MachineContext } from 'utils/machine/MachineContext'
import { getMediaSrc } from 'utils/media/getMediaSrc'
import { trackConversionIntention } from 'utils/tracking/trackConversionIntention'
import { tracker } from 'utils/tracking/tracker'
import { DynamicContactInfoMobileOverlay } from './dynamicContactInfoMobileOverlay'
import { DynamicContactPhoneSlide } from './dynamicContactPhoneSlide'
import styles from './machineDetailsContact.module.scss'

interface Props {
  isMobile: boolean
  machineGroupId: string
  contact: TPerson
  iconOnly: boolean
  locale: TLocale
}

export const MachineDetailsContactButtons: React.FC<Props> = React.memo(
  ({ isMobile, machineGroupId, contact, iconOnly, locale }) => {
    const { openRequestInfo } = useContext(ContactWizardContext)
    const { t } = useTranslation(localeNamespace.common)
    const [isDesktopPhoneNumberVisible, setDesktopPhoneNumberVisibility] =
      React.useState(false)
    const { machine } = useContext(MachineContext)

    const handlePhoneClick = React.useCallback(
      (e: SyntheticEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (!isDesktopPhoneNumberVisible) {
          if (machine) {
            trackConversionIntention(machine)
          }
          tracker.trackEvent({
            name: 'call-click',
            target_name: 'pdp.call_button',
            is_interaction: true,
            machine_group_id: machineGroupId,
          })
        }
        setDesktopPhoneNumberVisibility((showPhoneNumber) => !showPhoneNumber)
      },
      [isDesktopPhoneNumberVisible, machineGroupId, machine]
    )

    const handleMessageClick = React.useCallback(
      (e: SyntheticEvent) => {
        e.preventDefault()
        e.stopPropagation()
        openRequestInfo()
      },
      [openRequestInfo]
    )

    return (
      <>
        <div
          className={classNames({
            [styles.mobileWrapper]: isMobile && !iconOnly,
            [styles.desktopWrapper]: !isMobile && !iconOnly,
            [styles.iconOnlyWrapper]: iconOnly,
          })}
        >
          {iconOnly && (
            <IconButton
              disableRipple
              disableTouchRipple
              disableFocusRipple
              onClick={handlePhoneClick}
              className={styles.iconOnlyButton}
            >
              <CallOutlined className={classNames(styles.icon)} />
            </IconButton>
          )}

          {isMobile && !iconOnly && (
            <Button
              color='secondary'
              fullHeight
              fullWidth
              noMinWidth
              onClick={handlePhoneClick}
            >
              <div className={styles.imageMobileWrapper}>
                <img
                  loading='lazy'
                  key={contact.image.data.id}
                  alt={contact.image.data.attributes.alternativeText}
                  {...getMediaSrc(contact.image, {
                    ratio: 1 / 1,
                  })}
                />
              </div>
              <div className={styles.iconContainer}>
                <CallOutlined className={styles.icon} />
                {t(translations.machineDetailsContactCallText)}
              </div>
            </Button>
          )}

          {!isMobile && !iconOnly && (
            <Button
              fullHeight
              fullWidth
              noMinWidth
              color='secondary'
              onClick={handlePhoneClick}
            >
              <CallOutlined className={styles.icon} />
              {t(translations.machineDetailsContactCallText)}
            </Button>
          )}

          <ButtonGroup
            className={classNames(styles.buttonGroup, {
              [styles.iconOnly]: iconOnly,
            })}
          >
            {iconOnly && contact.productInfo && (
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={handleMessageClick}
                className={styles.iconOnlyButton}
              >
                <EmailOutlined className={classNames(styles.icon)} />
              </IconButton>
            )}
            {!iconOnly && contact.productInfo && (
              <Button
                variant='outlined'
                disableRipple={false}
                fullHeight
                fullWidth
                noMinWidth
                onClick={handleMessageClick}
              >
                <EmailOutlined className={styles.icon} />
                {t(translations.machineDetailsContactMessageText)}
              </Button>
            )}
          </ButtonGroup>

          {!isMobile && (
            <DynamicContactPhoneSlide
              open={isDesktopPhoneNumberVisible}
              locale={locale}
              onClose={handlePhoneClick}
              iconOnly={iconOnly}
            />
          )}
        </div>

        {isMobile && (
          <DynamicContactInfoMobileOverlay
            open={isDesktopPhoneNumberVisible}
            contact={contact}
            onCloseClick={handlePhoneClick}
          />
        )}
      </>
    )
  }
)

MachineDetailsContactButtons.displayName = 'MachineDetailsContactButtons'
