import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import dayjs from 'dayjs'

require('dayjs/locale/en')
require('dayjs/locale/de')
require('dayjs/locale/fr')
require('dayjs/locale/el')
require('dayjs/locale/it')
require('dayjs/locale/pl')
require('dayjs/locale/ro')
require('dayjs/locale/ru')
require('dayjs/locale/hu')
require('dayjs/locale/es')
require('dayjs/locale/bg')
require('dayjs/locale/sl')
const localizedFormat = require('dayjs/plugin/localizedFormat')

dayjs.extend(localizedFormat)

export const formatDate = (locale: TLocale, value: string) =>
  dayjs(value).locale(locale).format('L')
