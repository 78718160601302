import Image from 'next/image'
import { ImageLoader } from 'utils/media/ImageLoader'
import countryFlags from '@renderer-ui-library/assets/countryFlags'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import styles from './countryFlags.module.scss'

type CountryFlagProps = {
  locale: TLocale
  localeName: string
}

export const CountryFlag = ({ locale, localeName }: CountryFlagProps) => (
  <img
    src={countryFlags[locale].src}
    loading='lazy'
    alt={`${localeName} flag`}
    className={styles.countryFlag}
  />
)
