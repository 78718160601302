import { Close, SvgIconComponent } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { IconHeadline } from '@renderer-ui-library/molecules'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import classNames from 'classnames'
import React from 'react'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '../../mui'
import { WizardOverlayMobileHeader } from './WizardOverlayMobileHeader/WizardOverlayMobileHeader'
import styles from './wizardOverlay.module.scss'

export interface WizardOverlayProps {
  open: boolean
  children?: React.ReactNode
  onCloseClick: () => void
  machineImage: React.ReactNode | null
  mobileHeaderTitle: string
  mobileHeaderPrice: string
  mobileHeaderInfo: string
  mobileCenterAlign?: boolean
  title?: string
  icon?: SvgIconComponent
  onPrevClick?: () => void
  onNextClick?: () => void
  prevButtonLabel?: string
  nextButtonLabel?: string
  prevButtonIcon?: SvgIconComponent
  nextButtonIcon?: SvgIconComponent
  disablePrev?: boolean
  disableNext?: boolean
  subtitle?: string
  loading?: boolean
}

export const WizardOverlay: React.FC<WizardOverlayProps> = React.memo(
  (props) => {
    const { isMobile } = useMediaQuery()

    const PrevButtonIconComponent = props.prevButtonIcon
      ? props.prevButtonIcon
      : undefined
    const NextButtonIconComponent = props.nextButtonIcon
      ? props.nextButtonIcon
      : undefined

    return (
      <Dialog
        open={props.open}
        onClose={props.onCloseClick}
        maxWidth={!isMobile && 'md'}
        fullScreen={isMobile}
        fullWidth
        PaperProps={{
          sx: {
            height: {
              xs: '100vh',
              md: 'calc(100vh - 48px)',
            },
            maxHeight: {
              xs: '100vh',
              md: 'calc(100vh - 48px)',
            },
            margin: {
              md: '24px',
            },
          },
        }}
      >
        {isMobile && (
          <WizardOverlayMobileHeader
            machineImage={props.machineImage}
            title={props.mobileHeaderTitle}
            price={props.mobileHeaderPrice}
            info={props.mobileHeaderInfo}
            onCloseClick={props.onCloseClick}
          />
        )}

        {props.loading && (
          <div className={styles.loader}>
            <CircularProgress variant='indeterminate' color='primary' />
          </div>
        )}

        {!props.loading && (
          <DialogContent
            className={classNames(styles.dialogContent, {
              [styles.mobileCenterAlign]: props.mobileCenterAlign,
            })}
          >
            <div
              className={classNames(styles.header, {
                [styles.noTitle]: !props.title,
              })}
            >
              {props.title ? (
                <IconHeadline icon={props.icon} title={props.title} />
              ) : (
                <div />
              )}
              <IconButton
                className={styles.close}
                onClick={props.onCloseClick}
                color='primary'
                size='small'
              >
                <Close />
              </IconButton>
            </div>
            <div className={styles.scrollContainerWrapper}>
              <div className={styles.scrollContainer}>
                {props.subtitle && (
                  <Typography variant='body2' color='textSecondary'>
                    {props.subtitle}
                  </Typography>
                )}
                <div className={styles.content}>{props.children}</div>
              </div>
            </div>
            <div>
              <div className={styles.buttons}>
                {props.onPrevClick && props.prevButtonLabel && (
                  <Button
                    startIcon={
                      PrevButtonIconComponent ? (
                        <PrevButtonIconComponent />
                      ) : null
                    }
                    variant='outlined'
                    onClick={props.onPrevClick}
                    noMinWidth
                    disabled={props.disablePrev}
                  >
                    {props.prevButtonLabel}
                  </Button>
                )}
                {props.onNextClick && props.nextButtonLabel && (
                  <Button
                    endIcon={
                      NextButtonIconComponent ? (
                        <NextButtonIconComponent />
                      ) : null
                    }
                    onClick={props.onNextClick}
                    noMinWidth
                    color='secondary'
                    disabled={props.disableNext}
                  >
                    {props.nextButtonLabel}
                  </Button>
                )}
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    )
  }
)

WizardOverlay.displayName = 'WizardOverlay'
